import React from 'react'
import { Route } from 'react-router-dom'
import Transaction from '../pages/Transactions/Transaction'
import Payments from '../pages/Payments/Payments'
import TransactionReceipt from '../pages/Transactions/TransactionReceipt'
import MerchantLayout from '../layouts/MerchantLayout'
import NewVendor from '../pages/Merchant/Vendors/NewVendor'
import ListTransactions from '../pages/Transactions/ListTransactions'
import Users from '../pages/Users/Users'
import User from '../pages/Users/User'
import NewUser from '../pages/Users/NewUser'
import Home from '../pages/Home/Home'
import ListVendors from '../pages/Merchant/Vendors/ListVendors'
import VendorShow from '../pages/Merchant/Vendors/VendorShow'
import NewPayment from '../pages/Merchant/Payments/NewPayment'

export default function MerchantRouter() {
  return (
    <Route path="merchant" element={<MerchantLayout />}>
      <Route path="dashboard" element={<Home />} />
      <Route path="transactions" element={<ListTransactions />}>
        <Route path=":transactionId" element={<Transaction />}>
          <Route path="receipt" element={<TransactionReceipt />} />
        </Route>
      </Route>
      <Route path="payments" element={<Payments />}>
        <Route path="new" element={<NewPayment />} />
      </Route>
      <Route path="users" element={<Users />}>
        <Route path=":userId" element={<User />} />
        <Route path="new" element={<NewUser />} />
      </Route>
      <Route path="vendors" element={<ListVendors />}>
        <Route path="new" element={<NewVendor />} />
      </Route>
      <Route path="vendors/:vendorId" element={<VendorShow />}></Route>
      {/* <Route path="settings" element={<Home />} /> */}
    </Route>
  )
}
