import AWS from 'aws-sdk'

// Inicializar o provedor de credenciais do Amazon Cognito
AWS.config.region = 'us-east-1' // Região
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:b6af5dbd-e05c-4270-b45f-22d78465a665'
})

export const uploadToS3 = (file, onProgress, onUpload) => {
  const s3 = new AWS.S3({
    params: {
      bucketRegion: 'us-east-1',
      Bucket: 'docs.ledger.pppay.com.br'
    }
  })

  var filePath = `${crypto.randomUUID()}/${file.name}`
  var bucketRegion = 'us-east-1'
  var fileUrl = `https://${bucketRegion}.amazonaws.com/${filePath}`

  s3.upload(
    {
      Key: filePath,
      Body: file,
      ACL: 'public-read'
    },
    function (err, data) {
      if (err) {
        alert('error')
        return
      }
      onUpload(data)
    }
  ).on('httpUploadProgress', function (progress) {
    var uploaded = parseInt((progress.loaded * 100) / progress.total)
    onProgress(uploaded)
  })
}
