import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import { fetchAdminPayments, fetchCustomsPayments } from '../../../services/ledgerApi'
import PaymentStatus from '../../../components/Payment/PaymentStatus'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import TextInput from '../../../components/@ui/TextInput'
import SearchIcon from '../../../components/@ui/icons/SearchIcon'
import { Text } from '@radix-ui/themes'
import Button from '../../../components/Button/Button'
import NewPaymentUpload from './NewPaymentUpload'
import DropdownButton from '../../../components/@ui/DropdownButton'

const generateColumns = (hasMerchantId) => {
  const columns = [
    {
      title: 'Pagamento',
      render: (payment) => (
        <div>
          <Link to={`/admin/payments/${payment.id}`}>
            <h1>{payment.id}</h1>
          </Link>
        </div>
      )
    },
    {
      title: 'Tipo',
      render: ({ payment_type }) => payment_type
    },
    {
      title: 'Fornecedor',
      render: ({ vendor }) => <Text>{vendor?.name || ''}</Text>
    },
    {
      title: 'Valor Total',
      render: ({ amount }) => <FormattedCurrency amount={amount} currency="BRL" />
    },
    {
      title: 'Status',
      render: ({ payment_status }) => <PaymentStatus paymentStatus={payment_status} />
    }
  ]

  if (!hasMerchantId) {
    columns.splice(2, 0, {
      title: 'Cliente',
      render: ({ merchant }) => <Link to={`/customs/merchant/${merchant.id}`}>{merchant.name}</Link>
    })
  }

  return columns
}

export default function CustomsListPayments({ merchantId }) {
  const [searchInput, setSearchInput] = useState('')
  const [isUploadOpen, setIsUploadOpen] = useState(false)
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    setSearchInput(event.target.value)
  }

  const { data, isLoading, isRefetching } = useQuery({
    queryFn: fetchAdminPayments,
    meta: { filters: { text: searchInput } },
    queryKey: ['fetchAdminPayments', { merchantId }]
  })

  const { t } = useTranslation()

  const columns = generateColumns(merchantId)

  return (
    <div>
      <PageHeader
        title="Payments"
        subtitle="A list of all Payments."
        button={
          <div className="flex gap-2">
            <Button variant="white" onClick={() => setIsUploadOpen(!isUploadOpen)}>
              <ArrowUpTrayIcon className="w-5" /> {t('ListPaymentImportListButton')}
            </Button>
            <DropdownButton
              menuContent={[
                { label: 'Boleto e Título', onClick: () => navigate('/customs/pay-boleto') },
                { label: 'Pix', onClick: () => null },
                { label: 'DARF', onClick: () => null }
              ]}
            >
              Novo pagamento
            </DropdownButton>
          </div>
        }
      />
      <div className="px-8 mt-11">
        <TextInput
          value={searchInput}
          onChange={handleInputChange}
          slot={<SearchIcon height="16" width="16" />}
          placeholder="Search"
        />
      </div>
      <Main className="pt-4">
        <Table isLoading={isLoading} columns={columns} rows={data || []} isRefetching={isRefetching} />
      </Main>
      <NewPaymentUpload isOpen={isUploadOpen} setIsOpen={setIsUploadOpen} />
      <Outlet />
    </div>
  )
}
