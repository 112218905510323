import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { useAuth } from '../../../contexts/AuthContext'
import authenticatorApi from '../../../services/authenticatorApi'
import { useTranslation } from 'react-i18next'

export default function AdminImpersonateUser() {
  const auth = useAuth()
  const { t } = useTranslation()
  const { merchantId, userId } = useParams()
  const navigate = useNavigate()

  const onClose = () => {
    navigate(`/admin/merchant/${merchantId}/users/`)
  }

  const impersonate = (event) => {
    authenticatorApi
      .post(`/app/admin/users/${userId}/impersonate`)
      .then(async (response) => {
        await auth.UpdateCurrentUser(response.data.token)
        navigate('/merchant')
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  return (
    <div>
      <ConfirmationModal
        title={t('AdminMerchantUsersModal')}
        onClose={onClose}
        confirmButton={
          <Button variant="secondary" onClick={impersonate}>
            {t('AdminMerchantUsersModalButton')}
          </Button>
        }
      >
        <p className="text-sm text-gray-500">{t('AdminMerchantUsersModalSubtitles')}</p>
      </ConfirmationModal>
    </div>
  )
}
