import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { escapeRegExp } from './helpers/utils'
import '@radix-ui/themes/styles.css'
import './index.css'

import './i18n'

if (process.env.VERCEL_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          escapeRegExp(process.env.REACT_APP_GATEWAY_URL),
          escapeRegExp(process.env.REACT_APP_BUREAU_URL),
          escapeRegExp(process.env.REACT_APP_LEDGER_URL),
          escapeRegExp(process.env.REACT_APP_AUTHENTICATOR_URL)
        ]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
} else {
  console.log('SENTRY NOT REQUIRED', process.env.VERCEL_ENV)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
