import React, { useEffect } from 'react'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import { useOutletContext } from 'react-router-dom'
import FormatCNPJ from '../../../components/Helpers/FormatCNPJ'
import MerchantFullAddress from '../../../components/Merchant/MerchantFullAddress'
import { useTranslation } from 'react-i18next'

const attachments = [
  { name: 'pppay_volks_main_contract.pdf', href: '#' },
  { name: 'volks_processing_agreement.pdf', href: '#' }
]

export default function CustomsMerchantDetails() {
  const { t } = useTranslation()
  const { merchant, isLoading, setActivePage } = useOutletContext()

  useEffect(() => {
    setActivePage('details')
  }, [])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div>
      <div className="mx-auto mt-8 grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-3 lg:col-start-1">
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white">
              <div className="py-5">
                <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                  {t('MerchantHomeMerchantInformation')}
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('MerchantHomeBusiness')}</p>
              </div>
              <div className="border-t border-gray-200 py-5">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeDocument')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <FormatCNPJ cnpj={merchant.document} />
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeEmailAddress')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">TBD</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeAddress')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <MerchantFullAddress merchant={merchant} />
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeZipCode')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{merchant.address_zip}</dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeMonthlyTransaction')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">TBD</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomePhone')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{merchant.phone}</dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeAbout')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">TBD</dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">{t('MerchantHomeContracts')}</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                        {attachments.map((attachment) => (
                          <li
                            key={attachment.name}
                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                          >
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 w-0 flex-1 truncate">{attachment.name}</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a href={attachment.href} className="font-medium text-blue-600 hover:text-blue-500">
                                {t('MerchantHomeDownload')}
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
