import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const authenticatorApi = axios.create({
  baseURL: process.env.REACT_APP_AUTHENTICATOR_URL
})

authenticatorApi.interceptors.response.use(...responseInterceptors)
authenticatorApi.interceptors.request.use(...requestInterceptors)

export const requestPasswordReset = async ({ email }) => {
  return authenticatorApi
    .post(`/api/passwords`, {
      email
    })
    .then((response) => response.data)
}

export const setNewPassword = async ({ email, token, password, password_confirmation }) => {
  return authenticatorApi
    .put(`/api/passwords`, {
      email,
      token,
      password,
      password_confirmation
    })
    .then((response) => response.data)
}

export default authenticatorApi
