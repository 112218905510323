import React from 'react'
import Form from '../../Form/Form'
import TextInput from '../../Form/Inputs/TextInput'
import SubmitButton from '../../Form/Inputs/SubmitButton'
import { useMutation } from '@tanstack/react-query'
import { requestPasswordReset } from '../../../services/authenticatorApi'
import { Link } from 'react-router-dom'
import Button from '../../Button/Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function ResetPasswordEmailForm() {
  const { t } = useTranslation()
  const resetPassword = useMutation({
    mutationFn: requestPasswordReset,
    onSuccess: (data) => {
      console.log('data', data)
    }
  })

  const handleSubmit = (data) => {
    resetPassword.mutate(data)
  }

  if (resetPassword.isSuccess) {
    return (
      <>
        <h1 className="mt-4 text-2xl font-semibold text-gray-800">{t('ResetPasswordWellDone')}</h1>
        <p className="mb-4 mt-2 text-gray-600">{t('ResetPasswordEmailMatches')}</p>
        <Link to="/">
          <Button variant="secondary" size="lg">
            {t('ResetPasswordButtonBack')}
          </Button>
        </Link>
      </>
    )
  }

  return (
    <div>
      <h1 className="mt-4 text-2xl font-semibold text-gray-800">{t('ResetPasswordTitle')}</h1>
      <p className="mb-6 mt-2 text-gray-600">{t('ResetPasswordSubtitles')}</p>
      <Form onSubmit={handleSubmit} isLoading={resetPassword.isLoading} className="my-4">
        <label htmlFor="email" className="mb-1 block font-medium text-gray-700">
          {t('ResetPasswordEmailAddress')}
        </label>

        <TextInput name="email" type="email" size="lg" rules={{ required: t('LoginErrorEmail') }} />

        <SubmitButton text={t('ResetPasswordButtonReset')} loadingText="Sending..." name="submit" className="mt-5" />
      </Form>

      <Link to="/">
        <span className="flex items-center font-medium text-gray-600">
          <ArrowLeftIcon className="mr-2 h-4 w-4" /> {t('ResetPasswordButtonBack')}
        </span>
      </Link>
    </div>
  )
}
