import { CheckIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import LoadingPuff from '../../components/Icons/LoadingPuff'
import authenticatorApi from '../../services/authenticatorApi'
import { useTranslation } from 'react-i18next'

export default function Confirm() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const email = searchParams.get('email')
  const token = searchParams.get('token')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (email !== '' && token !== '') {
      authenticatorApi
        .put('api/confirmation_email', {
          email,
          token
        })
        .then((response) => {
          setIsLoading(false)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }, [email, token])

  return (
    <div>
      <div className="mt-8 shadow-md sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="rounded-md bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          {isLoading ? (
            <div className="text-center">
              <LoadingPuff className="mx-auto h-16 stroke-chill-600" />
              <h1 className="mt-4 text-lg font-medium">{t('ConfirmationYourAccount')}</h1>
              <p className="text-sm text-gray-500">{t('ConfirmationWeConfirming')}</p>
            </div>
          ) : (
            <div className="text-center">
              <CheckIcon className="mx-auto h-16 fill-chill-600 stroke-chill-600" />
              <h1 className="mt-4 text-lg font-medium text-chill-500">{t('ConfirmationEmailConfirmed')}</h1>
              <p className="mt-1 text-gray-500">
                {t('ConfirmationPlease')}&nbsp;
                <Link className="font-bold text-chill-500" to="/auth/sign-in">
                  {t('ConfirmationSign')}
                </Link>
                &nbsp;{t('ConfirmationIntoYourAccount')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
