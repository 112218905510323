import React from 'react'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import DashboardBanks from './DashboardBanks'
import DashboardMain from './DashboardMain'
import DashboardProcessors from './DashboardProcessors'
import { useTranslation } from 'react-i18next'

export default function Dashboard() {
  const { t } = useTranslation()
  return (
    <div>
      <DashboardMain />

      <PageHeader title={t('AdmimdashboardLast')} variant="secondary" />

      <Main>
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-2">
          <div>
            <DashboardProcessors />
          </div>

          <div>
            <DashboardBanks />
          </div>
        </div>
      </Main>
    </div>
  )
}
