import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form from '../../components/Form/Form'
import HiddenInput from '../../components/Form/Inputs/HiddenInput'
import OTPInput from '../../components/Form/Inputs/OTPInput'
import PasswordInput from '../../components/Form/Inputs/PasswordInput'
import SubmitButton from '../../components/Form/Inputs/SubmitButton'
import TextInput from '../../components/Form/Inputs/TextInput'
import AuthContext from '../../contexts/AuthContext'
import { classNames } from '../../helpers/dom'
import { BellAlertIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { ExclamationCircleFill, ExclamationDiamond } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

export default function SignIn() {
  const authContext = useContext(AuthContext)
  const [requiredOTP, setRequiredOTP] = useState(false)
  const [loginData, setLoginData] = useState({ email: '', password: '' })
  const [apiResponse, setApiResponse] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const { t } = useTranslation()

  let navigate = useNavigate()

  const loginUser = async ({ email, password, one_time_password }) => {
    try {
      setApiResponse('')
      await authContext.Login(email, password, one_time_password)
      navigate('/')
    } catch (error) {
      if (error === 'otp_secret.required') {
        setRequiredOTP(true)
        setLoginData({ email, password })
      } else if (error === 'confirm_email.required') {
        setErrorMessage(t('LoginErrorEmailRequired'))
      } else if (typeof error == 'string') {
        setErrorMessage(t('LoginErrorInvalidEmail'))
      } else {
        setErrorMessage(t('LoginErrorUnexpected'))
      }
      if (one_time_password) {
        setApiResponse(error)
      }
    }
  }

  const onSubmit = async (data) => {
    await loginUser(data)
  }

  return (
    <div className="mt-8 shadow-md sm:mx-auto sm:w-full sm:max-w-md">
      <div className="rounded-md bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
        <h1 className="mt-4 text-2xl font-semibold text-gray-800">{t('LoginTitle')}</h1>

        <p className="mb-6 mt-2 text-gray-600">{t('LoginSubtitle')}</p>

        <Form onSubmit={onSubmit} className={classNames(requiredOTP ? 'hidden' : '')}>
          <label htmlFor="email" className="mb-1 block font-medium text-gray-700">
            {t('LoginFormEmailAddress')}
          </label>

          <TextInput name="email" size="lg" error={errorMessage} />

          <label htmlFor="password" className="mb-1 mt-5 block font-medium text-gray-700">
            {t('LoginFormPassword')}
          </label>

          <PasswordInput name="password" size="lg" error={errorMessage} />

          <>
            {!!errorMessage && (
              <label className="mb-1 mt-5 flex items-center rounded-md font-medium text-red-600">
                <ExclamationCircleFill className="mr-2 h-4 w-4" /> {errorMessage}
              </label>
            )}
          </>

          <div className="mt-5 flex items-center justify-between">
            <div className="flex items-center">
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                &nbsp;
              </label>
            </div>

            <div className="text-sm">
              <Link to="/auth/reset-password" className="font-medium text-chill-600 hover:text-chill-500">
                {t('LoginButtonPassword')}
              </Link>
            </div>
          </div>

          <SubmitButton text={t('LoginButtonSign')} loadingText={t('LoginSinging')} name="submit" className="mt-5" />
        </Form>

        {requiredOTP && (
          <Form onSubmit={onSubmit}>
            <h1 className="text-center text-xl font-semibold text-gray-600">{t('LoginFormEnterCode')}</h1>
            <p className="mt-4 text-center text-gray-800">{t('LoginFormAuthenticator')}</p>

            <OTPInput size="6" name="one_time_password" apiResponse={apiResponse} setApiResponse={setApiResponse} />

            <HiddenInput name="email" value={loginData.email} />
            <HiddenInput name="password" value={loginData.password} />

            <SubmitButton text={t('LoginVerify')} loadingText={t('LoginVerifying')} name="submit" className="mt-5" />
          </Form>
        )}
      </div>
    </div>
  )
}
