import { BuildingOfficeIcon, CalendarDaysIcon, XCircleIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { FormattedCurrency, FormattedDateTime } from '../TransactionHelpers'
import ledgerApi from '../../services/ledgerApi'
import Button from '../Button/Button'
import PaymentStatus from '../Payment/PaymentStatus'
import BlockSkeleton from '../Skeleton/BlockSkeleton'
import { CashStack, Wallet2 } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import EmptyState from '../EmptyState/EmptyState'
import { useTranslation } from 'react-i18next'

export default function PendingPayments() {
  const [payments, setPayments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  async function fetchData() {
    ledgerApi.get('/v1/payments').then((response) => {
      setPayments(response.data)
      setIsLoading(false)
    })
  }

  fetchData()

  return (
    <div>
      <div className="overflow-hidden border bg-white sm:rounded-md">
        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg font-medium leading-6 text-gray-900">{t('PaymentsTableLatest')}</h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0 space-x-4">
              {false && (
                <Button variant="primary" as={<Link to="/merchant/payments/new" />}>
                  {t('PaymentsbuttonNewPayments')}
                </Button>
              )}
            </div>
          </div>
        </div>
        {isLoading && <BlockSkeleton />}
        {!isLoading && payments.length > 0 && (
          <ul role="list" className="divide-y divide-gray-200">
            {payments.map((payment) => (
              <li key={payment.id}>
                <a href="#" className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="text flex truncate font-medium text-secondary-900">
                        <FormattedCurrency
                          amount={payment.amount}
                          currency={payment.payment_type === 'VENDOR' ? 'BRL' : 'USD'}
                        />
                      </p>
                      <div className="ml-2 flex flex-shrink-0">
                        {payment.payment_status === 'REVIEW' && <XCircleIcon className="mr-1 w-6 text-red-600" />}
                        <PaymentStatus paymentStatus={payment.payment_status} />
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-xs text-gray-500">
                          {payment.payment_type === 'VENDOR' ? (
                            <>
                              <BuildingOfficeIcon
                                className="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {payment.vendor?.name}
                            </>
                          ) : (
                            <>
                              <CashStack className="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              {t('PaymentsSettlement')}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="mt-2 flex items-center text-xs text-gray-500 sm:mt-0">
                        <CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p>
                          {t('PaymentsCreatedOn')}&nbsp;
                          <time dateTime={payment.created_at}>
                            <FormattedDateTime dateTime={payment.created_at} />
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        )}
        {!isLoading && payments.length <= 0 && (
          <EmptyState
            title={t('PaymentsFirstPayment')}
            description={t('PaymentsDontPayment')}
            icon={<Wallet2 />}
            action={
              <Button variant="primary" as={<Link to="/merchant/payments/new" />}>
                <Wallet2 className="mr-2" />
                {t('PaymentsButtonCreatePayment')}
              </Button>
            }
          />
        )}
      </div>
    </div>
  )
}
