import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { fetchProcessor } from '../../services/gatewayApi'

export default function PaymentProcessor({ processor_id }) {
  const { user } = useAuth()
  const { data, isLoading } = useQuery({
    queryFn: fetchProcessor,
    queryKey: ['fetchProcessor', { processorId: processor_id, userRole: user.role }]
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <div>{data.processors.alias}</div>
}
