import React from 'react'
import Skeleton from './Skeleton'

export default function BlockSkeleton({ size }) {
  return (
    <div className="space-y-6 p-6">
      {[...Array(size || 5).keys()].map((i) => (
        <Skeleton key={i} className="w-full" />
      ))}
    </div>
  )
}
