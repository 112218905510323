// Format for permissions is:
// 0000
// 1000 read
// 0100 create
// 0010 update
// 0001 delete
// 1111 all

export const PERMISSIONS = {
  admin: {
    dashboard: '1111',
    merchants: '1111',
    transactions: '1111',
    compliance: '1111',
    users: '1111'
  },
  merchant_admin: {
    dashboard: '1111',
    transactions: '1111',
    payments: '1111',
    vendors: '1111',
    users: '1111'
  },
  merchant_employee: {
    transactions: '1100'
  },
  merchant_finance: {
    transactions: '1100',
    payments: '1110',
    vendors: '1110'
  }
}
