import React, { useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import {
  ChartPieIcon,
  UsersIcon,
  QueueListIcon,
  ArrowLeftOnRectangleIcon,
  BuildingOfficeIcon,
  WalletIcon
} from '@heroicons/react/24/outline'
import CustomToaster from '../components/CustomToaster/CustomToaster'
import Sidebar from '../components/Sidebar/Sidebar'
import Topbar from '../components/Topbar/Topbar'
import { filterLinksWithPermissions } from '../helpers/utils'
import ZendeskSnippet from '../components/Snippets/ZendeskSnippet'
import { useTranslation } from 'react-i18next'

export default function MerchantLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const navigation = [
    {
      name: t('NavigationButtonDashboard'),
      href: '/merchant/dashboard',
      icon: ChartPieIcon,
      current: true,
      permission: 'dashboard'
    },
    {
      name: t('NavigationButtonTransactions'),
      href: '/merchant/transactions',
      icon: QueueListIcon,
      current: false,
      permission: 'transactions'
    },
    {
      name: t('NavigationButtonPayments'),
      href: '/merchant/payments',
      icon: WalletIcon,
      current: false,
      permission: 'payments'
    },
    {
      name: t('NavigationButtonVendors'),
      href: '/merchant/vendors',
      icon: BuildingOfficeIcon,
      current: false,
      permission: 'vendors'
    },
    {
      name: t('NavigationButtonUsers'),
      href: '/merchant/users',
      icon: UsersIcon,
      current: false,
      permission: 'users'
    }
  ]

  const secondaryNavigation = [
    {
      name: t('NavigationButtonLogout'),
      href: '/auth/sign-out',
      icon: ArrowLeftOnRectangleIcon
    }
  ]
  const availableLinks = useMemo(() => {
    return filterLinksWithPermissions(navigation, auth)
  }, [auth, location])

  if (auth.user.role === 'admin') {
    return <Navigate to="/admin" />
  }

  if (location.pathname === '/merchant' || location.pathname === '/merchant/') {
    return <Navigate to={availableLinks[0].href} />
  }
  return (
    <>
      <div className="min-h-full">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={availableLinks}
          secondaryNavigation={secondaryNavigation}
        />

        <div className="flex flex-1 flex-col lg:pl-64">
          <Topbar userNavigation={[]} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1">
            <CustomToaster />
            <Outlet />
          </main>
        </div>

        <ZendeskSnippet />
      </div>
    </>
  )
}
