import React from 'react'
import { classNames } from '../../helpers/dom'

const sizes = {
  xxs: 'px-[4px] py-[1px] rounded text-[0.6rem]',
  xs: 'px-2 py-0.5 rounded text-xs',
  sm: 'text-sm px-3 py-2 leading-4 rounded-md',
  md: 'text-sm px-4 py-2 rounded-md ',
  lg: 'text-base px-4 py-2 rounded-md ',
  xl: 'text-base px-6 py-3 rounded-md '
}

const variants = {
  white: 'bg-white text-gray-800',
  gray: 'bg-gray-100 text-gray-800',
  muted: 'bg-gray-200 text-gray-500',
  red: 'bg-red-600 text-white',
  yellow: 'bg-yellow-100 text-yellow-800',
  green: 'bg-green-600 text-white',
  blue: 'bg-blue-600 text-white',
  primary: 'bg-chill-100 text-chill-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  purple: 'bg-purple-600 text-white',
  pink: 'bg-pink-600 text-white',
  inverted: 'bg-gray-200 text-gray-900',
  black: 'bg-gray-900 text-white',
  sky: 'bg-sky-600 text-white'
}

export default function Badge({ size = 'xs', variant = 'gray', children, className }) {
  return (
    <>
      <span className={classNames('inline-flex items-center', sizes[size], variants[variant], className)}>
        {children}
      </span>
    </>
  )
}
