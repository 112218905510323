import React from 'react'
import { useFlags } from 'flagsmith/react'
import clsx from 'clsx'

export default function PreviewAlert() {
  const flags = useFlags(['development_badge'])

  if (!flags.development_badge.enabled) {
    return <></>
  }

  return (
    <div
      className={clsx(
        flags.development_badge.enabled ? 'debug-screens flex' : 'hidden',
        'items-center justify-center border-black bg-red-800'
      )}
    >
      <span className="font-bold uppercase text-yellow-300">
        {flags.development_badge.value ? flags.development_badge.value : 'dev'} version
      </span>
    </div>
  )
}
