import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image, Font } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReceiptPercentIcon } from '@heroicons/react/24/outline'
import PoppinsRegular from '../../fonts/Poppins-Regular.ttf'
import PoppinsBold from '../../fonts/Poppins-Bold.ttf'
import PoppinsSemiBold from '../../fonts/Poppins-SemiBold.ttf'
import { accountType, accountWithDigit, hideDocument } from '../../components/TransactionHelpers'
import BrazilSPI from '../../data/BrazilSPI.json'
import Button from '../../components/Button/Button'
import transactionStatementDiscoverFlowStatus from '../../utils/transactionStatementDiscoverFlowStatus'

/**
 * FIXME: It only works for Stark Bank, should be improved to support other banks.
 */
export function TransactionReceiptDocument({ type, transaction, merchant }) {
  const { t } = useTranslation()

  const player = useMemo(
    () =>
      transaction?.statement?.find((statement) => {
        const status = transactionStatementDiscoverFlowStatus(statement)
        return (type === 'refund' && status === 'refund') || (type === 'pay' && status !== 'refund')
      })?.payer,
    [transaction]
  )

  if (!transaction?.statement) return <></>

  Font.register({
    family: 'Poppins',
    format: 'truetype',
    fonts: [
      {
        src: PoppinsRegular,
        fontWeight: 500
      },
      {
        src: PoppinsSemiBold,
        fontWeight: 600
      },
      {
        src: PoppinsBold,
        fontWeight: 700
      }
    ]
  })

  // Font.register()
  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#F4F4F4',
      fontFamily: 'Poppins'
    },
    content: {
      paddingHorizontal: 60
    },
    section: {},
    header: {
      paddingTop: 44,
      paddingBottom: 28
    },
    logo: {
      width: 136,
      height: 44,
      marginLeft: -6
    },
    subheader: {
      marginBottom: 24
    },
    title: {
      fontWeight: 600,
      fontSize: 18
    },
    subtitle: {
      fontWeight: 500,
      fontSize: 9.5,
      color: '#616164',
      marginTop: 2
    },
    title2: {
      fontSize: 11,
      fontWeight: 600
    },
    value: {
      fontSize: 15,
      color: '#0B6C8D',
      marginTop: 2,
      fontWeight: 700,
      marginBottom: 20
    },
    section: {
      marginTop: 16
    },
    sectionHeader: {
      fontSize: 15,
      textTransform: 'uppercase',
      paddingBottom: 4,
      borderBottom: 1,
      borderBottomColor: '#B2B2B2',
      borderStyle: 'dotted'
    },
    sectionBody: {
      paddingTop: 20
    },
    grid2: {},
    row: {
      marginBottom: 20,
      flexDirection: 'row'
    },
    colBig: {
      width: 260
    },
    colBig2: {
      width: 250
    },
    colMedium: {
      width: 212
    },
    colMedium2: {
      width: 222,
      textAlign: 'right'
    },
    colSmall: {
      width: 104
    },
    colTitle: {
      fontSize: 11,
      fontWeight: 600
    },
    colValue: {
      fontSize: 9.5,
      fontWeight: 500
    },
    footer: {
      marginTop: 20,
      paddingTop: 40
    },
    bold: {
      fontWeight: 600
    }
  })

  const bankName = BrazilSPI.filter((b) => b.participante == player.ispb).at(0).nomeReduzidoParticipante

  const account1 = {
    name: player.name,
    document: hideDocument(player.document),
    document_type: 'CPF',
    bank: `${bankName} (${player.ispb})`,
    agency: player.bank_agency,
    account_type: accountType(player.acc_type),
    account_number: accountWithDigit(player.acc_number)
  }

  const account2Stark = {
    name: 'Servicos e Intermediacoes Paag LTDA',
    document: '41.388.405/0001-26',
    document_type: 'CNPJ',
    bank: 'STARK BANK S.A (20018183)',
    agency: '0001',
    account_type: 'Conta Corrente',
    account_number: '6683 4727 1846 2976'
  }

  const account2Aarin = {
    name: 'Servicos e Intermediacoes Paag LTDA',
    document: '41.388.405/0001-26',
    document_type: 'CNPJ',
    bank: 'REPASSES E INTERMEDIACOES (560)',
    agency: '0001',
    account_type: 'Conta Corrente',
    account_number: '352466429'
  }

  const destinationAccount =
    type === 'refund' || transaction.flow_type === 'cashout'
      ? account1
      : transaction.processor.processor_type === 'aarin'
        ? account2Aarin
        : account2Stark

  const originAccount =
    type === 'refund' || transaction.flow_type === 'cashout'
      ? transaction.processor.processor_type === 'aarin'
        ? account2Aarin
        : account2Stark
      : transaction.flow_type === 'cashin' && type === 'pay'
        ? account1
        : destinationAccount

  const statement = transaction?.statement?.find((statement) => {
    const status = transactionStatementDiscoverFlowStatus(statement)
    return (type === 'refund' && status === 'refund') || (type === 'pay' && status !== 'refund')
  })

  const foundDate = statement?.date
  const amount = statement?.amount
  const e2e_id = statement?.e2e_id

  const transactionDate = new Date(`${foundDate}+0000`).toLocaleDateString('pt-BR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })

  return (
    <Document
      title={`Comprovante #${transaction.id} - Paag.io`}
      author="SERVICOS E INTERMEDIACOES PAAG LTDA"
      creator="Paag.io - Backoffice"
      language="pt-BR"
    >
      <Page size={[592, 960]} style={styles.page}>
        <View style={{ ...styles.content, backgroundColor: '#FFF' }}>
          <View style={styles.header}>
            <Image style={styles.logo} src="/paag.png" />
          </View>
          <View style={styles.subheader}>
            <Text style={styles.title}>
              {type === 'refund'
                ? t('TransactionReceiptDocumentRefundVoucher')
                : t('TransactionReceiptDocumentTransferVoucher')}
            </Text>
            <Text style={styles.subtitle}>
              {t('TransactionReceiptDocumentTransferVoucherHeldOnDay')} {transactionDate}
            </Text>
          </View>
          <View>
            <Text style={styles.title2}>{t('TransactionReceiptDocumentValue')}</Text>
            <Text style={styles.value}>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(amount || 0)}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>{t('TransactionReceiptDocumentOriginAccount')}</Text>
            <View style={styles.sectionBody}>
              <View style={styles.grid2}>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentName')}</Text>
                    <Text style={styles.colValue}>{originAccount.name}</Text>
                  </View>
                  <View style={styles.colMedium}>
                    <Text style={styles.colTitle}>{originAccount.document_type}</Text>
                    <Text style={styles.colValue}>{originAccount.document}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentBank')}</Text>
                    <Text style={styles.colValue}>{originAccount.bank}</Text>
                  </View>
                  <View style={styles.colSmall}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentAgency')}</Text>
                    <Text style={styles.colValue}>{originAccount.agency}</Text>
                  </View>
                  <View style={styles.colSmall}>
                    <Text style={styles.colTitle}>{originAccount.account_type}</Text>
                    <Text style={styles.colValue}>{originAccount.account_number}</Text>
                  </View>
                </View>
                {(transaction.flow_type === 'cashout' || type === 'refund') && (
                  <View style={styles.row}>
                    <View style={styles.colSmall}>
                      <Text style={styles.colTitle}>{t('TransactionReceiptDocumentMerchant')}</Text>
                      <Text style={styles.colValue}>{merchant.merchant.name}</Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>{t('TransactionReceiptDocumentDestinationAccount')}</Text>
            <View style={styles.sectionBody}>
              <View style={styles.grid2}>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentName')}</Text>
                    <Text style={styles.colValue}>{destinationAccount.name}</Text>
                  </View>
                  <View style={styles.colMedium}>
                    <Text style={styles.colTitle}>{destinationAccount.document_type}</Text>
                    <Text style={styles.colValue}>{destinationAccount.document}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentBank')}</Text>
                    <Text style={styles.colValue}>{destinationAccount.bank}</Text>
                  </View>
                  <View style={styles.colSmall}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentAgency')}</Text>
                    <Text style={styles.colValue}>{destinationAccount.agency}</Text>
                  </View>
                  <View style={styles.colSmall}>
                    <Text style={styles.colTitle}>{destinationAccount.account_type}</Text>
                    <Text style={styles.colValue}>{destinationAccount.account_number}</Text>
                  </View>
                </View>
                {transaction.flow_type === 'cashin' && type !== 'refund' && (
                  <View style={styles.row}>
                    <View style={styles.colSmall}>
                      <Text style={styles.colTitle}>{t('TransactionReceiptDocumentMerchant')}</Text>
                      <Text style={styles.colValue}>{merchant.merchant.name}</Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>{t('TransactionReceiptDocumentTransferInformation')}</Text>
            <View style={styles.sectionBody}>
              <View style={styles.grid2}>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentE2ETransactionID')}</Text>
                    <Text
                      style={{
                        ...styles.colValue,
                        textTransform: 'capitalize'
                      }}
                    >
                      {e2e_id}
                    </Text>
                  </View>
                  <View style={styles.colMedium}>
                    <Text style={styles.colTitle}>{t('TransactionReceiptDocumentType')}</Text>
                    <Text style={styles.colValue}>{transaction.transaction_type.toUpperCase()}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.colBig}>
                    <Text style={styles.colTitle}>Servicos e Intermediacoes Paag LTDA</Text>
                    <Text style={styles.colValue}>41.388.405/0001-26 </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ height: 30 }}></View>
        </View>
        <View style={styles.footer}>
          <View style={styles.content}>
            <View style={styles.row}>
              <View style={styles.colBig2}>
                <Text style={styles.colTitle}>{t('TransactionReceiptDocumentFooterTitle')}</Text>
                <Text style={styles.colValue}>
                  {t('TransactionReceiptDocumentFooterDescription1')}{' '}
                  <Text style={styles.bold}>{t('TransactionReceiptDocumentFooterDescription2')}</Text>
                  {/* ou entre em contato com nosso suporte pelo Whatsapp
                  <Text style={styles.bold}>+55 31 99876-5432</Text> */}
                  .
                </Text>
              </View>
              <View style={styles.colMedium2}>
                <Text style={styles.colValue}>
                  <Image
                    src="/paag-icone.png"
                    style={{
                      width: 40,
                      height: 40
                    }}
                  ></Image>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default function TransactionReceiptPdf({ type, transaction, merchant }) {
  return (
    <PDFDownloadLink
      document={<TransactionReceiptDocument type={type} transaction={transaction} merchant={merchant} />}
      fileName={`receipt-${transaction.id}.pdf`}
    >
      {({ blob, url, loading, error }) => !loading && transaction.statement && <Button variant="link">Download</Button>}
    </PDFDownloadLink>
  )
}
