import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { createPortal } from 'react-dom'
import { Toaster, ToastIcon, toast, resolveValue } from 'react-hot-toast'

export default function CustomToaster() {
  return createPortal(
    <div>
      <Toaster position="right-bottom">
        {(toast) => (
          <div
            className={`${
              toast.visible ? 'animate-enter' : 'animate-leave'
            } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
          >
            <div className="w-0 flex-1 p-4">
              <div className="flex items-center">
                {toast.type !== 'blank' && (
                  <div className="mr-3 flex-shrink-0 border-r pt-0.5">
                    <ToastIcon toast={toast} />
                  </div>
                )}
                <div className="flex-1 pl-3">
                  <p className="text-sm font-medium text-gray-900">{resolveValue(toast.message)}</p>
                </div>
              </div>
            </div>
            <div className="flex  border-gray-200">
              <button
                onClick={() => {
                  toast.remove(toast.id)
                }}
                className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-xs font-medium text-chill-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                <XMarkIcon className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          </div>
        )}
      </Toaster>
    </div>,
    document.body
  )
}
