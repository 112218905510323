import React from 'react'
import Badge from '../Badge/Badge'
import { classNames } from '../../helpers/dom'
import { useTranslation } from 'react-i18next'

export default function MerchantStatuses({ merchant, size, className }) {
  const { t } = useTranslation()
  const statuses = {
    DRAFT: {
      text: t('AdmimsStatusDraft'),
      variant: 'gray'
    },
    ACTIVE: {
      text: t('AdmimsStatusAcive'),
      variant: 'green'
    },
    BLOCKED: {
      text: t('AdmimsStatusBlocked'),
      variant: 'red'
    }
  }
  return (
    <Badge
      className={classNames('mr-2', className)}
      variant={statuses[merchant.merchant_statuses]?.variant}
      size={size || ''}
    >
      {statuses[merchant.merchant_statuses]?.text}
    </Badge>
  )
}
