import React from 'react'
import { classNames } from '../../helpers/dom'
import SimplePagination from '../Pagination/SimplePagination'
import RowSkeleton from '../Skeleton/RowSkeleton'

const RowColumn = ({ row, column, index }) => {
  const nodeValue = row?.[column.node]
  const columnValue = column.render ? <>{column.render(row, index)}</> : <>{nodeValue}</>

  return (
    <td
      className={classNames(
        'whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6',
        column.onlyDesktop ? 'hidden sm:table-cell' : '',
        column.rowClassName
      )}
    >
      {columnValue}
    </td>
  )
}

export default function Table({ columns, rows, isLoading, isRefetching, emptyState, pagination, hasFilters }) {
  return (
    <div>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            className={classNames(
              'overflow-hidden sm:border sm:border-gray-200',
              hasFilters ? 'md:rounded-b-md' : 'md:rounded-md'
            )}
          >
            {rows.length == 0 && !isLoading && emptyState ? (
              emptyState
            ) : (
              <>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-stone-100">
                    <tr>
                      {columns.map((column) => (
                        <th
                          key={`column_${column.title}`}
                          scope="col"
                          className={classNames(
                            'py-3 pl-4 pr-3 text-left text-xs font-semibold uppercase text-gray-600 sm:pl-6',
                            column.onlyDesktop ? 'hidden sm:table-cell' : '',
                            column.headerClassName
                          )}
                        >
                          {column.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {isLoading ? (
                      <RowSkeleton times={3} cols={columns.length} />
                    ) : (
                      <>
                        {rows.map((row, indexRow) => (
                          <tr key={`r${indexRow}`} className="hover:bg-gray-100 active:bg-gray-100">
                            {columns.map((column, indexColumn) => (
                              <RowColumn
                                row={row}
                                column={column}
                                index={indexRow}
                                key={`r${indexRow}c${indexColumn}`}
                              />
                            ))}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                  <tfoot></tfoot>
                </table>
                <SimplePagination pagination={pagination} isLoading={isLoading} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
