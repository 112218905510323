import React from 'react'
import { classNames } from '../../helpers/dom'
import { useTranslation } from 'react-i18next'

const variants = {
  primary: {
    wrapper: 'bg-gray-50',
    section: 'mx-auto px-4 sm:px-6 md:px-8'
  },
  secondary: {
    wrapper: 'bg-white',
    section: 'mx-auto px-4 sm:px-6 md:px-8'
  },
  simple: {
    wrapper: 'bg-white',
    section: ''
  }
}

export default function PageHeader({ title, subtitle, button, variant, avatar }) {
  const activeVariant = variants[variant || 'primary']
  const { t } = useTranslation()

  return (
    <>
      <div className={classNames('border-b border-gray-100 py-6', activeVariant['wrapper'], activeVariant['section'])}>
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <div className="hidden sm:flex">{avatar && avatar}</div>

              <div>
                <div className="flex items-center">
                  <div className="sm:hidden">{avatar && avatar}</div>
                  <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9 md:ml-3">
                    {title}
                  </h1>
                </div>
                <dl className="flex flex-col sm:ml-3 sm:flex-row sm:flex-wrap md:mt-1">
                  <dt className="sr-only">{t('PageHeaderCompany')}</dt>
                  <dd className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">{subtitle}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">{button}</div>
        </div>
      </div>
    </>
  )
}
