import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { classNames } from '../../../helpers/dom'

const SelectCardElement = React.forwardRef(({ name, states, onChange, options, propagateChange }, ref) => {
  const [selected, setSelected] = useState({})

  const handleSelect = (option) => {
    onChange(option.id)
    propagateChange(option.id)
    setSelected(option)
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      {options.map((option) => (
        <div
          className={classNames(
            'cursor-pointer rounded-md border-2 p-4 hover:border-chill-500',
            option == selected ? 'border-chill-500 bg-sky-50' : ''
          )}
          key={option.id}
          onClick={() => handleSelect(option)}
        >
          <h3 className="font-medium text-chill-700">{option.title}</h3>
          <h4 className="mt-1 text-xs text-chill-500">{option.hint}</h4>
        </div>
      ))}
    </div>
  )
})

export default function SelectCardInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={(props) => (
        <SelectCardElement name={name} states={props.formState} inputRef={props.ref} {...props.field} {...rest} />
      )}
    />
  )
}
