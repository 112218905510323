import React from 'react'
import Badge from '../Badge/Badge'
import Button from '../Button/Button'
import { ChevronDown, ChevronUp } from 'lucide-react'
import gatewayApi from '../../services/gatewayApi'

const setPrecedence = async (processor, direction) => {
  const newPrecedence = direction == 'up' ? (processor.precedence -= 1) : (processor.precedence += 1)

  gatewayApi
    .put(`/app/admin/processors/${processor.id}/${newPrecedence}`)
    .then((r) => window.location.reload())
    .catch((e) => console.log(e))
}

const ProcessorPrecedence = ({ processor }) => {
  const handleUp = (event) => {
    event.preventDefault()
    setPrecedence(processor, 'up')
  }

  const handleDown = (event) => {
    event.preventDefault()
    setPrecedence(processor, 'down')
  }

  return (
    <div className="flex space-x-2">
      <div className="space-x-1">
        <Button size="xxs" variant="fail" onClick={handleUp}>
          <ChevronUp className="h-3 w-3" />
        </Button>
        <Button size="xxs" variant="tertiary" onClick={handleDown}>
          <ChevronDown className="h-3 w-3" />
        </Button>
      </div>
      <Badge>{processor.precedence}</Badge>
    </div>
  )
}

export default ProcessorPrecedence
