import { CogIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StatsCard from '../../../components/StatsCard/StatsCard'
import kernApi from '../../../services/kernApi'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import { useTranslation } from 'react-i18next'

export default function MerchantMainStats() {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { merchantId } = useParams()

  useEffect(() => {
    kernApi
      .get('/app/admin/stats/merchants')
      .then((response) => {
        setData({
          result: response.data.result.rows,
          retrieved_at: response.data.retrieved_at
        })
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }, [])

  const stats = [
    {
      key: 'current_amount',
      comparison_key: 'past_amount',
      title: t('MerchantMainStatsLast'),
      render: (value) => <FormattedCurrency amount={value} />
    },
    {
      key: 'current_count',
      comparison_key: 'past_count',
      title: t('MerchantMainStatsLast30Days'),
      render: (value) => new Intl.NumberFormat().format(value || 0)
    },
    {
      key: 'total_amount',
      comparison_key: false,
      title: t('MerchantMainStatsTotal'),
      render: (value) => <FormattedCurrency amount={value} />
    }
  ]

  return (
    <div>
      <div className="mx-auto grid gap-5 lg:grid-cols-3 lg:px-6">
        {stats.map((stat) => {
          const currentData = data.result ? data.result.find((d) => d.merchant_id == merchantId) : {}
          const current = currentData?.[stat.key]
          const past = currentData?.[stat.comparison_key]
          const change = Math.round((current / past - 1) * 100, 2)

          return (
            <StatsCard
              name={stat.title}
              icon={CogIcon}
              changeType={change > 0 ? 'increase' : 'decrease'}
              change={stat.comparison_key && change && `${change}%`}
              stat={stat.render(current)}
              isLoading={isLoading}
            />
          )
        })}
      </div>
    </div>
  )
}
