import React, { useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import { phone } from 'phone'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import { useNavigate } from 'react-router-dom'
import FieldSet from '../../../components/Form/FieldSet'
import Form from '../../../components/Form/Form'
import FormSection from '../../../components/Form/FormSection'
import FileInput from '../../../components/Form/Inputs/FileInput'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import ledgerApi from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'
import RadioGroup from '../../../components/RadioGroup/RadioGroup'
import { getMaskByPixKeyType } from '../../../utils/inputMasks'
import { stripNonNumbers } from '../../../helpers/utils'
import { validateEmail } from '../../../utils/validate'

export default function NewVendor() {
  const [open, setOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [pixKeyType, setPixKeyType] = useState('DOCUMENT')
  const [document, setDocument] = useState()
  const formRef = useRef()
  const submitRef = useRef()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    switch (pixKeyType) {
      case 'DOCUMENT':
        formRef.current?.setValue('pix_key', document)
        break
      case 'PHONE':
        formRef.current?.setValue('pix_key', formRef.current?.getValues('phone'))
        break
      default:
        formRef.current?.setValue('pix_key', '')
    }
  }, [pixKeyType])

  useEffect(() => {
    if (pixKeyType === 'DOCUMENT') {
      formRef.current?.setValue('pix_key', document)
    }
  }, [document])

  const schema = z.object({
    name: z.string().min(1, t('VendorsSidePainelErrorCompany')),
    document: z.string().refine((data) => cnpjValidator.isValid(data), t('VendorsSidePainelErrorCnpj')),
    phone: z.string().refine((data) => {
      const phoneNumber = stripNonNumbers(data)
      const phoneLength = phoneNumber.length
      return phoneLength === 12 || phoneLength === 13
    }, t('VendorsSidePainelErrorPhone')),
    bank_number: z.string().min(1, t('VendorsSidePainelErrorBank')),
    bank_agency: z.string().min(1, t('VendorsSidePainelErrorAgency')),
    bank_account: z.string().min(1, t('VendorsSidePainelErrorAccount')),
    file_social_contract_url: z.any().refine((data) => Boolean(data), t('VendorsSidePainelErrorSocial')),
    file_document_url: z.any().refine((data) => Boolean(data), t('VendorsSidePainelErrorCnpjDocument')),
    pix_key: z
      .string()
      .refine(
        (data) => pixKeyType !== 'DOCUMENT' || cnpjValidator.isValid(data),
        t('VendorsSidePainelErrorPixKeyDocument')
      )
      .refine((data) => pixKeyType !== 'PHONE' || phone(data).isValid, t('VendorsSidePainelErrorPixKeyPhone'))
      .refine((data) => pixKeyType !== 'EMAIL' || validateEmail(data), t('VendorsSidePainelErrorPixKeyEmail'))
  })

  const handleSubmit = (data, event) => {
    const formattedData = {
      ...data,
      pix_type: pixKeyType.toUpperCase(),
      phone: `+${stripNonNumbers(data.phone)}`,
      document: stripNonNumbers(data.document),
      pix_type: pixKeyType,
      get pix_key() {
        switch (pixKeyType) {
          case 'DOCUMENT':
          case 'PHONE':
            return stripNonNumbers(data.pix_key)
          default:
            return data.pix_key
        }
      }
    }

    ledgerApi
      .post('/v1/vendors', formattedData)
      .then((response) => {
        navigate('/merchant/vendors?r=true')
      })
      .catch((e) => console.log('e', e))
  }

  const handleError = () => {}

  const pixKeyMask = getMaskByPixKeyType(pixKeyType)

  return (
    <>
      <SidePanel open={open} setOpen={setOpen} onClose={() => navigate('/merchant/vendors')}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title={t('VendorsSidePainelCreate')} />
          <SidePanelBody>
            <Form
              onSubmit={handleSubmit}
              onError={handleError}
              isLoading={isLoading}
              submitRef={submitRef}
              onChange={(values) => setDocument(values.document)}
              ref={formRef}
              className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
              schema={schema}
            >
              <FormSection name={t('VendorsSidePainelTitle')} description={t('VendorsSidePainelSubtitles')} />
              <FieldSet name="name" label={t('VendorsSidePainelCompany')} />
              <FieldSet name="document" label={t('VendorsSidePainelCnpj')} mask="99.999.999/9999-99" />
              <FieldSet name="phone" label={t('VendorsSidePainelPhone')} mask="+99 (99) 9999-99999" />
              <FormSection name={t('VendorsSidePainelBank')} description={t('VendorsSidePainelBankSubtitle')} />
              <FieldSet name="bank_number" label={t('VendorsSidePainelBankInsert')} />
              <FieldSet name="bank_agency" label={t('VendorsSidePainelAgency')} />
              <FieldSet name="bank_account" label={t('VendorsSidePainelAccount')} />
              <FormSection
                name={t('VendorsSidePainelPixKeyTitle')}
                description={t('VendorsSidePainelPixKeyDescription')}
              />
              <FieldSet
                mask={pixKeyMask}
                name="pix_key"
                label={t('VendorsSidePainelPixKey')}
                disabled={pixKeyType === 'DOCUMENT'}
                HeaderComponent={() => {
                  return (
                    <div className="pt-2">
                      <RadioGroup
                        disableHorizontalSpacing
                        options={[
                          {
                            label: t('VendorsSidePainelPixKeyTypeCnpj'),
                            value: 'DOCUMENT'
                          },
                          { label: t('VendorsSidePainelPixKeyTypePhone'), value: 'PHONE' },
                          { label: t('VendorsSidePainelPixKeyTypeEmail'), value: 'EMAIL' },
                          { label: t('VendorsSidePainelPixKeyTypeRandom'), value: 'RANDOM' }
                        ]}
                        value={pixKeyType}
                        onChange={(value) => {
                          setPixKeyType(value)
                          formRef.current.clearErrors('pix_key')
                        }}
                      />
                    </div>
                  )
                }}
              />
              <FormSection name={t('VendorsSidePainelDocuments')} />
              <FieldSet
                name="file_social_contract_url"
                label={t('VendorsSidePainelSocialContract')}
                input={<FileInput />}
              />
              <FieldSet
                name="file_document_url"
                label={t('VendorsSidePainelCnpjDocument')}
                hint={
                  <div className="prose">
                    {t('VendorsSidePainelTextToEmit')}{' '}
                    <a
                      href="https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp"
                      target="_blank"
                      className="font-bold text-chill-500"
                    >
                      {t('VendorsSidePainelButtonClick')}
                    </a>
                    .
                  </div>
                }
                input={<FileInput />}
              />
            </Form>
          </SidePanelBody>
          <SidePanelFooter>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              {t('VendorsSidePainelButtonCancel')}
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-chill-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-chill-700 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => {
                submitRef.current.click()
              }}
            >
              {t('VendorsSidePainelButtonSave')}
            </button>
          </SidePanelFooter>
        </div>
      </SidePanel>
    </>
  )
}
