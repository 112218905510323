import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import PT_BR from './locales/pt/pt-br.json'
import EN_US from './locales/en/en-us.json'
import en from 'javascript-time-ago/locale/en.json'
import pt from 'javascript-time-ago/locale/pt.json'
import TimeAgo from 'javascript-time-ago'

const resources = {
  'pt-BR': PT_BR,
  'en-US': EN_US
}

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language,
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false
  }
})

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(pt)

export default i18n
