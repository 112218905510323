import React from 'react'
import Skeleton from '../Skeleton/Skeleton'

export default function DetailedListItem({ title, children, value, isLoading }) {
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        {isLoading ? <Skeleton /> : children ? children : value}
      </dd>
    </div>
  )
}
