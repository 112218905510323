import { BookmarkSlashIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React from 'react'

export default function StatsPill({ title, value, icon, action, className }) {
  return (
    <dt className={clsx(className, 'group cursor-default overflow-hidden rounded-lg bg-gray-50 hover:bg-gray-100')}>
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {React.createElement(icon.type, {
              className: 'h-6 w-6 text-chill'
            })}
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="mt-1 text-xl font-semibold text-chill-800 group-hover:text-gray-800">{value}</dt>
              <dd className="">
                <div className="truncate text-sm font-medium uppercase text-secondary-400 group-hover:text-secondary-600">
                  {title}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {action && (
        <div className="bg-gray-50 px-5 py-3">
          <div className="text-sm">{action}</div>
        </div>
      )}
    </dt>
  )
}
