import React from 'react'
import { Button, Dialog, Flex } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'

const ConfirmationPayment = ({ isOpen, children, setIsOpen, onProceed }) => {
  const { t } = useTranslation()

  return (
    <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <Dialog.Content maxWidth="480px">
        <Dialog.Title className="text-lg font-bold mb-6">{t('ConfirmationPaymentTitle')}</Dialog.Title>
        {children}
        <Flex justify="between" mt="6" gap="2">
          <Button variant="outline" color="gray" className="w-[48%]" onClick={() => setIsOpen(false)}>
            {t('CancelButton')}
          </Button>
          <Button className="bg-orange-600 hover:bg-orange-800 w-[48%]" onClick={onProceed}>
            {t('ProceedButton')}
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default ConfirmationPayment
