import React, { createElement, FC } from 'react'
import cn from 'clsx'

function Loading({ className }) {
  return (
    <div className="flex w-10 items-center justify-center">
      <svg className={cn('animate-spin', className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={4}></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  )
}

export default function Button({
  children,
  variant = 'primary',
  size = 'md',
  disabled,
  isLoading,
  className,
  as,
  onClick,
  ...rest
}) {
  const variants = {
    primary: {
      true: 'border border-transparent text-white bg-chill-700 hover:bg-chill-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chill-500',
      false:
        'cursor-not-allowed border border-transparent text-white bg-chill-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-chill-500 opacity-50'
    },
    secondary: {
      true: 'border border-transparent text-secondary-800 bg-secondary-200 hover:bg-secondary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500',
      false: ''
    },
    tertiary: {
      true: 'border border-transparent text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500',
      false: ''
    },
    success: {
      true: 'border border-green-600 text-white bg-green-600 hover:bg-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
      false: 'cursor-not-allowed border border-gray-200 text-gray-400 bg-gray-100'
    },
    fail: {
      true: 'border border-red-600 text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
      false: 'cursor-not-allowed border border-gray-200 text-gray-400 bg-gray-100'
    },
    white: {
      true: 'border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
      false: 'cursor-not-allowed border border-gray-200 text-gray-400 bg-gray-100'
    },
    simple: {
      true: 'text-gray-700 hover:text-gray-900',
      false: 'cursor-not-allowed text-gray-300'
    },
    link: {
      true: 'text-chill-700 hover:text-chill-900',
      false: 'cursor-not-allowed text-gray-300'
    },
    outline: {
      true: 'text-chill-300 p-0 m-0 px-0 py-0 font-medium'
    }
  }

  const sizes = {
    xxs: 'text-xs px-1 py-1 rounded',
    xs: 'text-xs px-2 py-1 rounded',
    sm: 'text-sm px-3 py-2 leading-4 rounded-md',
    md: 'text-sm px-4 py-2 rounded-md ',
    lg: 'text-base px-4 py-2 rounded-md ',
    xl: 'text-base px-6 py-3 rounded-md '
  }

  const handleOnClick = (e) => {
    if (disabled || isLoading) {
      e.preventDefault()
      return
    }
    onClick && onClick(e)
  }

  return React.cloneElement(
    as || <button />,
    {
      className: cn(
        'inline-flex items-center font-medium text-center justify-center gap-1',
        sizes[size],
        variants[variant][(!disabled).toString()],
        className
      ),
      disabled: disabled,
      onClick: handleOnClick,
      ...rest
    },
    (isLoading && <Loading className="w-5" />) || children
  )
}
