import React from 'react'
import { BanknotesIcon, CheckCircleIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import Tooltip from './Popover/Tooltip'
import { classNames } from '../helpers/dom'
import { ArrowDownSquareFill, ArrowUpSquareFill, QuestionCircleFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const flowTypeMap = {
  cashin: {
    label: 'TransactionsHelpersCashIn',
    icon: <ArrowUpSquareFill className="h-5 w-5 text-chill-500" />
  },
  cashout: {
    label: 'TransactionsHelpersCashOut',
    icon: <ArrowDownSquareFill className="h-5 w-5 text-orange-500" />
  },
  settlement: {
    label: 'TransactionsHelpersCashOut',
    icon: <ArrowDownSquareFill className="h-5 w-5 text-orange-500" />
  }
}

export const FormattedCurrency = ({ amount, currency, colorize = false, className = '' }) => {
  return (
    <>
      <span
        className={clsx(className, {
          'text-red-600': colorize && amount < 0,
          'text-green-600': colorize && amount > 0
        })}
      >
        {new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: currency || 'BRL'
        }).format(amount || 0)}
      </span>
    </>
  )
}

export const FormattedNumber = ({ amount, locale = 'pt-BR', currency = 'BRL', isLoading = false }) => {
  return (
    <span className={clsx(isLoading ? 'animate-pulse blur-sm' : '', '')}>
      {['BRL', 'USD'].includes(currency)
        ? new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency || 'BRL'
          }).format(amount || 0)
        : amount || 0}
    </span>
  )
}

export const FormattedDateTime = ({ dateTime }) => {
  const date = new Date(dateTime)
  const formattedDate = date.toLocaleString()

  return <time dateTime={date}>{formattedDate}</time>
}

export function FormattedDate({ date, options, locale, timezone }) {
  const [year, month, day] = date?.split('-') || ''

  const parsedDate = new Date(year, month - 1, day)
  const defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  const formattedDate = new Date(parsedDate).toLocaleDateString(locale || 'pt-BR', {
    ...defaultOptions,
    ...options
  })

  return <span>{formattedDate}</span>
}
export const TransactionAmount = ({ transaction, align }) => {
  const { t } = useTranslation()
  const event = transaction?.transaction_events?.[0] || {
    amount: 0,
    event_type: 'error'
  }

  const { label, icon } = flowTypeMap[transaction.flow_type] || {}

  return (
    <div className={`flex items-center ${align === 'left' ? '' : 'justify-end'}`}>
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: transaction?.processor?.currency || 'BRL'
        }).format(event?.amount || 0)}
      </span>
      <span className="ml-2">
        <Tooltip text={t(label)}>{icon}</Tooltip>
      </span>
    </div>
  )
}

export const TransactionTax = ({ transaction }) => {
  const event = transaction?.transaction_events?.[0] || {
    amount: 0,
    event_type: 'error'
  }
  return <FormattedCurrency amount={event.amount * (event.event_type == 'payment' ? 0.008 : 0.018)} currency="BRL" />
}

export const TransactionHash = ({ transaction }) => {
  const transactionHash = transaction.split('-')[0]
  return <span className="uppercase">{transactionHash}</span>
}

export const TransactionStatus = ({ transaction, extendedFormat = false }) => {
  const { t } = useTranslation()
  const lastEvent = transaction.transaction_events[0]
  const failReason =
    (typeof transaction.meta == 'string' && JSON.parse(transaction.meta).risk.reason) ||
    (transaction.error != null && typeof transaction.error.message == 'string' && transaction.error.message)

  const statuses = {
    capture: {
      success: {
        styles: 'bg-green-100 text-green-800',
        text: t('TransactionsHelpersSuccess')
      },
      error: {
        styles: 'bg-chill-200 text-chill-800',
        text: t('TransactionsHelpersExpired')
      }
    },
    auth: {
      success: {
        styles: 'bg-gray-200',
        text: t('TransactionsHelpersPending')
      },
      error: {
        styles: 'bg-red-100 text-red-800 cursor-help',
        text: (
          <>
            {t('TransactionsHelpersFailed')} <QuestionCircleFill className="ml-1 mt-1" />
          </>
        )
      }
    },
    payment: {
      success: {
        styles: 'bg-green-100 text-green-800',
        text: t('TransactionsHelpersSuccess')
      },
      error: {
        styles: 'bg-red-100 text-red-800',
        text: (
          <>
            {t('TransactionsHelpersFailed')} <QuestionCircleFill className="ml-1 mt-1" />
          </>
        )
      }
    }
  }

  return (
    <div className="group relative z-10 inline-block">
      <span
        className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
          statuses?.[lastEvent.event_type]?.[lastEvent.success ? 'success' : 'error']?.styles
        }`}
      >
        {statuses?.[lastEvent.event_type]?.[lastEvent.success ? 'success' : 'error']?.text}
      </span>
      {lastEvent && !lastEvent.success && (
        <span
          className={classNames(
            'ml-2 text-red-800',
            extendedFormat
              ? ''
              : 'absolute -top-2 right-0 z-20 hidden -translate-y-full rounded-lg bg-red-100 p-2 font-bold shadow-md group-hover:block'
          )}
        >
          {failReason}
        </span>
      )}
    </div>
  )
}

export const TransactionType = ({ transaction }) => {
  const types = {
    capture: {
      classes: 'text-green-700',
      text: 'Capture',
      icon: CheckCircleIcon
    },
    auth: {
      classes: 'text-cyan-700',
      text: 'Auth',
      icon: QrCodeIcon
    },
    payment: {
      classes: 'text-chill-700',
      text: 'Payment',
      icon: BanknotesIcon
    }
  }

  const currentType = types[transaction.event_type]

  return (
    <div className="flex items-center">
      <div>{currentType.icon.render({ className: `h-5 ${currentType.classes}` })}</div>
      <div className={`ml-2 ${currentType.classes}`}>{currentType.text}</div>
    </div>
  )
}

export const TRANSACTION_FILTER_DEFAULTS = {
  name: '',
  e2e: '',
  document: '',
  email: '',
  type: [],
  status: []
}

export const hideDocument = (document_number) => {
  if (document_number.length == 11) {
    return document_number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**')
  }

  if (document_number.length == 14) {
    return document_number.replace(/(\d{3})\.(\d{3})\.(\d{3})\-(\d{2})/, '***.$2.$3-**')
  }

  return document_number
}

export const accountWithDigit = (acc) => {
  return acc.substring(0, acc.length - 1) + '-' + acc.substring(acc.length - 1)
}

export const accountType = (tp) => {
  if (tp === 'savings') return 'Conta Poupança'
  if (tp === 'checking') return 'Conta Corrente'
  if (tp === 'payment') return 'Conta Corrente'

  return 'Conta'
}
