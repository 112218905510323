import React from 'react'
import classNames from 'classnames'
import ErrorIconCircle from '../@ui/icons/Errocircle'

export default function Alert({ title, subtitle, variant = 'error', className }) {
  return (
    <div
      className={classNames(
        'flex gap-2 rounded-xl border p-4',
        {
          'border-red-300': variant === 'error'
        },
        className
      )}
    >
      <div>
        <ErrorIconCircle className="h-[35px] w-[35px]" />
      </div>
      <div className="flex flex-col gap-1">
        {title && <span className="text-sm font-semibold text-left text-slate-700">{title}</span>}
        {subtitle && <span className="text-sm text-left text-slate-600">{subtitle}</span>}
      </div>
    </div>
  )
}
