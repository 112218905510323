import React from 'react'

export default function SectionHeader({ title, subtitle }) {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold text-secondary-800">{title}</h3>
      {subtitle && <h4 className="text-secondary-600">{subtitle}</h4>}
    </div>
  )
}
