export const PROCESSORS = [
  {
    name: 'Aarin',
    code: 'aarin',
    color: 'indigo',
    credentials: [
      {
        humanName: 'Client ID',
        name: 'client_id',
        field: 'credentials_1',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Client Secret',
        name: 'client_secret',
        field: 'credentials_2',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Account ID',
        name: 'x_account_id',
        field: 'credentials_3',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Dict Key',
        name: 'dict_key',
        field: 'credentials_4',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      }
    ]
  },
  {
    name: 'Stark',
    code: 'stark',
    color: 'sky',
    credentials: [
      {
        humanName: 'Stark Environment',
        name: 'stark_environment',
        field: 'credentials_1',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Stark Project ID',
        name: 'stark_project_id',
        field: 'credentials_2',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Stark Private Key',
        name: 'stark_private_key',
        field: 'credentials_20',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      }
    ]
  },
  {
    name: 'BS2',
    code: 'bs2',
    credentials: [
      {
        humanName: 'API Key',
        name: 'api_key',
        field: 'credentials_1',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'API Secret',
        name: 'api_secret',
        field: 'credentials_2',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'PIX Key',
        name: 'pix_key',
        field: 'credentials_3',
        validation: ['required', 'alpha_num'],
        rules: {
          required: 'This field is required'
        },
        default: null
      }
    ]
  },
  {
    name: 'Iugu',
    code: 'iugu',
    credentials: [
      {
        humanName: 'Iugu Token',
        name: 'iugu_token',
        field: 'credentials_1',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Iugu Recipient Account ID',
        name: 'iugu_recipient_account_id',
        field: 'credentials_2',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      }
    ]
  },
  {
    name: 'Iugu Card',
    code: 'iugu_card',
    credentials: [
      {
        humanName: 'Iugu Token',
        name: 'iugu_token',
        field: 'credentials_1',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      },
      {
        humanName: 'Iugu Recipient Account ID',
        name: 'iugu_recipient_account_id',
        field: 'credentials_2',
        validation: ['required', 'alpha_num'],
        rules: {
          required: true
        },
        default: null
      }
    ]
  }
]

export const PROCESSOR_MODES = [
  { id: 'live', value: 'Production Mode (Live)' },
  { id: 'test', value: 'Test Mode (Staging)' }
]

export const TRANSACTION_TYPES = [
  { id: 'pix', value: 'PIX' },
  { id: 'card', value: 'Card' },
  { id: 'boleto', value: 'Boleto' }
  // { id: "check", value: "Check" },
  // { id: "external", value: "External" },
  // { id: "recurring", value: "Recurring" },
  // { id: "wallet", value: "Wallet" },
]

export const processorByCode = (code) => {
  return PROCESSORS.find((p) => p.code === code)
}
