import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function SidePanelHeader({ title, subtitle, onClose, children }) {
  return (
    <header className="pointer-events-auto print:hidden">
      <div className="bg-chill-700 px-4 py-6 sm:px-5">
        <div className="flex items-start justify-between space-x-3">
          <div className="space-y-1">
            <Dialog.Title className="text-lg font-semibold text-white">{title}</Dialog.Title>
            <p className="text-sm text-chill-100">{subtitle}</p>
          </div>
          <div className="flex h-7 items-center">
            <button type="button" className="text-chill-200 hover:text-chill-300" onClick={onClose}>
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {children}
    </header>
  )
}
