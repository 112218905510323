import React from 'react'

export default function DetailedList({ children, title, icon, button }) {
  return (
    <>
      {title && (
        <div className="flex justify-between border-b border-gray-200 py-3 sm:px-5 sm:py-5">
          <h3 className="flex items-center text-lg font-semibold leading-6 text-gray-900">
            {icon && icon.render({ className: 'h-5 w-5 text-gray-900 mr-2' })}
            <span>{title}</span>
          </h3>
          {button}
        </div>
      )}

      <dl className="divide-y divide-gray-100 sm:divide-gray-200">{children}</dl>
    </>
  )
}
