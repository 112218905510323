import React, { useState } from 'react'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import { useQuery } from '@tanstack/react-query'
import { fetchAdminMerchantVendors } from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'

export default function ListVendors() {
  const { t } = useTranslation()
  const { data, isLoading, isRefetching } = useQuery({
    queryFn: fetchAdminMerchantVendors,
    queryKey: ['listAdminMerchantVendors']
  })
  const columns = [
    {
      title: t('AdmimVendors'),
      render: (vendor) => (
        <div>
          <h1 className="font-semibold">{vendor.name}</h1>
          <h2 className="text-xs">{vendor.document}</h2>
        </div>
      )
    },
    {
      title: ''
    }
  ]

  return (
    <div>
      <PageHeader title={t('AdmimVendors')} subtitle={t('AdminVendorsSubtitle')} />

      <Main className="pt-4">
        <Table isRefetching={isRefetching} isLoading={isLoading} columns={columns} rows={data || []} />
      </Main>
    </div>
  )
}
