import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import Badge from '../../../../components/Badge/Badge'
import Button from '../../../../components/Button/Button'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import PageHeader from '../../../../components/Pages/PageHeader'
import Table from '../../../../components/Table/Table'
import YesNo from '../../../../components/YesNo/YesNo'
import { processorByCode } from '../../../../data/ProcessorCredentials'
import gatewayApi from '../../../../services/gatewayApi'
import { useTranslation } from 'react-i18next'
import ProcessorPrecedence from '../../../../components/Processors/Precedence'

const ProcessorType = ({ type }) => {
  const processor = processorByCode(type)
  return (
    <div>
      <Badge variant={processor?.color}>{processor?.name}</Badge>
    </div>
  )
}

export default function MerchantSettingsProcessor() {
  const { t } = useTranslation()
  const { merchantId } = useParams()
  const { setActivePage } = useOutletContext()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    meta: {},
    processors: []
  })

  useEffect(() => {
    setActivePage('processors')

    gatewayApi
      .get('/app/admin/processors', {
        params: {
          merchant_id: merchantId
        }
      })
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log({ error })
      })
  }, [merchantId, setActivePage])

  const columns = [
    {
      title: t('MerchantSettingsProcessorType'),
      render: ({ processor_type }) => <ProcessorType type={processor_type} />
    },
    {
      title: t('MerchantSettingsProcessorPrecedence'),
      render: (processor) => <ProcessorPrecedence processor={processor} />
    },
    {
      title: t('MerchantSettingsProcessor'),
      render: ({ id, alias }) => (
        <>
          <span className="mb-px block text-xs uppercase text-gray-600">{id}</span>
          <span>{alias}</span>
        </>
      )
    },
    {
      title: t('MerchantSettingsProcessorTerceiro'),
      render: ({ third_party_deposit_block }) => <YesNo test={third_party_deposit_block} />
    },
    {
      title: t('MerchantSettingsProcessorBureau'),
      render: ({ bureau_validation }) => <YesNo test={bureau_validation} />
    },
    {
      title: t('MerchantSettingsProcessorMode'),
      node: 'mode',
      render: ({ mode }) => (
        <Badge variant={mode == 'live' ? 'green' : 'pink'}>
          {mode == 'live' ? t('AdminMerchantModeLive') : t('AdminMerchantMode')}
        </Badge>
      )
    },
    {
      title: t('MerchantSettingsProcessorCurrency'),
      node: 'currency',
      render: ({ currency }) => <Badge>{currency}</Badge>
    },
    {
      title: t('MerchantSettingsProcessorEnabled'),
      render: ({ enabled }) => <YesNo test={enabled} />
    }
  ]

  return (
    <div>
      <Outlet />

      <PageHeader
        title={t('MerchantSettingsProcessors')}
        variant="simple"
        button={
          <Button as={<Link to={`/admin/merchant/${merchantId}/processors/new`} />} size="sm">
            <BuildingLibraryIcon className="mr-2 h-4" />
            {t('MerchantSettingsButtonNew')}
          </Button>
        }
      />

      <Table
        columns={columns}
        rows={data.processors}
        isLoading={isLoading}
        pagination={data.meta}
        emptyState={
          <EmptyState
            icon={<BuildingLibraryIcon />}
            title={t('MerchantSettingsProcessorTitle')}
            description={t('MerchantSettingsProcessorDescription')}
            action={<Button>{t('MerchantSettingsButtonNew')}</Button>}
          />
        }
      />
    </div>
  )
}
