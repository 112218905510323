import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../helpers/dom'
import { useAuth } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { PersonSquare } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

export default function SidebarMobile({
  navigation,
  secondaryNavigation,
  setSidebarOpen,
  sidebarOpen,
  leaveImpersonation,
  impersonated
}) {
  const isMenuActive = () => false
  const { t } = useTranslation()

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-1 flex-col bg-chill-800 pb-4 pt-5">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex flex-shrink-0 items-center px-4">
              <img className="h-10 w-auto" src="/paag-white.png" alt="Paag Logo - White version" />
            </div>
            <nav
              className="mt-5 flex h-full flex-shrink-0 flex-col divide-y divide-chill-900 divide-opacity-40 overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => {
                      setSidebarOpen(false)
                    }}
                    className={classNames(
                      isMenuActive(item.href)
                        ? 'bg-chill-900 text-white'
                        : 'text-chill-100 hover:bg-chill-900 hover:text-white',
                      'group flex items-center rounded-md px-2 py-2 text-base font-medium'
                    )}
                    aria-current={isMenuActive(item.href) ? 'page' : undefined}
                  >
                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-chill-200" aria-hidden="true" />
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="mt-6 pt-6">
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-chill-100 hover:bg-chill-900 hover:text-white"
                    >
                      <item.icon className="mr-4 h-6 w-6 text-chill-200" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              {impersonated && (
                <div className="p-4 text-white">
                  <a
                    onClick={leaveImpersonation}
                    className={classNames(
                      'text-gray-300 hover:bg-black hover:bg-opacity-20 hover:text-white',
                      'group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium leading-6'
                    )}
                  >
                    <PersonSquare className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
                    {t('AdminMerchantUsersSidebar')}
                  </a>
                </div>
              )}
            </nav>
          </div>
        </Transition.Child>
        <div className="w-14 flex-shrink-0" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  )
}
