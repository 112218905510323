import React from 'react'
import FieldSet from '../../components/Form/FieldSet'
import Form from '../../components/Form/Form'
import HiddenInput from '../../components/Form/Inputs/HiddenInput'
import SelectInput from '../../components/Form/Inputs/SelectInput'
import SwitchInput from '../../components/Form/SwitchInput'

import { useTranslation } from 'react-i18next'

export default function UserForm({ data, isLoading, submitRef, merchantId, onSubmit, onError }) {
  const handleSubmit = (data) => {
    !!onSubmit && onSubmit(data)
  }
  const { t } = useTranslation()

  const MERCHANT_ROLES = [
    { id: 'merchant_admin', value: t('UserRolesMerchantAdmin') },
    { id: 'merchant_finance', value: t('UserRolesMerchantFinance') },
    { id: 'merchant_employee', value: t('UserRolesMerchantOperator') }
  ]

  const handleError = (error) => {
    !!onError && onError(data)
  }

  const sanitizedUser = { ...data?.user, mobile_phone: data?.user?.mobile_phone || '' }

  return (
    <div>
      {/* Divider container */}
      <div className="">
        <Form
          defaultValues={sanitizedUser}
          onSubmit={handleSubmit}
          onError={handleError}
          isLoading={isLoading}
          submitRef={submitRef}
          className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
          noValidate
        >
          <FieldSet
            name="name"
            label={t('UsersSidePainelName')}
            rules={{
              required: t('UsersSidePainelErrorName')
            }}
          />
          <FieldSet
            name="email"
            type="email"
            label={t('UsersSidePainelEmail')}
            rules={{
              required: t('UsersSidePainelErrorEmail'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please input a valid email address.'
              }
            }}
          />

          <FieldSet name="mobile_phone" label={t('UsersSidePainelPhone')} mask="+99 (99) 9999-99999" />
          <FieldSet
            name="role"
            label={t('UsersSidePainelRole')}
            rules={{ required: t('UsersSidePainelErrorRole') }}
            input={<SelectInput options={MERCHANT_ROLES} />}
          />
          <SwitchInput name="active" label={t('UsersSidePainelUser')} />
          {!!merchantId ? <HiddenInput name="merchant_id" value={merchantId} /> : <></>}
        </Form>
      </div>
    </div>
  )
}
