import React, { useId } from 'react'
import InputSkeleton from '../Skeleton/InputSkeleton'
import TextInput from './Inputs/TextInput'
import { classNames } from '../../helpers/dom'

export default function FieldSet({
  register,
  name,
  label,
  hint,
  isLoading,
  input,
  onChange,
  showLabel,
  HeaderComponent,
  className,
  ...props
}) {
  const displayLabel = !(showLabel === false)
  const id = useId()

  return (
    <div>
      {HeaderComponent && (
        <div className="px-4 sm:px-6 sm:pt-4">
          <HeaderComponent />
        </div>
      )}
      <div
        className={classNames(
          displayLabel
            ? 'space-y-1 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-6'
            : 'space-y-1 py-5 sm:gap-4 sm:space-y-0 sm:py-6',
          className
        )}
      >
        {displayLabel && (
          <>
            <div className="flex items-center">
              <label htmlFor={id} className="block text-sm font-medium text-gray-900 sm:mt-px">
                <span className="block">{label}</span>
                <span className="block text-xs font-normal text-gray-500">{hint}</span>
              </label>
            </div>
          </>
        )}

        <div className={classNames(displayLabel ? 'sm:col-span-2' : 'px-0 sm:col-span-3')}>
          {input ? (
            React.createElement(input.type, {
              ...input.props,
              id,
              register: register,
              control: props.control,
              rules: props.rules,
              name: name,
              propagateChange: onChange
            })
          ) : (
            <>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <TextInput register={register} name={name} propagateChange={onChange} {...props} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
