import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button/Button'
import Main from '../../components/Main/Main'
import PageHeader from '../../components/Pages/PageHeader'
import PendingPayments from '../../components/Payments/PendingPayments'

const stats = [
  { name: 'Available Balance', stat: 'R$ 1.987.120,32' },
  { name: 'Pending Balance', stat: 'R$ 257.600,78' },
  { name: 'Under Approval', stat: 'R$ 57.660,15' }
]

export default function Payments() {
  const { t } = useTranslation()
  return (
    <>
      <PageHeader
        title={t('PaymentsTitle')}
        subtitle={t('PaymentsSubtitle')}
        button={<Button as={<Link to={'/merchant/payments/new'} />}>{t('PaymentsbuttonNewPayments')}</Button>}
      />

      <Main>
        {false && (
          <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              {stats.map((item) => (
                <div key={item.name} className="overflow-hidden rounded-lg border bg-white px-4 py-5 sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
                </div>
              ))}
            </dl>
          </div>
        )}
        <div className="space-x-4 space-y-0 py-6">
          <PendingPayments />
        </div>
      </Main>
      <Outlet />
    </>
  )
}
