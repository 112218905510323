import React from 'react'

export default function SidePanelFooter({ children }) {
  return (
    <footer>
      <div className="pointer-events-auto flex-shrink-0 border-t border-gray-200 px-4 py-4 sm:px-6 print:hidden">
        <div className="flex justify-end space-x-3">{children}</div>
      </div>
    </footer>
  )
}
