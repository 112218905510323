import React from 'react'

export default function EmptyState({ title, description, action, icon }) {
  return (
    <div className="flex flex-col items-center justify-center p-10">
      {React.createElement(icon.type, {
        className: 'h-10 w-10 text-gray-500 text-center'
      })}

      <h3 className="mt-2 text-xl font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-base text-gray-500">{description}</p>
      <div className="mt-6">{action}</div>
    </div>
  )
}
