import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

const registerInputs = (children, methods, formState, isLoading) => {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null
    }

    if (child.type === React.Fragment) {
      return React.createElement(
        React.Fragment,
        {},
        registerInputs(child.props.children, methods, formState, isLoading)
      )
    }

    if (child.props.name) {
      return React.createElement(child.type, {
        ...child.props,
        register: methods.register,
        control: methods.control,
        key: child.props.name,
        states: formState,
        isLoading: isLoading
      })
    } else {
      return child
    }
  })
}

const Form = React.forwardRef(
  ({ defaultValues, children, onSubmit, onError, submitRef, onChange, isLoading, schema, ...props }, ref) => {
    const methods = useForm({ defaultValues, resolver: schema ? zodResolver(schema) : undefined })
    const { formState } = methods
    const formElementRef = useRef(null)
    const handleSubmit = methods.handleSubmit(onSubmit, onError)
    const formValues = methods.watch()

    useEffect(() => {
      methods.reset(defaultValues)
    }, [defaultValues])

    useEffect(() => {
      if (onChange) {
        onChange(formValues)
      }
    }, [formValues])

    useImperativeHandle(ref, () => ({
      setValue(...args) {
        methods.setValue(...args)
      },
      getValues(...args) {
        return methods.getValues(...args)
      },
      reset(values) {
        methods.reset(values)
      },
      clearErrors(...args) {
        methods.clearErrors(...args)
      },
      submit() {
        handleSubmit()
      }
    }))

    return (
      <FormProvider {...methods}>
        <form ref={formElementRef} onSubmit={handleSubmit} {...props}>
          {registerInputs(children, methods, formState, isLoading)}
          {submitRef && <input type="submit" ref={submitRef} className="hidden" />}
        </form>
      </FormProvider>
    )
  }
)

export default Form
