import {
  BanknotesIcon,
  CreditCardIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  ServerStackIcon
} from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import Skeleton from '../../../components/Skeleton/Skeleton'
import StatsPill from '../../../components/StatsPill/StatsPill'
import kernApi from '../../../services/kernApi'
import { FormattedCurrency, FormattedNumber } from '../../../components/TransactionHelpers'
import { useTranslation } from 'react-i18next'
import { CustomReactTimeAgo } from '../../../components/DateTime/FormatDate'

export default function DashboardMain() {
  const { t } = useTranslation()

  const statsMapping = [
    {
      key: 'valor',
      title: t('DashboardMainProcessedAmount'),
      icon: <CreditCardIcon />,
      render: (value) => <FormattedCurrency amount={value} currency="BRL" />
    },
    {
      key: 'volume',
      title: t('DashboardMainProcessedTransactions'),
      icon: <ServerStackIcon />,
      render: (value) => <FormattedNumber amount={value || 0} />
    },
    {
      key: 'comissao',
      title: t('DashboardMainCommission'),
      icon: <FaceSmileIcon />,
      render: (value) => <FormattedCurrency amount={value} currency="BRL" />
    },
    {
      key: 'custo',
      title: t('DashboardMainCost'),
      icon: <FaceFrownIcon />,
      render: (value) => <FormattedCurrency amount={value} currency="BRL" />
    },
    {
      key: 'lucro',
      title: t('DashboardMainRevenue'),
      icon: <BanknotesIcon />,
      render: (value) => <FormattedCurrency amount={value} currency="BRL" />
    }
  ]
  const [data, setData] = useState({
    result: {},
    retrieved_at: ''
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    kernApi
      .get('/app/admin/stats/dashboard')
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch((e) => console.log('e', e))
  }, [])

  return (
    <div className="mb-10">
      <PageHeader
        title={t('DashboardMainMonthlyOverview')}
        subtitle={
          data.retrieved_at ? (
            <>
              {t('DashboardMainLastUpdated')} <CustomReactTimeAgo date={Date.parse(data.retrieved_at)} />
            </>
          ) : (
            t('loadingText')
          )
        }
      />
      <Main>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5">
          {statsMapping.map((stats) => (
            <StatsPill
              key={stats.key}
              title={stats.title}
              icon={stats.icon}
              value={isLoading ? <Skeleton className="mt-3" /> : stats.render(data.result?.rows?.[0]?.[stats.key])}
            />
          ))}
        </dl>
      </Main>
    </div>
  )
}
