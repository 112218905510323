import React, { useState } from 'react'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import Table from '../../components/Table/Table'
import { FormattedCurrency, FormattedDate } from '../../components/TransactionHelpers'
import { fetchMerchantBalanceDashboard, fetchPixData } from '../../services/gatewayApi'
import { useQuery } from '@tanstack/react-query'
import { ArrowClockwise } from 'react-bootstrap-icons'
import Datepicker from 'react-tailwindcss-datepicker'
import RecentActivityCards from './RecentActivityCards'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br' // Importação a língua desejada
import { useTranslation } from 'react-i18next'

export default function RecentActivity({ pixDataIsEnabled }) {
  const [period, setPeriod] = useState({
    startDate: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  })
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ['fetchMerchantBalanceDashboard', { period }],
    queryFn: fetchMerchantBalanceDashboard
  })
  const { data: pixData = {}, isLoading: pixDataIsLoading } = useQuery({
    queryKey: [
      'fetchPixData',
      {
        startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(period.endDate).format('YYYY-MM-DD')
      }
    ],
    queryFn: fetchPixData,
    enabled: pixDataIsEnabled
  })
  const { t, i18n } = useTranslation()
  const maxDate = dayjs().subtract(1, 'day').toDate()

  const handleValueChange = (value) => {
    setPeriod(value)
  }

  const columns = [
    {
      title: t('DashboardFormDay'),
      render: (balance) => (
        <div>
          <FormattedDate date={balance.date.split('T')[0]} />
        </div>
      )
    },
    {
      title: t('DashboardCashIn'),
      render: (balance) => (
        <div className="text-sm font-medium">
          <div className="text-sm">
            <FormattedCurrency currency="BRL" amount={balance.cash_in.total} />
          </div>
          <div className="text-xs text-gray-500">
            (<FormattedCurrency currency="BRL" amount={balance.cash_in.total_fee} />)
          </div>
        </div>
      )
    },
    {
      title: t('DashboardCashOut'),
      render: (balance) => (
        <div>
          <div className="text-sm font-medium">
            <FormattedCurrency currency="BRL" amount={balance.cash_out.total} />
          </div>
          <div className="text-xs text-gray-500">
            (<FormattedCurrency currency="BRL" amount={balance.cash_out.total_fee} />)
          </div>
        </div>
      )
    },
    {
      title: t('DashboardSettlement'),
      render: (balance) => (
        <div>
          <div className="text-sm font-medium">
            <FormattedCurrency currency="BRL" amount={balance.settlement.total} />
          </div>
          <div className="text-xs text-gray-500">
            (
            <FormattedCurrency currency="BRL" amount={balance.settlement.total_fee} />)
          </div>
        </div>
      )
    },
    {
      title: t('DashboardFees'),
      render: (balance) => <FormattedCurrency currency="BRL" amount={parseFloat(balance.total_fee)} />
    },
    {
      title: t('DashboardPeriodBalance'),
      render: (balance) => <FormattedCurrency currency="BRL" amount={balance.total} />
    }
  ]

  return (
    <div>
      <SectionHeader
        title={
          <>
            <div className="md:flex md:items-center">
              <div className="w- md:flex md:items-center">
                {t('DashboardActivityFrom')}&nbsp;
                <div className="ml-1">
                  <Datepicker
                    i18n={i18n.language}
                    value={period}
                    onChange={handleValueChange}
                    displayFormat={t('DatepickerDisplayFormat')}
                    separator={t('DatepickerSeparator')}
                    showShortcuts={true}
                    maxDate={maxDate}
                    configs={{
                      shortcuts: {
                        yesterday: t('DatepickerShortcutYesterday'),
                        currentMonth: t('DatepickerShortcutCurrentMonth'),
                        pastMonth: t('DatepickerShortcutPastMonth')
                      }
                    }}
                    inputClassName="cursor-pointer w-full pl-0 md:w-80 border-none font-bold text-gray-800 placeholder:text-gray-800 text-lg focus:ring-0"
                    toggleClassName="text-chill-800 right-0 h-full focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                    readOnly
                  />
                </div>
              </div>

              <div>{isRefetching && <ArrowClockwise className="ml-2 animate-spin text-chill-500" />}</div>
            </div>
          </>
        }
      />

      <RecentActivityCards
        data={data}
        pixDataIsEnabled={pixDataIsEnabled}
        pixData={pixData.data}
        isLoading={isLoading}
        pixDataIsLoading={pixDataIsEnabled ? pixDataIsLoading : false}
      />

      <Table columns={columns} rows={data || []} isLoading={isLoading} />
    </div>
  )
}
