import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import FormatCNPJ from '../../../components/Helpers/FormatCNPJ'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import Table from '../../../components/Table/Table'
import VendorStatus from '../../../components/Vendors/VendorStatus'
import ledgerApi from '../../../services/ledgerApi'
import EmptyState from '../../../components/EmptyState/EmptyState'
import { BuildingOffice2Icon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { CustomReactTimeAgo } from '../../../components/DateTime/FormatDate'

export default function ListVendors() {
  const { t } = useTranslation()
  const [vendors, setVendors] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [reload, setReload] = useState(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const searchReload = searchParams.get('r')

  useEffect(() => {
    if (searchReload !== 'true') return
    setReload(true)
    searchParams.set('r', 'true') // Atualize o parâmetro "r" para "true"
  }, [searchReload, searchParams])

  useEffect(() => {
    if (!reload) return

    ledgerApi.get('/v1/vendors', { params: { limit: 300 } }).then((response) => {
      setVendors(response.data)
      setIsLoading(false)
      setReload(false)
    })
  }, [reload])

  const columns = [
    {
      title: t('VendorsTableVendor'),
      render: (vendor) => (
        <div className="">
          <div className="">
            <div className="text-base font-medium text-secondary-900">{vendor.name}</div>
            <div className="text-xs text-gray-500 sm:text-sm">
              <FormatCNPJ cnpj={vendor.document} />
            </div>
            <div className="text-xs text-gray-500 sm:hidden">
              {vendor.created_at && <CustomReactTimeAgo date={Date.parse(vendor.created_at)} />}
            </div>
          </div>
        </div>
      )
    },
    {
      title: t('VendorsTableStatus'),
      render: (vendor) => <VendorStatus size="sm" vendorStatus={vendor.vendor_status} />
    },
    {
      title: '',
      render: ({ id }) => (
        <>
          <Button size="sm" variant="primary" as={<Link to={`/merchant/vendors/${id}`} />}>
            {t('VendorsButtonView')}
          </Button>
        </>
      )
    }
  ]

  return (
    <div>
      <PageHeader
        title={t('VendorsTitle')}
        subtitle={t('VendorsSubtitle')}
        button={
          <>
            <Button as={<Link />} to="/merchant/vendors/new">
              {t('VendorsButtonAddVendor')}
            </Button>
          </>
        }
      />

      <Main className="pt-6">
        <Table
          columns={columns}
          rows={vendors}
          isLoading={isLoading}
          emptyState={
            <EmptyState
              title={t('VendorsFirstVendor')}
              description={t('VendorsDontVendor')}
              icon={<BuildingOffice2Icon />}
              action={
                <Button variant="primary" as={<Link to="/merchant/vendors/new" />}>
                  <BuildingOffice2Icon className="mr-2 h-5" />
                  {t('VendorsSidePainelCreate')}
                </Button>
              }
            />
          }
        />
      </Main>

      <Outlet />
    </div>
  )
}
