import React from 'react'
import { Outlet } from 'react-router-dom'

export default function AuthLayout() {
  return (
    <>
      <div className="flex min-h-full flex-col justify-center bg-gradient-to-tl from-chill-600 to-chill-800 py-12 sm:px-6 lg:px-8 h-[100vh]">
        <header className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-20 w-auto" src="/paag-white.png" alt="Workflow" />
        </header>
        <main className="p-4">
          <Outlet />
        </main>
      </div>
    </>
  )
}
