import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'
import FieldSet from '../Form/FieldSet'
import Alert from '../Alert/Alert'
import clsx from 'clsx'
import { changeProcessor } from '../../services/gatewayApi'

export default function ProcessorModal({ title, subtitle, bodyText, processorType, confirmButton, open, onClose }) {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState('')
  const [showConfirmSubmit, setShowConfirmSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef()

  useEffect(() => {
    if (!open) {
      setSelectedOption('')
      setShowConfirmSubmit(false)
    }
  }, [open])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const merchantIds = []

    try {
      setIsLoading(true)
      await changeProcessor({ merchantIds, processorType: selectedOption })
      setShowConfirmSubmit(false)
      onClose()
    } catch (error) {
      console.error('Error submitting form:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => onClose && onClose()}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[480px] max-h-[80vh] flex flex-col transform overflow-hidden rounded-xl bg-white shadow-xl transition-all">
                {/* Header */}
                <div className="border-b p-6">
                  <div className="flex flex-col items-start">
                    <h3 className="text-lg font-semibold">{title}</h3>
                    {subtitle && <p className="mt-1 text-sm text-gray-600">{subtitle}</p>}
                  </div>
                </div>

                {/* Body */}
                <div className="flex-grow p-6 overflow-auto">
                  <div
                    className={clsx({
                      block: !showConfirmSubmit,
                      hidden: showConfirmSubmit
                    })}
                  >
                    <p className="text-left text-sm font-medium text-gray-700 mb-1.5">{t('Mudar para')}</p>

                    <form ref={formRef} onSubmit={handleSubmit}>
                      <FieldSet
                        name="provider"
                        afterChange={(value) => value}
                        showLabel={false}
                        className="!py-0"
                        input={
                          <select
                            title={t('Select a provider')}
                            value={selectedOption}
                            onChange={handleSelectChange}
                            className={clsx(
                              'block w-full px-2.5 py-2.5 border rounded-md',
                              selectedOption === '' && 'text-gray-400 bg-gray-100',
                              selectedOption !== '' && 'text-black bg-white gap-x-5'
                            )}
                            name="provider"
                          >
                            <option value="" disabled hidden className="text-gray-400">
                              {t('SelectOption')}
                            </option>
                            <option value="stark" className="text-black">
                              STARK
                            </option>
                            <option value="aarin" className="text-black">
                              AARIN
                            </option>
                          </select>
                        }
                      />
                    </form>
                  </div>

                  {showConfirmSubmit && <Alert title={t('ErrorTitle')} subtitle={t('ErrorSubtitle')} />}
                </div>

                {/* Footer */}
                <div className="border-t p-6 flex flex-row">
                  {confirmButton}
                  <Button variant="white" className="mr-auto w-52 h-11 py-2.5 px-5" onClick={onClose}>
                    {t('MerchantSettingsAccountButtonCancel')}
                  </Button>
                  {!showConfirmSubmit && (
                    <Button
                      variant="fail"
                      className="text w-52 h-11 py-2.5 px-5"
                      disabled={selectedOption === ''}
                      onClick={() => setShowConfirmSubmit(true)}
                    >
                      {t('Continuar')}
                    </Button>
                  )}
                  {showConfirmSubmit && (
                    <Button
                      variant="fail"
                      className="text w-52 h-11 py-2.5 px-5"
                      disabled={selectedOption === ''}
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.requestSubmit()
                        }
                      }}
                    >
                      {t('Confirmar operação')}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
