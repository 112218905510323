import React, { useEffect, useState } from 'react'
import { classNames } from '../../helpers/dom'
import Main from '../Main/Main'

export default function Tabs({ title, subtitle, tabs }) {
  const [currentTab, setCurrentTab] = useState({})

  const selectTab = (tab) => {
    setCurrentTab(tab)
  }

  useEffect(() => {
    setCurrentTab(tabs.find((tab) => tab.current))
  }, [tabs])

  return (
    <div>
      <div className="mx-auto border-b border-gray-100 bg-gray-50 px-4 py-6 sm:px-6 sm:pb-0 md:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            {/* Profile */}
            <div className="flex items-center">
              <div>
                <div className="">
                  <h1 className="ml-0 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">{title}</h1>
                  <h2 className="mt-1 text-sm text-gray-400">{subtitle}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 sm:mt-5">
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-chill-500 focus:outline-none focus:ring-chill-500 sm:text-sm"
              defaultValue={currentTab.name}
              onChange={(e) => {
                selectTab(tabs.find((t) => t.name == e.currentTarget.value))
              }}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab == currentTab
                      ? 'border-chill-500 text-chill-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'cursor-default whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => selectTab(tab)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <Main>
        <div className="mt-5">
          {tabs.map((tab) => (
            <div key={tab.name} className={classNames(tab == currentTab ? '' : 'hidden')}>
              {tab.element}
            </div>
          ))}
        </div>
      </Main>
    </div>
  )
}
