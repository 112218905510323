import React from 'react'
import ResetPasswordEmailForm from '../../components/Auth/ResetPassword/ResetPasswordEmailForm'
import { useSearchParams } from 'react-router-dom'
import ResetPasswordForm from '../../components/Auth/ResetPassword/ResetPasswordForm'

export default function ResetPassword() {
  const [params, _setParams] = useSearchParams()

  const token = params.get('token')
  const email = params.get('email')

  return (
    <div>
      <div className="mt-8 shadow-md sm:mx-auto sm:w-full sm:max-w-md">
        <div className="rounded-md bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
          {email && token ? <ResetPasswordForm token={token} email={email} /> : <ResetPasswordEmailForm />}
        </div>
      </div>
    </div>
  )
}
