import React from 'react'
import Main from '../../components/Main/Main'
import RecentActivity from './RecentActivity'
import Overview from './Overview'
import PageHeader from '../../components/Pages/PageHeader'
import UserAvatar from '../../components/Auth/UserAvatar'
import UserMerchant from '../../components/User/UserMerchant'
import { useAuth } from '../../contexts/AuthContext'
import { useQuery } from '@tanstack/react-query'
import { fetchUserMerchant } from '../../services/ledgerApi'

const merchantsAllowedQrCode = (process.env.REACT_APP_ALLOWED_QRCODE_RATE_MERCHANT_IDS || '').split(',')

export default function Home() {
  const auth = useAuth()
  const { data, error } = useQuery({
    queryFn: fetchUserMerchant,
    queryKey: ['fetchUserMerchant', { merchantId: auth.user.merchant_id }],
    staleTime: 50 * 60 * 1000,
    cacheTime: 50 * 60 * 1000
  })

  const pixDataIsEnabled = merchantsAllowedQrCode.includes(data?.merchant.id)

  return (
    <>
      <header>
        <PageHeader
          title={auth.user?.name}
          avatar={<UserAvatar user={auth.user} size="lg" className="hidden sm:flex" />}
          subtitle={<UserMerchant data={data} error={error} />}
          auth={auth}
        />
      </header>

      <Overview pixDataIsEnabled={pixDataIsEnabled} />

      <Main>
        <div className="mt-8">
          <RecentActivity pixDataIsEnabled={pixDataIsEnabled} />
        </div>
      </Main>
    </>
  )
}
