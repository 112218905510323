import React from 'react'
import FormatCNPJ from '../../Helpers/FormatCNPJ'
import Skeleton from '../../Skeleton/Skeleton'
import { FormattedCurrency } from '../../TransactionHelpers'
import VendorStatus from '../VendorStatus'
import { useTranslation } from 'react-i18next'

export default function VendorOverview({ vendor, isLoading }) {
  const { t } = useTranslation()
  return (
    <div className="mb-5">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewName')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{isLoading ? <Skeleton /> : vendor.name}</dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewCNPJ')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : <FormatCNPJ cnpj={vendor.document} />}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewPhoneNumber')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : vendor.phone}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewStatus')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : <VendorStatus vendorStatus={vendor.vendor_status} />}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewMonthlyRevenue')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : <FormattedCurrency amount={vendor.monthy_revenue} currency="BRL" />}
          </dd>
        </div>
        <h2 className="pb-2 pt-6 text-lg font-bold text-gray-700">{t('VendorOverviewBankAccount')}</h2>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewBank')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : vendor.bank_number}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewBankAgency')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : vendor.bank_agency}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewBankAccount')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : vendor.bank_account}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorOverviewPixKey')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? <Skeleton /> : vendor.pix_key || 'N/A'}
          </dd>
        </div>
      </dl>
    </div>
  )
}
