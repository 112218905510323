import React, { useContext, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'

export default function SignOut() {
  const auth = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    auth.Logout()
  }, [])

  return <div>{t('SignOutMessage')} :)</div>
}
