import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { classNames } from '../../helpers/dom'
import UserAvatar from '../Auth/UserAvatar'
import { useTranslation } from 'react-i18next'

const stylesForRole = {
  merchant: {
    background: 'bg-white'
  },
  admin: {
    background: 'bg-white'
  }
}

export default function Topbar({ setSidebarOpen, userNavigation, isAdmin }) {
  const auth = useAuth()
  const { t } = useTranslation()
  const role = isAdmin ? 'admin' : 'merchant'
  const styles = stylesForRole[role]

  return (
    <>
      <div
        className={classNames(
          'relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 lg:hidden lg:border-none',
          styles.background
        )}
      >
        <button
          type="button"
          className="border-none border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-chill-500 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">{t('TopbarOpen')}</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        {/* Search bar */}
        <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:px-8">
          <div className="flex flex-1 items-center justify-center">&nbsp;</div>
          <div className="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
            >
              <span className="sr-only">{t('TopbarViewNotification')}</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                  <UserAvatar user={auth.user} size="xs" />
                  <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                    <span className="sr-only">{t('TopbarUserMenu')} </span>
                    {auth.user.name}
                  </span>
                  <ChevronDownIcon
                    className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          key={item.name}
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  )
}
