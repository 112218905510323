import React, { Fragment, useEffect, useState } from 'react'
import { fetchBalanceDashboard, fetchPixData } from '../../services/gatewayApi'
import { CurrencyDollar } from 'react-bootstrap-icons'
import { FormattedNumber } from '../../components/TransactionHelpers'
import { useQuery } from '@tanstack/react-query'
import {
  ArrowDownRightIcon,
  ArrowDownTrayIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ArrowUpRightIcon,
  ArrowUpTrayIcon,
  GlobeEuropeAfricaIcon,
  InboxArrowDownIcon,
  ScaleIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import formatNumber from '../../utils/formatNumber'

const getToday = () => {
  const date = new Date(new Date().getTime() - 60 * 60 * 1000 * 3)
  return date.toISOString().slice(0, 10)
}

const staleTime = 2 * 60 * 1000
const cacheTime = 2 * 60 * 1000

function OverviewCards({ generalCards, cashInCards, cashOutCards, isLoading }) {
  const { t } = useTranslation()

  return (
    <>
      <dl className="mx-auto grid grid-cols-1 gap-y-4 sm:grid-cols-2 lg:grid-cols-4 py-10">
        {generalCards.map((card) => (
          <div
            key={card.name}
            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 sm:px-6 xl:px-8"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">{t(card.i18nKey)}</dt>
            <dd className="w-full flex-none text-2xl font-bold leading-10 tracking-tight text-gray-900">
              {card.hasOwnProperty('amount') && <FormattedNumber amount={card.amount} isLoading={isLoading} />}
              {card.hasOwnProperty('value') && (
                <span className={clsx({ 'animate-pulse blur-sm': isLoading })}>{card.value || 0}</span>
              )}
            </dd>
          </div>
        ))}
      </dl>
      <div className="grid grid-cols-2">
        <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{t('DashboardCashIn')}</h3>
          <dl className="mt-2 grid grid-cols-1 gap-1">
            {cashInCards.map((card) => (
              <div
                key={card.name}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow hover:shadow-md sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">{t(card.i18nKey)}</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-green-400">
                  <FormattedNumber amount={card.amount} isLoading={isLoading} currency={card.currency} />
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
          <div className="grid grid-cols-1 gap-1">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{t('DashboardCashOut')}</h3>
            {/* <h3 className="text-base font-semibold leading-6 text-gray-900">{t('DashboardCashOutVendors')}</h3> */}
            {/* <h3 className="text-base font-semibold leading-6 text-gray-900">{t('DashboardCashOutSystem')}</h3>
            <h3 className="text-base font-semibold leading-6 text-gray-900">{t('DashboardCashOutTotal')}</h3> */}
          </div>
          <dl className="grid grid-cols-1 gap-1">
            <div className="mt-2 flex flex-col gap-1">
              {cashOutCards.map((card) => (
                <div
                  key={card.name}
                  className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow hover:shadow-md sm:p-6"
                >
                  <dt className="truncate text-sm font-medium text-gray-500">{t(card.i18nKey)}</dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-red-400">
                    <FormattedNumber amount={card.amount} isLoading={isLoading} currency={card.currency} />
                  </dd>
                </div>
              ))}
            </div>
          </dl>
        </div>
      </div>
    </>
  )
}

export default function Overview({ pixDataIsEnabled }) {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const todayDate = getToday()
  const { data = {}, isLoading } = useQuery({
    queryKey: ['fetchBalanceData', { todayDate }],
    queryFn: fetchBalanceDashboard,
    staleTime,
    cacheTime
  })
  const { data: pixData = {}, isLoading: pixDataIsLoading } = useQuery({
    queryKey: [
      'fetchPixData',
      {
        startDate: dayjs(todayDate).format('YYYY-MM-DD'),
        endDate: dayjs(todayDate).format('YYYY-MM-DD')
      }
    ],
    queryFn: fetchPixData,
    staleTime,
    cacheTime,
    enabled: pixDataIsEnabled
  })

  const general = [
    {
      name: 'accountBalance',
      i18nKey: 'DashboardAccountBalance',
      href: '#',
      icon: ScaleIcon,
      amount: data?.current_amount
    },
    {
      name: 'dailyBalance',
      i18nKey: 'DashboardDailyBalance',
      href: '#',
      icon: InboxArrowDownIcon,
      amount: data.total,
      cta: 'Request Withdraw'
    },
    {
      name: 'fees',
      i18nKey: 'DashboardFees',
      href: '#',
      icon: CurrencyDollar,
      amount: data?.total_fee
    },
    {
      name: 'settlement',
      i18nKey: 'DashboardSettlement',
      href: '#',
      icon: GlobeEuropeAfricaIcon,
      amount: data.settlement?.total
    },
    ...(pixDataIsEnabled
      ? [
          {
            name: 'dashboardGeneratedQrCodes',
            i18nKey: 'DashboardGeneratedQrCodes',
            href: '#',
            value: formatNumber(pixData.data?.dashboard?.pix.started)
          },
          {
            name: 'dashboardPaidQrCodes',
            i18nKey: 'DashboardPaidQrCodes',
            href: '#',
            value: formatNumber(pixData.data?.dashboard?.pix.paid)
          },
          {
            name: 'dashboardConversionRate',
            i18nKey: 'DashboardConversionRate',
            href: '#',
            value: `${Number(pixData.data?.dashboard?.pix.rate || 0).toFixed(2)}%`
          }
        ]
      : [])
  ]
  const cashIn = [
    {
      name: 'cashIn',
      i18nKey: 'DashboardCashIn',
      href: '#',
      icon: ArrowDownTrayIcon,
      amount: data.cash_in?.total,
      currency: 'BRL'
    },
    {
      name: 'ticketIn',
      i18nKey: 'DashboardAvgTicketIn',
      href: '#',
      icon: ArrowTrendingUpIcon,
      amount: data.cash_in?.amount_average,
      currency: 'BRL'
    },
    {
      name: 'cashInCount',
      i18nKey: 'DashboardCashInDay',
      href: '#',
      icon: ArrowUpRightIcon,
      amount: formatNumber(data.cash_in?.counter),
      currency: null // FormattedNumber will show integer
    }
  ]

  const cashOutCards = [
    {
      name: 'cashOut',
      i18nKey: 'DashboardCashOut',
      href: '#',
      icon: ArrowUpTrayIcon,
      amount: data.cash_out?.total,
      currency: 'BRL'
    },
    {
      name: 'ticketOut',
      i18nKey: 'DashboardAvgTicketOut',
      href: '#',
      icon: ArrowTrendingDownIcon,
      amount: data.cash_out?.amount_average,
      currency: 'BRL'
    },
    {
      name: 'cashOutCount',
      i18nKey: 'DashboardCashOutDay',
      href: '#',
      icon: ArrowDownRightIcon,
      amount: formatNumber(data.cash_out?.counter),
      currency: null // FormattedNumber will show integer
    }
  ]

  // const cashOutSystem = [
  //   {
  //     name: 'cashOutSystem',
  //     i18nKey: 'DashboardCashOut',
  //     href: '#',
  //     icon: ArrowUpTrayIcon,
  //     amount: data.cash_out?.total,
  //     currency: 'BRL'
  //   },
  //   {
  //     name: 'ticketOutSystem',
  //     i18nKey: 'DashboardAvgTicketOut',
  //     href: '#',
  //     icon: ArrowTrendingDownIcon,
  //     amount: data.cash_out?.amount_average,
  //     currency: 'BRL'
  //   },
  //   {
  //     name: 'cashOutCountSystem',
  //     i18nKey: 'DashboardCashOutDay',
  //     href: '#',
  //     icon: ArrowDownRightIcon,
  //     amount: data.cash_out?.counter,
  //     currency: null // FormattedNumber will show integer
  //   }
  // ]

  // const cashOutTotal = [
  //   {
  //     name: 'cashOutSystem',
  //     i18nKey: 'DashboardCashOut',
  //     href: '#',
  //     icon: ArrowUpTrayIcon,
  //     amount: data.cash_out?.total,
  //     currency: 'BRL'
  //   },
  //   {
  //     name: 'ticketOutSystem',
  //     i18nKey: 'DashboardAvgTicketOut',
  //     href: '#',
  //     icon: ArrowTrendingDownIcon,
  //     amount: data.cash_out?.amount_average,
  //     currency: 'BRL'
  //   },
  //   {
  //     name: 'cashOutCountSystem',
  //     i18nKey: 'DashboardCashOutDay',
  //     href: '#',
  //     icon: ArrowDownRightIcon,
  //     amount: data.cash_out?.counter,
  //     currency: null // FormattedNumber will show integer
  //   }
  // ]

  let [tabs] = useState([{ name: "Today's Overview", i18nKey: 'DashboardTodayOverview' }])

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <div className="relative isolate overflow-hidden border-b border-gray-200">
        <div className="flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 md:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">{t('DashboardCashFlow')}</h1>
          <Tab.List className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            {tabs.map((tab) => (
              <Tab
                key={`tab-${tab.name}`}
                className={({ selected }) =>
                  clsx(
                    'w-full py-2.5 text-sm leading-5',
                    'focus:outline-none',
                    selected ? 'font-bold text-chill-800' : 'text-gray-400 hover:bg-white/[0.12] hover:text-chill-800'
                  )
                }
              >
                {t(tab.i18nKey)}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </div>
      <div className="relative isolate overflow-hidden border-b border-gray-200">
        <Tab.Panels>
          <Tab.Panel className="ring-offset-none focus:outline-none">
            <OverviewCards
              generalCards={general}
              cashInCards={cashIn}
              // cashOutSystemCards={cashOutSystem}
              cashOutCards={cashOutCards}
              // cashOutTotal={cashOutTotal}
              isLoading={isLoading || pixDataIsEnabled ? pixDataIsLoading : false}
            />
          </Tab.Panel>
        </Tab.Panels>
      </div>
    </Tab.Group>
  )
}
