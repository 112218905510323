import React, { useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useMutation } from '@tanstack/react-query'
import { identifyZendesk } from '../../services/helpdeskApi'
import { useFlags } from 'flagsmith/react'

const setupScript = (auth, jwt) => {
  const script = document.createElement('script')

  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9d4cb66c-30d3-4cd2-aea1-6c8adf4fe592'
  script.async = true
  script.id = 'ze-snippet'
  script.onload = () => {
    try {
      zE('messenger', 'loginUser', function (callback) {
        callback(jwt)
        zE('messenger', 'show')
      })

      if (auth.isImpersonated()) {
        zE('messenger', 'hide')
      }
    } catch (error) {}
  }

  document.body.appendChild(script)

  return script
}

export default function ZendeskSnippet() {
  const flags = useFlags(['zendesk_chat'])

  const auth = useAuth()
  const identifyUser = useMutation({
    mutationFn: identifyZendesk,
    onSuccess: (data) => {
      window.zendesk_script = setupScript(auth, data.jwt)
    }
  })

  useEffect(() => {
    if (flags.zendesk_chat?.enabled) {
      identifyUser.mutate()
      return () => {
        try {
          document.body.removeChild(window.zendesk_script)
        } catch (error) {}
      }
    }
  }, [flags])

  return <></>
}
