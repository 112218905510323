import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import Badge from '../../../components/Badge/Badge'
import FieldSet from '../../../components/Form/FieldSet'
import Form from '../../../components/Form/Form'
import FormSection from '../../../components/Form/FormSection'
import CurrencyInput from '../../../components/Form/Inputs/CurrencyInput'
import DateInput from '../../../components/Form/Inputs/DateInput'
import FileInput from '../../../components/Form/Inputs/FileInput'
import SelectCardInput from '../../../components/Form/Inputs/SelectCardInput'
import SelectInput from '../../../components/Form/Inputs/SelectInput'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'

import ledgerApi from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import { toast } from 'react-hot-toast'
import dayjs from 'dayjs'

const yesterday = () => {
  var date = new Date()
  return date.setDate(date.getDate() - 1)
}

export default function NewPayment() {
  const [open, setOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentType, setPaymentType] = useState('')
  const submitRef = useRef()
  const formRef = useRef()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const PAYMENT_TYPES = [
    {
      id: 'VENDOR',
      value: 'Vendor',
      title: t('PaymentsSidePanelButtonPay'),
      hint: t('PaymentsSidePanelButtonPayBrazil')
    },
    {
      id: 'SETTLEMENT',
      value: 'Settlement',
      title: t('PaymentsSidePanelButtonSettlement'),
      hint: t('PaymentsSidePanelButtonSettlethePayment')
    }
  ]

  const handleError = (e) => {}

  const handleSubmit = (data) => {
    setIsLoading(true)
    ledgerApi
      .post('/v1/payments', data)
      .then((response) => {
        setIsLoading(false)
        setOpen(false)
        toast.success('Pedido de pagamento criado com sucesso. Em até um dia útil a análise será definida.', {
          position: 'bottom-center',
          duration: 6000
        })
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const schema = useMemo(() => {
    const validateDate = (data) => {
      const today = dayjs()
      const date = dayjs(data)
      return date.diff(today, 'day') >= 0
    }

    if (paymentType === 'VENDOR') {
      return z.object({
        payment_type: z.string(),
        amount: z
          .number({
            invalid_type_error: t('PaymentsSidePanelAmountError'),
            required_error: t('PaymentsSidePanelAmountError')
          })
          .positive(),
        due_at: z
          .string({ required_error: t('PaymentsSidePanelDueAtRequiredError') })
          .refine(validateDate, t('PaymentsSidePanelDueAtError')),
        vendor_id: z.string({ required_error: t('PaymentsSidePanelVendorRequiredError') }),
        invoice_url: z.string({ required_error: t('PaymentsSidePanelInvoiceRequiredError') })
      })
    }

    if (paymentType === 'SETTLEMENT') {
      return z.object({
        payment_type: z.string(),
        amount: z
          .number({
            invalid_type_error: t('PaymentsSidePanelAmountError'),
            required_error: t('PaymentsSidePanelAmountError')
          })
          .positive(),
        due_at: z
          .string({ required_error: t('PaymentsSidePanelDueAtRequiredError') })
          .refine(validateDate, t('PaymentsSidePanelDueAtError'))
      })
    }
  }, [paymentType])

  return (
    <>
      <SidePanel open={open} setOpen={setOpen} onClose={() => navigate('/merchant/payments')}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title={t('PaymentsSidePanelTitle')} />
          <SidePanelBody>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              onError={handleError}
              isLoading={isLoading}
              submitRef={submitRef}
              schema={schema}
              className="divide-y divide-gray-100 py-0 sm:space-y-0
              sm:divide-gray-200 sm:py-0"
            >
              <FormSection name={t('PaymentsSidePanelBasic')} description={t('PaymentsSidePanelDetailsPayment')} />
              <FieldSet
                name="payment_type"
                label={t('PaymentsSidePanelPaymentType')}
                rules={{ required: t('PaymentsSidePanelErrorPaymentType') }}
                input={<SelectCardInput options={PAYMENT_TYPES} />}
                onChange={(value) => {
                  formRef.current.reset({
                    payment_type: value
                  })
                  setPaymentType(value)
                }}
              />
              <NestedVendor paymentType={paymentType} />
            </Form>
          </SidePanelBody>
          {paymentType && (
            <SidePanelFooter>
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
                onClick={() => setOpen(false)}
              >
                {t('PaymentsSidePanelButtonCancel')}
              </button>
              <Button
                type="submit"
                onClick={() => {
                  submitRef.current.click()
                }}
                isLoading={isLoading}
              >
                {t('PaymentsSidePanelButtonSave')}
              </Button>
            </SidePanelFooter>
          )}
        </div>
      </SidePanel>
    </>
  )
}

function NestedVendor({ paymentType }) {
  const register = useFormContext()
  const [vendors, setVendors] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    ledgerApi.get('/v1/vendors', { params: { limit: 300 } }).then((r) => {
      setVendors(
        r.data.map((x) => {
          return {
            id: x.id,
            value: (
              <div className="inline-block w-full">
                <div className="flex items-center">
                  <div className="flex-grow">
                    <span className="font-medium">{x.name}</span> <br />
                    <span className="text-xs text-gray-600 group-hover:text-gray-400">{x.document}</span>
                  </div>
                  <div>
                    <Badge>{x.vendor_status}</Badge>
                  </div>
                </div>
              </div>
            )
          }
        })
      )
    })
  }, [])

  return (
    <div>
      {paymentType === 'VENDOR' && (
        <div className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0">
          <FieldSet
            name="amount"
            label={t('PaymentsSidePanelButtonPayAmount')}
            input={<CurrencyInput currency="BRL" />}
          />
          <FieldSet
            name="due_at"
            label={t('PaymentsSidePanelButtonPayDueDate')}
            hint={t('PaymentsSidePanelButtonPayDueInformation')}
            input={<DateInput minDate={yesterday()} useRange={false} asSingle={true} />}
          />
          <FormSection
            name={t('PaymentsSidePanelButtonPayVendor')}
            description={t('PaymentsSidePanelButtonPayVendorSubtitle')}
          />
          <FieldSet
            name="vendor_id"
            label={t('PaymentsSidePanelButtonPaySelect')}
            rules={{ required: t('PaymentsSidePanelErrorVendor') }}
            input={<SelectInput options={vendors} />}
            register={register}
          />
          <FieldSet
            name="invoice_url"
            label={t('PaymentsSidePanelButtonPayInvoice')}
            hint={t('PaymentsSidePanelButtonPayInvoiceSub')}
            input={<FileInput />}
          />
        </div>
      )}
      {paymentType === 'SETTLEMENT' && (
        <div className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0">
          <FieldSet
            name="amount"
            label={t('PaymentsSidePanelButtonPayAmount')}
            input={<CurrencyInput currency="USD" />}
          />
          <FieldSet
            name="due_at"
            label={t('PaymentsSidePanelButtonPayDueDate')}
            hint={t('PaymentsSidePanelButtonPayDueInformation')}
            input={<DateInput minDate={yesterday()} useRange={false} asSingle={true} />}
          />
        </div>
      )}
    </div>
  )
}
