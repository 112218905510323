import React, { useState } from 'react'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import { useQuery } from '@tanstack/react-query'
import { fetchAdminMerchantVendors } from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'
import { Badge } from '@radix-ui/themes'

export default function CustomListVendors({ merchantId }) {
  const { t } = useTranslation()
  const { data, isLoading, isRefetching } = useQuery({
    queryFn: fetchAdminMerchantVendors,
    queryKey: ['listAdminMerchantVendors', { merchantId }]
  })

  const columns = [
    {
      title: 'Fornecedor',
      render: (vendor) => (
        <div>
          <h1 className="font-semibold">{vendor.name}</h1>
          <h2 className="text-xs">{vendor.document}</h2>
        </div>
      )
    },
    {
      title: 'MODE',
      render: () => (
        <Badge color="green" variant="solid">
          Live
        </Badge>
      )
    },
    {
      title: 'Câmbio',
      render: () => <Badge color="gray">BRL</Badge>
    },
    {
      title: 'ATIVO',
      render: (vendor) => (
        <Badge color={vendor.vendor_status === 'APPROVED' ? 'green' : 'red'} variant="solid">
          {vendor.vendor_status === 'APPROVED' ? 'Yes' : 'No'}
        </Badge>
      )
    }
  ]

  return (
    <div>
      <PageHeader title="Fornecedores" subtitle="Lista de fornecedores" />

      <Main className="pt-4">
        <Table isRefetching={isRefetching} isLoading={isLoading} columns={columns} rows={data || []} />
      </Main>
    </div>
  )
}
