import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Flex } from '@radix-ui/themes'
import { FormattedCurrency } from '../TransactionHelpers'

function BoletoDetailsPayment({ showBank, boleto }) {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={showBank ? '8' : '4'} width="100%">
      {showBank && (
        <Text size="4" weight="bold" as="h2">
          {t('BoletoPaymentSectionAboutTransaction')}
        </Text>
      )}
      <Flex gap="6" direction="column" align="center">
        <Flex gap="1" direction="column">
          <Text size="2" color="gray" align="center">
            {t('BoletoPaymentLabelValue')}
          </Text>
          <FormattedCurrency amount={boleto.value} currency="BRL" className="font-semibold text-4xl" />
        </Flex>
        {showBank && (
          <Flex gap="1" direction="column">
            <Text align="center" weight="medium">
              {boleto.bank_name}
            </Text>
            <Text size="2" color="gray" align="center">
              {boleto.cnpj}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex direction="column" gap="3">
        <Text size="4" weight="bold" as="h2">
          {t('BoletoPaymentSectionDetails')}
        </Text>
        <Flex direction="column" gap="2">
          <Flex justify="between">
            <Text color="gray">{t('BoletoPaymentLabelBankIssuer')}</Text>
            <Text>{boleto.emissor}</Text>
          </Flex>
          <Flex justify="between">
            <Text color="gray">{t('BoletoPaymentLabelInterest')}</Text>
            <FormattedCurrency amount={boleto.interest} currency="BRL" />
          </Flex>
          <Flex justify="between">
            <Text color="gray">{t('BoletoPaymentLabelFine')}</Text>
            <FormattedCurrency amount={boleto.penalty} currency="BRL" />
          </Flex>
          <Flex justify="between">
            <Text color="gray">{t('BoletoPaymentLabelDiscounts')}</Text>
            <FormattedCurrency amount={boleto.discount} currency="BRL" />
          </Flex>
          <Flex justify="between">
            <Text color="gray">{t('BoletoPaymentLabelTotalValue')}</Text>
            <FormattedCurrency amount={boleto.total_value} currency="BRL" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BoletoDetailsPayment
