import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button/Button'
import {
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function SimplePagination({ isLoading, pagination }) {
  const { t } = useTranslation()
  if (!pagination) {
    return
  }

  return (
    <nav className="flex items-center border-t border-gray-200 bg-white px-4 py-3 sm:px-4" aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-xs text-gray-500">
          {isLoading ? (
            t('loadingText')
          ) : (
            <>
              {t('SimplePaginationShowing')}&nbsp;
              <span className="font-medium">{pagination?.current_page}</span>
              &nbsp;{t('SimplePaginationOf')}&nbsp;
              <span className="font-medium">{pagination?.last_page}</span>
              &nbsp;{t('SimplePagination')}&nbsp;
            </>
          )}
        </p>
      </div>
      <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
        <Button
          variant="white"
          size="xs"
          as={<Link />}
          to={`/merchant/transactions?page=${1}`}
          disabled={isLoading || pagination?.current_page == 1}
        >
          <ChevronDoubleLeftIcon className="mr-2 h-3" />
          {t('TransactionsButtonFirstPage')}
        </Button>
        <Button
          size="xs"
          variant="white"
          as={<Link />}
          to={`/merchant/transactions?page=${pagination?.current_page - 1}`}
          disabled={isLoading || pagination?.current_page == 1}
        >
          <ChevronLeftIcon className="mr-2 h-3" /> {t('TransactionsButtonPrevious')}
        </Button>
        <Button
          size="xs"
          className="ml-3"
          variant="white"
          as={<Link />}
          to={`/merchant/transactions?page=${pagination?.current_page + 1}`}
          disabled={isLoading || pagination?.current_page >= pagination.last_page}
        >
          {t('TransactionsButtonNext')} <ChevronRightIcon className="ml-2 h-3" />
        </Button>
        <Button
          size="xs"
          variant="white"
          as={<Link />}
          to={`/merchant/transactions?page=${pagination?.last_page}`}
          disabled={pagination?.current_page == pagination?.last_page}
        >
          {t('TransactionsButtonLastPage')} <ChevronDoubleRightIcon className="ml-2 h-3" />
        </Button>
      </div>
    </nav>
  )
}
