import React from 'react'
import { CreditCardIcon, NewspaperIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline'
import PixIcon from '../components/Icons/PixIcon'

export const transactionTypesMap = {
  external: {
    icon: ArrowUpRightIcon,
    label: 'transactionTypes.external'
  },
  card: {
    icon: CreditCardIcon,
    label: 'transactionTypes.card'
  },
  boleto: {
    icon: NewspaperIcon,
    label: 'transactionTypes.boleto'
  },
  pix: {
    icon: PixIcon,
    label: 'transactionTypes.pix'
  }
}
