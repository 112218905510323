import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { classNames } from '../../helpers/dom'
import Skeleton from '../Skeleton/Skeleton'

export default function StatsCard(item) {
  const { isLoading } = item

  return (
    <div
      className={classNames(
        item.action ? 'pb-12' : '',
        'relative overflow-hidden rounded-lg border bg-white px-4 pt-5 sm:px-6 sm:pt-6'
      )}
    >
      <dt>
        <div className="absolute rounded-md bg-chill-700 p-3">
          <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        <p className="flex-1 text-2xl font-semibold text-gray-900">
          {isLoading ? <Skeleton className="mt-3 h-5" /> : item.stat}
        </p>
        <p
          className={classNames(
            item.changeType === 'increase' ? 'bg-green-50 text-green-600' : 'bg-red-50 text-red-600',
            'ml-2 flex items-baseline rounded-lg p-2 text-sm font-semibold',
            item.changeType && item.change ? '' : 'hidden'
          )}
        >
          {item.changeType === 'increase' ? (
            <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-600" aria-hidden="true" />
          ) : (
            <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
          )}

          <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
          {item.change}
        </p>
        {item.action ? (
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <a href="#" className="font-medium text-chill-600 hover:text-chill-500">
                {' '}
                View all<span className="sr-only"> {item.name} stats</span>
              </a>
            </div>
          </div>
        ) : (
          ''
        )}
      </dd>
    </div>
  )
}
