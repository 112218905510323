import React from 'react'
import FormatCPF from '../Helpers/FormatCPF'

export default function CustomerName({ customer }) {
  return (
    <div>
      <h3 className="font-semibold">{customer.name || 'Processing...'}</h3>
      <h4 className="text-sm text-gray-500">
        <FormatCPF cpf={customer.document} />
      </h4>
    </div>
  )
}
