import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import Datepicker from 'react-tailwindcss-datepicker'
import { useTranslation } from 'react-i18next'

const DateElement = React.forwardRef(({ name, states, onChange, ...props }, ref) => {
  const { t, i18n } = useTranslation()
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  })
  const [errorMessage, setErrorMessage] = useState('')
  const isError = !!states?.errors?.[name]

  useEffect(() => {
    setErrorMessage(isError ? states?.errors?.[name]?.message : '')
  }, [isError])

  const handleChange = (date) => {
    setValue(date)
    onChange(date.startDate)
  }

  return (
    <div>
      <Datepicker
        onChange={handleChange}
        i18n={i18n.language}
        displayFormat={t('DatepickerDisplayFormat')}
        separator={t('DatepickerSeparator')}
        configs={{
          shortcuts: {
            today: t('DatepickerShortcutToday'),
            yesterday: t('DatepickerShortcutYesterday'),
            currentMonth: t('DatepickerShortcutCurrentMonth'),
            pastMonth: t('DatepickerShortcutPastMonth')
          }
        }}
        {...props}
        value={value}
      />
      {errorMessage && <div className="mt-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
})

export default function DateInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        return <DateElement name={name} states={props.formState} {...props.field} {...rest} />
      }}
      rules={rules}
    />
  )
}
