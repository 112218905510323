import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex } from '@radix-ui/themes'
import CustomListVendors from '../Vendors/CustomsListVendors'

export default function CustomsMerchantVendors() {
  const { t } = useTranslation()
  const { isLoading, setActivePage, merchant } = useOutletContext()

  useEffect(() => {
    setActivePage('vendors')
  }, [])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div>
      <CustomListVendors merchantId={merchant.id} />
    </div>
  )
}
