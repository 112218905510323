import React from 'react'
import Badge from '../Badge/Badge'
import { useTranslation } from 'react-i18next'

export default function VendorStatus({ vendorStatus, size }) {
  const { t } = useTranslation()
  const statuses = {
    DRAFT: {
      title: t('VendorStatusDraft'),
      variant: 'gray'
    },
    PENDING: {
      title: t('VendorStatusWaitingApproval'),
      variant: 'yellow'
    },
    REVIEW: {
      title: t('VendorStatusNeedReview'),
      variant: 'red'
    },
    APPROVED: {
      title: t('VendorStatusApproved'),
      variant: 'green'
    }
  }
  return (
    <Badge size={size || 'xs'} variant={statuses[vendorStatus].variant}>
      {statuses[vendorStatus].title}
    </Badge>
  )
}
