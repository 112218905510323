import React, { useState } from 'react'

export default function ToggleInput({ optionLeft, optionRight, isChecked, onChange }) {
  const [checked, setChecked] = useState(isChecked)
  const handleChange = (event) => {
    const isChecked = event.currentTarget.checked
    setChecked(isChecked)
    onChange && onChange(isChecked)
  }

  return (
    <div className="flex text-sm text-gray-600">
      <p className="mr-2">{optionLeft}</p>
      <label htmlFor="toggle" className="relative inline-flex cursor-pointer items-center">
        <input type="checkbox" id="toggle" className="peer sr-only" checked={isChecked} onChange={handleChange} />
        <div className="peer h-5 w-9 rounded-full bg-slate-200  after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-slate-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-chill-600 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
      </label>
      <p className="ml-2">{optionRight}</p>
    </div>
  )
}
