import React from 'react'
import { Route } from 'react-router-dom'
import CustomsLayout from '../layouts/CustomsLayout'
import Dashboard from '../pages/Customs/Dashboard/CustomsDashboard'
import ListMerchants from '../pages/Customs/Merchants/ListMerchants'
import NewMerchant from '../pages/Admin/Merchant/NewMerchant'
import CustomsListPayments from '../pages/Customs/Payments/CustomsListPayments'
import ShowPayment from '../pages/Admin/Payments/ShowPayment'
import ConfirmUploadedPayments from '../components/Payments/ConfirmUploadedPayments'
import NewPaymentUpload from '../pages/Customs/Payments/NewPaymentUpload'
import BoletoPayment from '../pages/Customs/Payments/BoletoPayment'
import ViewMerchant from '../pages/Admin/Merchant/ViewMerchant'
import CustomsMerchantHome from '../pages/Customs/Merchant/CustomsMerchantHome'
import CustomsMerchantSettings from '../pages/Customs/Merchant/CustomsMerchantSettings'
import CustomsMerchantVendors from '../pages/Customs/Merchant/CustomsMerchantVendors'
import CustomsMerchantDetails from '../pages/Customs/Merchant/CustomsMerchantDetails'
import BoletoPaymentSuccess from '../pages/Customs/Payments/BoletoPaymentSuccess'

export default function CustomsRouter() {
  return (
    <Route path="customs" element={<CustomsLayout />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="merchants" element={<ListMerchants />}>
        <Route path="new" element={<NewMerchant />} />
      </Route>
      <Route path="merchant/:merchantId" element={<ViewMerchant />}>
        <Route path="" element={<CustomsMerchantSettings />}>
          <Route path="" element={<CustomsMerchantHome />} />
          <Route path="vendors" element={<CustomsMerchantVendors />} />
          <Route path="details" element={<CustomsMerchantDetails />} />
        </Route>
      </Route>
      <Route path="payments" element={<CustomsListPayments />}>
        <Route path="upload" element={<NewPaymentUpload />} />
      </Route>
      <Route path="pay-boleto" element={<BoletoPayment />} />
      <Route path="pay-boleto/success" element={<BoletoPaymentSuccess />} />
      <Route path="payments/:paymentId" element={<ShowPayment />} />
      <Route path="confirm-upload-payments" element={<ConfirmUploadedPayments />} />
    </Route>
  )
}
