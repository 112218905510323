import React from 'react'

export const cnpjMask = (value) => {
  return
}

export default function FormatCNPJ({ cnpj }) {
  const value = cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

  return <>{value}</>
}
