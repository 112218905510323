import React, { useState } from 'react'
import Form from '../../Form/Form'
import SubmitButton from '../../Form/Inputs/SubmitButton'
import PasswordInput from '../../Form/Inputs/PasswordInput'
import { useMutation } from '@tanstack/react-query'
import { setNewPassword } from '../../../services/authenticatorApi'
import { Link } from 'react-router-dom'
import Button from '../../Button/Button'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

function ResetPasswordForm({ token, email }) {
  const { t } = useTranslation()

  const passwordRules = [
    {
      title: t('ResetPasswordCharactersMinimum'),
      validator: (pass) => pass.match(/.{8,}/g)
    },
    {
      title: t('ResetPasswordSpecialcharacter'),
      validator: (pass) => pass.match(/[#?!@$%^&*-]/g)
    },
    {
      title: t('ResetPasswordUppercaseCharacter'),
      validator: (pass) => pass.match(/[A-Z]/g)
    },
    {
      title: t('ResetPasswordLowercaseCharacter'),
      validator: (pass) => pass.match(/[a-z]/g)
    },
    {
      title: t('ResetPasswordNumber'),
      validator: (pass) => pass.match(/[0-9]/g)
    },
    {
      title: t('ResetPasswordMatch'),
      validator: (pass, cpass) => pass === cpass
    }
  ]

  const [password, setPassword] = useState('')
  const [cpassword, setCPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const isValid = passwordRules.every((rule) => rule.validator(password, cpassword))
  const resetPassword = useMutation({
    mutationFn: setNewPassword,
    onError: (error) => {
      setErrorMessage(error.response?.data?.error?.message?.base?.[0])
    }
  })

  const handleSubmit = (data) => {
    resetPassword.mutate({ token, email, ...data })
  }

  if (resetPassword.isSuccess) {
    return (
      <div>
        <CheckIcon className="mx-auto h-16 fill-chill-600 stroke-chill-600" />
        <h1 className="mt-4 text-2xl font-semibold text-gray-800">{t('ResetPasswordcompleted')}</h1>
        <p className="mb-4 mt-2 text-gray-600">{t('ResetPasswordAllDone')}</p>
        <Link to="/">
          <Button size="xl" className="w-full">
            {t('ResetPasswordButtonSign')}
          </Button>
        </Link>
      </div>
    )
  }

  return (
    <div>
      <h1 className="mt-4 text-2xl font-semibold text-gray-800">{t('ResetPasswordChoosePassword')}</h1>
      <p className="mb-4 mt-2 text-gray-600">{t('ResetPasswordInsertNewPassword')}</p>

      {resetPassword.isError && <div className="mt-4 border border-red-100 p-4 text-red-900">{errorMessage}</div>}

      <Form className="my-4" onSubmit={handleSubmit} isLoading={resetPassword.isLoading}>
        <label htmlFor="email" className="mb-2 block font-medium text-gray-700">
          {t('ResetPasswordNewPassword')}
        </label>

        <PasswordInput
          onChange={(p) => setPassword(p.currentTarget.value)}
          name="password"
          size="lg"
          rules={{
            required: t('ResetPasswordErrorInsertPassword'),
            validate: () => isValid
          }}
        />

        <label htmlFor="email" className="mb-2 mt-6 block font-medium text-gray-700">
          {t('ResetPasswordConfirmNewPassword')}
        </label>

        <PasswordInput
          onChange={(p) => setCPassword(p.currentTarget.value)}
          name="password_confirmation"
          size="lg"
          rules={{
            required: t('ResetPasswordErrorInsertPassword'),
            validate: () => isValid
          }}
        />

        <div className="mt-4">
          <ul className="grid grid-cols-2 gap-y-1.5 text-xs">
            {passwordRules.map((rule) => (
              <li className={`${rule.validator(password, cpassword) ? 'text-green-700' : 'text-red-600'} `}>
                {rule.title}
              </li>
            ))}
          </ul>
        </div>

        <SubmitButton
          className="mt-6"
          text={t('ResetPasswordButtonCreatePassword')}
          loadingText={t('ResetPasswordLoading')}
          name="submit"
        />
      </Form>

      <Link to="/">
        <span className="flex items-center font-medium text-gray-600">
          <ArrowLeftIcon className="mr-2 h-4 w-4" /> {t('ResetPasswordButtonCreateNewPassword')}
        </span>
      </Link>
    </div>
  )
}
export default ResetPasswordForm
