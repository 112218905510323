import jwtDecode from 'jwt-decode'
import React, { createContext, useContext, useEffect, useState } from 'react'
import authenticatorApi from '../services/authenticatorApi'
import { PERMISSIONS } from '../data/Permissions'
import * as Sentry from '@sentry/react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('@App:user')
    if (storedUser) {
      const userInfo = JSON.parse(storedUser)
      Sentry.setUser({
        id: userInfo.id,
        email: userInfo.email,
        segment: userInfo.role
      })

      return userInfo
    }
    return false
  })

  async function UpdateCurrentUser(token) {
    // Update the token with newest one
    updateToken(token)

    if (isTokenImp()) {
      localStorage.setItem('@App:impersonated', true)
    } else {
      localStorage.setItem('@App:impersonated', false)
    }

    // Get user Data
    const userInfo = await authenticatorApi.get('/app/merchant/users/me')

    // add permissions to the user
    const user = userWithPermissions(userInfo.data.user)

    // Set user inside the context
    setUser(user)

    // Save information on local storage
    localStorage.setItem('@App:user', JSON.stringify(user))

    return user
  }

  async function Login(email, password, one_time_password) {
    // Try to login the user
    try {
      const response = await authenticatorApi.post('/api/email_password', {
        email,
        password,
        one_time_password
      })

      // Update user on store with current data
      const user = UpdateCurrentUser(response.data.token)

      // Return info to be used on components
      return user
    } catch (error) {
      const errorMessage = error?.response?.data?.error?.message?.base?.[0]

      if (errorMessage) {
        throw errorMessage
      }

      throw error
    }
  }

  function Logout() {
    setUser('')
    localStorage.removeItem('@App:user')
    localStorage.removeItem('@App:token')
    localStorage.removeItem('@App:impersonated')

    try {
      zE('messenger', 'logoutUser')
      zE('messenger', 'hide')
    } catch (error) {}

    return true
  }

  function isTokenImp() {
    const tokenData = jwtDecode(getToken())
    return !!tokenData.imp
  }

  function isImpersonated() {
    return localStorage.getItem('@App:impersonated') === 'true'
  }

  useEffect(() => {
    const storedUser = localStorage.getItem('@App:user')
    const storedToken = localStorage.getItem('@App:token')

    if (storedToken && storedUser) {
      setUser(JSON.parse(storedUser))
      updateToken(storedToken)
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, Login, Logout, UpdateCurrentUser, isImpersonated }}>
      {children}
    </AuthContext.Provider>
  )
}

function userWithPermissions(user) {
  user.permissions = PERMISSIONS[user.role]
  return user
}

function getToken() {
  return localStorage.getItem('@App:token').replace('Bearer ', '')
}

export function forceLogout() {
  // Remove user information
  localStorage.removeItem('@App:user')
  localStorage.removeItem('@App:token')
  localStorage.removeItem('@App:impersonate')

  // Redirect to sign-in page
  window.location = '/auth/sign-in'
}

export function updateToken(token) {
  const newToken = token.includes('Bearer ') ? token : `Bearer ${token}`

  localStorage.setItem('@App:token', newToken)
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export default AuthContext
