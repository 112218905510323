import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserAvatar from '../../components/Auth/UserAvatar'
import Button from '../../components/Button/Button'
import Main from '../../components/Main/Main'
import PageHeader from '../../components/Pages/PageHeader'
import Table from '../../components/Table/Table'
import { UserRole } from '../../data/UserRoles'
import authenticatorApi from '../../services/authenticatorApi'
import { CustomReactTimeAgo } from '../../components/DateTime/FormatDate'
import UsersProvider, { useUsers } from '../../components/UsersProvider/UsersProvider'

export default function Users() {
  return (
    <UsersProvider asyncFn={async () => (await authenticatorApi.get('/app/merchant/users')).data}>
      <UsersComponent />
    </UsersProvider>
  )
}

function UsersComponent() {
  const { t } = useTranslation()
  const { isLoading, error, data = { meta: {}, users: [] } } = useUsers()

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  const columns = [
    {
      title: t('UsersTableStatus'),
      render: ({ status }) => (
        <>
          <span className="inline-flex items-center rounded-full text-xs font-medium text-chill-800 sm:text-sm">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-chill-400 sm:h-3 sm:w-3" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            {t('UsersTableStatusActive')}
          </span>
        </>
      )
    },
    {
      title: t('UsersTableName'),
      render: (user) => (
        <div className="items-center sm:flex">
          <div className="hidden h-10 w-10 flex-shrink-0 sm:block">
            <UserAvatar user={user} size="sm" />
          </div>
          <div className="sm:ml-4">
            <div className="font-medium text-gray-900">{user.name}</div>
            <div className="text-xs text-gray-500 sm:text-sm">{user.email}</div>
            <div className="text-xs text-gray-500 sm:hidden">
              {user.last_request_at && <CustomReactTimeAgo date={Date.parse(user.last_request_at)} />}
            </div>
          </div>
        </div>
      )
    },
    {
      title: t('UsersTableRole'),
      render: ({ role }) => <UserRole role={role} />
    },
    {
      title: t('UsersTableLast'),
      render: ({ last_request_at }) => (
        <>
          {last_request_at ? (
            <CustomReactTimeAgo date={Date.parse(last_request_at)} />
          ) : (
            <span className="text-gray-400">{t('UsersSpanNever')}</span>
          )}
        </>
      )
    },

    {
      title: '',
      render: ({ id }) => (
        <>
          <Button size="xs" variant="secondary" as={<Link to={`/merchant/users/${id}`} />}>
            {t('UsersTableButtonEdit')}
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Outlet />

      <PageHeader
        title={t('UsersTitle')}
        subtitle={t('UsersSubtitle')}
        button={
          <>
            <Button as={<Link />} to="/merchant/users/new">
              {t('UsersButtonAddUser')}
            </Button>
          </>
        }
      />

      <Main className="py-6">
        <div className="rounded-t-md border border-b-0 p-4"></div>
        <Table columns={columns} rows={data.users} isLoading={isLoading} pagination={data.meta} hasFilters />
      </Main>
    </>
  )
}
