import { ArrowLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import Badge from '../../../components/Badge/Badge'
import Button from '../../../components/Button/Button'
import PageHeader from '../../../components/Pages/PageHeader'
import gatewayApi from '../../../services/gatewayApi'
import ledgerApi from '../../../services/ledgerApi'
import MerchantStatuses from '../../../components/Merchant/MerchantStatuses'
import { useTranslation } from 'react-i18next'

export default function ViewMerchant() {
  const { merchantId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [merchant, setMerchant] = useState({ name: 'Loading...' })
  const { t } = useTranslation()

  const refreshBalance = (event) => {
    gatewayApi
      .get('/app/admin/balance/2023-03-08')
      .then((r) => console.log('r', r))
      .catch((e) => console.log('e', e))
  }

  useEffect(() => {
    ledgerApi
      .get(`/v1/admin/merchants/${merchantId}`)
      .then((response) => {
        setIsLoading(false)
        setMerchant(response.data)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }, [])

  return (
    <div className="">
      <PageHeader
        title={
          <>
            <Link to={`/admin/merchant/${merchantId}`}>{merchant.name}</Link>
          </>
        }
        subtitle={
          <>
            {!isLoading && (
              <>
                <MerchantStatuses merchant={merchant} />
                <Badge variant="muted">{merchant.id}</Badge>
              </>
            )}
          </>
        }
        breadcrumb={
          <Button variant="outline" as={<Link to={`/admin/merchants`} />}>
            <div className="flex items-center">
              <ArrowLeftIcon className="h-4" />
              &nbsp;
              <span className="ml-2">All Merchants</span>
            </div>
          </Button>
        }
        button={
          <>
            <Button variant="secondary" as={<Link to={`/admin/merchant/${merchantId}/settings`} />}>
              <div className="flex items-center">
                <Cog6ToothIcon className="h-4" /> <span className="ml-2">{t('AdmimMerchantButtonSettings')}</span>
              </div>
            </Button>
          </>
        }
      />

      <div className="mt-10">
        <Outlet context={{ merchant, isLoading }} />
      </div>
    </div>
  )
}
