import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import authenticatorApi from '../../services/authenticatorApi'
import UserForm from './UserForm'
import SidePanel from '../../components/SidePanel/SidePanel'
import SidePanelHeader from '../../components/SidePanel/SidePanelHeader'
import SidePanelBody from '../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../components/SidePanel/SidePanelFooter'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button/Button'
import { useUsers } from '../../components/UsersProvider/UsersProvider'

export default function User() {
  const [open, setOpen] = useState(false)
  const [userDataIsLoading, setUserDataIsLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({ user: {} })
  const { reload: reloadUsers } = useUsers()
  const navigate = useNavigate()
  const { userId } = useParams()
  const submitRef = useRef()
  const { t } = useTranslation()

  const fetchUser = async () => {
    const response = await authenticatorApi.get(`/app/merchant/users/${userId}`)

    setData(response.data)
    setUserDataIsLoading(false)
    setOpen(true)
  }

  const handleSubmit = async (data) => {
    setIsLoading(true)
    await authenticatorApi.put(`/app/merchant/users/${data.id}`, { user: data })
    reloadUsers()
    setIsLoading(false)
    setOpen(false)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <SidePanel open={open} setOpen={setOpen} onClose={() => navigate('/merchant/users')}>
      <div>
        <SidePanelHeader
          onClose={() => setOpen(false)}
          title={t('UsersSidePainelEdit')}
          subtitle={t('UsersSidePainelSubtitle')}
        />
        <SidePanelBody>
          <UserForm
            data={data}
            setData={setData}
            isLoading={userDataIsLoading}
            submitRef={submitRef}
            onSubmit={handleSubmit}
          />
        </SidePanelBody>
        <SidePanelFooter>
          <Button type="button" variant="simple" disabled={isLoading} onClick={() => setOpen(false)}>
            {t('VendorsSidePainelButtonCancel')}
          </Button>
          <Button type="submit" variant="primary" isLoading={isLoading} onClick={() => submitRef.current.click()}>
            {t('VendorsSidePainelButtonSave')}
          </Button>
        </SidePanelFooter>
      </div>
    </SidePanel>
  )
}
