import React, { useState } from 'react'
import TextInput from './TextInput'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

export default function PasswordInput({ isLoading, visible, ...props }) {
  const [showPassword, setShowPassword] = useState(visible)
  const states = props.control._formState

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="relative">
      <span className="absolute right-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer" onClick={toggleShowPassword}>
        {showPassword ? (
          <EyeSlashIcon className="h-5 w-5 text-gray-600 active:relative active:left-px active:top-px" />
        ) : (
          <EyeIcon className="h-5 w-5 text-gray-600 active:relative active:left-px active:top-px" />
        )}
      </span>
      <TextInput {...props} type={showPassword ? 'text' : 'password'} />
    </div>
  )
}
