import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Button from '../../../components/Button/Button'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import Table from '../../../components/Table/Table'
import ledgerApi from '../../../services/ledgerApi'
import MerchantStatuses from '../../../components/Merchant/MerchantStatuses'
import { useTranslation } from 'react-i18next'
import NewMerchant from '../Merchant/NewMerchant'
import { FormattedCurrency } from '../../../components/TransactionHelpers'

export default function CustomListMerchants({ isCustoms = false }) {
  const [isLoading, setIsLoading] = useState(true)
  const [reload, setReload] = useState(true)
  const [openMerchant, setOpenMerchant] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const [data, setData] = useState({
    merchants: [],
    meta: {}
  })

  useEffect(() => {
    if (!reload) {
      return
    }

    ledgerApi
      .get('/v1/admin/merchants')
      .then((response) => {
        setData({ merchants: response.data })
        setIsLoading(false)
      })
      .catch((e) => console.log('e', e))

    setReload(false)
  }, [reload])

  useEffect(() => {
    setReload(location.state?.reload)
  }, [location.state?.reload])

  const columns = [
    {
      title: 'Cliente',
      render: (merchant) => (
        <>
          <span className="mb-1 block font-semibold">{merchant.name}</span>
          <span className="text-xs text-gray-500">{merchant.id}</span>
        </>
      )
    },
    {
      title: 'Saldo',
      node: 'saldo',
      render: () => <FormattedCurrency currency="BRL" amount="3000000" />
    },
    {
      title: 'Status',
      node: 'status',
      render: (merchant) => <MerchantStatuses merchant={merchant} />
    },
    {
      render: (merchant) => (
        <Button as={<Link to={`/customs/merchant/${merchant.id}`} />} size="xs" variant="secondary">
          {t('AdmimViewButton')}
        </Button>
      )
    }
  ]

  return (
    <div>
      <Outlet />

      <PageHeader
        title="Clientes"
        subtitle="Lista de todos os clientes"
        button={
          <>
            <Button onClick={() => setOpenMerchant(true)}>Adicionar Cliente</Button>
          </>
        }
      />

      <Main className="pt-4">
        <Table isLoading={isLoading} columns={columns} rows={data.merchants} />
      </Main>

      <NewMerchant open={openMerchant} setOpen={setOpenMerchant} />
    </div>
  )
}
