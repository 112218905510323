import React, { useEffect, useState } from 'react'
import Table from '../../../components/Table/Table'
import kernApi from '../../../services/kernApi'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import { useTranslation } from 'react-i18next'

export default function DashboardProcessors() {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('DashboardProcessors'),
      render: ({ alias, processor_type }) => (
        <>
          <span className="">{alias}</span>
        </>
      )
    },
    {
      title: t('DashboardProcessorsAmount'),
      rowClassName: 'text-right',
      headerClassName: 'text-right',
      render: ({ sum, currency }) => (
        <span className="font-semibold">
          <FormattedCurrency amount={sum} currency={currency} />
        </span>
      )
    }
  ]
  const [data, setData] = useState({ stats: [], retrieved_at: '' })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    kernApi
      .get('/app/admin/stats/processors')
      .then((response) => {
        setData({
          stats: response.data.result.rows,
          retrieved_at: response.data.retrieved_at
        })
        setIsLoading(false)
      })

      .catch((e) => console.log('e', e))
  }, [])

  return (
    <div>
      <Table columns={columns} rows={data.stats} isLoading={isLoading} />
      <p className="py-2 text-right text-xs text-gray-500">
        {data.retrieved_at ? (
          <>
            updated <CustomReactTimeAgo date={Date.parse(data.retrieved_at)} />
          </>
        ) : (
          t('loadingText')
        )}
      </p>
    </div>
  )
}
