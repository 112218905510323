import { ExclamationTriangleIcon, IdentificationIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import DetailedList from '../../components/DetailedList/DetailedList'
import DetailedListItem from '../../components/DetailedList/DetailedListItem'
import { FormattedCurrency } from '../../components/TransactionHelpers'
import BrazilBanks from '../../data/BrazilBanks.json'
import { useTranslation } from 'react-i18next'
import TransactionReceiptPdf from './TransactionReceiptPdf'
import transactionStatementDiscoverFlowStatus from '../../utils/transactionStatementDiscoverFlowStatus'

export default function TransactionReceipt() {
  const { t } = useTranslation()
  const { transaction, merchant, isLoading } = useOutletContext()

  const player = useMemo(
    () =>
      transaction?.statement?.find((statement) => {
        return transactionStatementDiscoverFlowStatus(statement) === 'deposit'
      })?.payer,
    [transaction]
  )

  const bank = useMemo(() => {
    if (!transaction.statement) {
      return {}
    }
    const bank = BrazilBanks.filter((bank) => player?.ispb == bank.ISPB).at(0)
    return bank
  }, [transaction.statement])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {Boolean(merchant) &&
        transaction.statement?.map((statement, index) => {
          const { e2e_id, amount, status, date } = statement
          const transactionType = transactionStatementDiscoverFlowStatus(statement)
          return (
            <div key={index} className="px-4 py-5 sm:p-0 print:mx-auto print:w-2/3 print:scale-90">
              <DetailedList
                title={transactionType === 'refund' ? t('TransactionRefund') : t('TransactionReceipt')}
                icon={ReceiptPercentIcon}
                button={
                  <TransactionReceiptPdf
                    type={transactionType === 'refund' ? 'refund' : 'pay'}
                    transaction={transaction}
                    merchant={merchant}
                  />
                }
              >
                <DetailedListItem isLoading={isLoading} title={t('TransactionReceiptE2E')} value={e2e_id} />
                <DetailedListItem title={t('TransactionReceiptAmount')} isLoading={isLoading}>
                  <span className="font-bold">
                    <FormattedCurrency amount={amount} currency={transaction.processor.currency} />
                  </span>
                </DetailedListItem>
                <DetailedListItem
                  title={t('TransactionReceiptType')}
                  value={t('TransactionReceiptPIX')}
                  isLoading={isLoading}
                />
                {/* <DetailedListItem isLoading={isLoading} title={t('TransactionReceiptStatus')} value={status} /> */}
                <DetailedListItem isLoading={isLoading} title={t('TransactionReceiptDate')} value={date} />
              </DetailedList>

              {index === transaction.statement?.length - 1 && (
                <DetailedList
                  title={
                    transaction.flow_type === 'cashout' ||
                    transaction.transaction_events?.at(-1)?.event_type == 'payment'
                      ? t('TransactionReceiptRecipient')
                      : t('TransactionReceiptPayer')
                  }
                  icon={IdentificationIcon}
                >
                  <DetailedListItem isLoading={isLoading} title={t('TransactionReceiptName')} value={player?.name} />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptDocument')}
                    value={player?.document}
                  />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptBank')}
                    value={`${bank?.ShortName}`}
                  />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptBankISPB')}
                    value={`${player?.ispb}`}
                  />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptBankAgency')}
                    value={`${player?.bank_agency}`}
                  />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptBankAccount')}
                    value={player?.acc_number}
                  />
                  <DetailedListItem
                    isLoading={isLoading}
                    title={t('TransactionReceiptBankAccountType')}
                    value={t(`BankAccountTypes.${player?.acc_type}`)}
                  />
                </DetailedList>
              )}
            </div>
          )
        })}
      {(!transaction.statement || transaction.statement.length === 0) && (
        <>
          <div className="m-4 rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">{t('TransactionsSidePainelReiceiptAlert')}</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>{t('TransactionsSidePainelReiceiptNotAvailable')}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
