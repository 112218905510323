import React from 'react'

export default function ErrorIconCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36" {...props}>
      <path
        stroke="#DC6803"
        strokeWidth="1.667"
        d="M5.5 18c0-6.903 5.597-12.5 12.5-12.5 6.904 0 12.5 5.597 12.5 12.5 0 6.904-5.596 12.5-12.5 12.5-6.903 0-12.5-5.596-12.5-12.5z"
        opacity="0.3"
      ></path>
      <path
        stroke="#DC6803"
        strokeWidth="1.667"
        d="M1.333 18C1.333 8.795 8.795 1.333 18 1.333c9.205 0 16.667 7.462 16.667 16.667 0 9.205-7.462 16.667-16.667 16.667-9.205 0-16.667-7.462-16.667-16.667z"
        opacity="0.1"
      ></path>
      <g clipPath="url(#clip0_2799_9562)">
        <path
          stroke="#DC6803"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.667"
          d="M18 14.667V18m0 3.333h.008M26.333 18a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2799_9562">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(8 8)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
