import React from 'react'
import Badge from '../Badge/Badge'
import { useTranslation } from 'react-i18next'

export default function PaymentStatus({ paymentStatus, size }) {
  const { t } = useTranslation()
  const statuses = {
    DRAFT: {
      title: t('PaymentStatusDraft'),
      variant: 'gray'
    },
    WAITING_APPROVAL: {
      title: t('PaymentStatusWaitingApproval'),
      variant: 'yellow'
    },
    REVIEW: {
      title: t('PaymentStatusNeedReview'),
      variant: 'red'
    },
    WAITING_SETTLEMENT: {
      title: t('PaymentStatusWaitingSettlement'),
      variant: 'blue'
    },
    PAID: {
      title: t('PaymentStatusPaid'),
      variant: 'green'
    },
    NOT_APPROVED: {
      title: t('PaymentStatusNotApproved'),
      variant: 'red'
    },
    PENDING: {
      title: t('PaymentStatusPending'),
      variant: 'gray'
    }
  }
  return (
    <Badge variant={statuses[paymentStatus].variant} size={size}>
      {statuses[paymentStatus].title}
    </Badge>
  )
}
