import React from 'react'
import clsx from 'clsx'
import { ExclamationCircleFill } from 'react-bootstrap-icons'
import ReactInputMask from 'react-input-mask'

export default function TextInput({
  register,
  name,
  rules,
  size,
  error,
  type,
  className,
  containerClassName,
  states,
  isLoading,
  mask,
  disabled,
  ...rest
}) {
  const sizes = {
    md: 'sm:text-sm',
    lg: 'w-full px-3 py-3'
  }

  const isError = !!states?.errors?.[name] || error
  const errorMessage = isError ? states?.errors?.[name]?.message : false || error
  const registerProps = typeof register === 'function' ? register(name, rules) : {}
  const InputComponent = Boolean(mask) ? ReactInputMask : 'input'

  return (
    <>
      <div className={clsx('relative', containerClassName)}>
        <InputComponent
          type={type || 'text'}
          mask={mask}
          disabled={disabled}
          className={`block w-full rounded-md border border-gray-300 placeholder-gray-400 shadow-sm focus:border-chill-500 focus:ring-chill-500 ${
            sizes[size || 'md']
          } ${
            isError &&
            'border-2 border-red-600 bg-red-50 text-red-900 placeholder-red-300 focus:border-red-600 focus:outline-none focus:ring-red-600'
          } ${disabled && 'bg-gray-100'} ${className}`}
          {...registerProps}
          {...rest}
        />
        {isError && type != 'password' && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleFill className="h-5 w-5 text-red-600" aria-hidden="true" />
          </div>
        )}
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </>
  )
}
