import React, { useEffect, useState } from 'react'
import bureauApi from '../../../services/bureauApi'
import { useParams } from 'react-router-dom'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import FormatCPF from '../../../components/Helpers/FormatCPF'
import CustomerRisk from '../../../components/Customer/CustomerRisk'
import { IdentificationIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function CustomerReport() {
  const { t } = useTranslation()
  const { customerId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})
  const [report, setReport] = useState({})

  useEffect(() => {
    bureauApi
      .get(`/app/admin/customers/${customerId}`)
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
        setReport(response.data.reports.filter((r) => r.report_status == 'FINISHED')[0])
      })
      .catch((e) => console.log('e', e))
  }, [])

  if (isLoading) {
    return <>{t('loadingText')}</>
  }

  return (
    <div>
      <PageHeader
        avatar={<IdentificationIcon className="h-10" />}
        title={data.name}
        subtitle={<FormatCPF cpf={data.document} />}
      />
      <Main>
        <div>
          <div>
            <div className="mt-6">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportFullName')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportCPF')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <FormatCPF cpf={data.document} />
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportSituationCPF')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.document_status}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportEmailAddress')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.email}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportPhone')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{data.phone}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportRisk')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <CustomerRisk customer={data} />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        {report && (
          <div className="pb-6">
            <h1 className="mt-4 text-lg font-semibold">Report</h1>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportFullName')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.nome}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportBirth')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.data_de_nascimento}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportCPF')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.numero}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    {t('CustomerReportRegistrationStatus')}
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.cpf_situacao_cadastral}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportIncomeTax')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.situacao_imposto_de_renda}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportPEP')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.pep ? 'SIM' : 'NÃO'}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportIncome')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.cpf.renda}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('CustomerReportResult')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {report.result.result.resultado}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </Main>
    </div>
  )
}
