import React, { useMemo, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import {
  ArrowLeftOnRectangleIcon,
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  ChartPieIcon,
  Cog8ToothIcon,
  IdentificationIcon,
  WalletIcon
} from '@heroicons/react/24/outline'
import CustomToaster from '../components/CustomToaster/CustomToaster'
import Sidebar from '../components/Sidebar/Sidebar'
import Topbar from '../components/Topbar/Topbar'
import { useAuth } from '../contexts/AuthContext'
import { filterLinksWithPermissions } from '../helpers/utils'
import { useTranslation } from 'react-i18next'

export default function AdminLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()
  const auth = useAuth()
  const { t } = useTranslation()

  const navigation = [
    {
      name: t('NavigationAdminDashboard'),
      href: '/admin/dashboard',
      icon: ChartPieIcon,
      current: true,
      permission: 'dashboard'
    },
    {
      name: t('NavigationAdminMerchants'),
      href: '/admin/merchants',
      icon: BuildingStorefrontIcon,
      current: false,
      permission: 'merchants'
    },
    // {
    //   name: "Transactions",
    //   href: "/admin/transactions",
    //   icon: QueueListIcon,
    //   current: false,
    //   permission: "transactions",
    // },
    {
      name: t('NavigationAdminSettings'),
      href: '/admin/vendors',
      icon: BuildingOfficeIcon,
      current: false,
      permission: 'merchants'
    },
    {
      name: t('NavigationAdminPayments'),
      href: '/admin/payments',
      icon: WalletIcon,
      current: false,
      permission: 'merchants'
    },
    {
      name: t('NavigationAdminCompliance'),
      href: '/admin/bureau',
      icon: IdentificationIcon,
      permission: 'compliance'
    }
    // {
    //   name: "Batches",
    //   href: "/admin/batches",
    //   icon: CircleStackIcon,
    //   current: false,
    // },
    // {
    //   name: "Users",
    //   href: "/admin/users",
    //   icon: UsersIcon,
    //   current: false,
    //   permission: "users",
    // },
  ]
  const secondaryNavigation = [
    {
      name: t('NavigationAdminSettings'),
      href: '/merchant/settings',
      icon: Cog8ToothIcon,
      permission: 'profile'
    },
    {
      name: t('NavigationAdminLogout'),
      href: '/auth/sign-out',
      icon: ArrowLeftOnRectangleIcon,
      permission: 'profile'
    }
  ]

  const userNavigation = [
    { name: t('NavigationAdminYourProfile'), href: '#' },
    { name: t('NavigationAdminSettings'), href: '#' },
    { name: t('NavigationAdminLogout'), href: '/auth/sign-out' }
  ]
  const availableLinks = useMemo(() => {
    return filterLinksWithPermissions(navigation, auth)
  }, [auth, location])

  if (auth.user.role !== 'admin') {
    return <Navigate to="/merchant" />
  }

  const customsUrl = 'pinho'
  if (window.location.origin.includes(customsUrl)) {
    return <Navigate to="/customs/dashboard" />
  }

  if (location.pathname === '/admin' || location.pathname === '/admin/') {
    return <Navigate to={availableLinks[0].href} />
  }

  return (
    <>
      <div className="min-h-full">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={availableLinks}
          secondaryNavigation={secondaryNavigation}
        />

        <div className="flex flex-1 flex-col lg:pl-64">
          <Topbar userNavigation={userNavigation} isAdmin={true} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1">
            <div className="">
              <CustomToaster />
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
