import React from 'react'
import Badge from '../Badge/Badge'

export default function CustomerRisk({ customer }) {
  return (
    <div className="mr-2 h-2 w-full rounded bg-gray-200">
      <div
        className="h-2 rounded bg-yellow-600"
        style={{
          width: `${customer.risk_ratio * 100}%`
        }}
      ></div>
    </div>
  )
}
