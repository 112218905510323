import React from 'react'
import { useTranslation } from 'react-i18next'

export const MERCHANT_ROLES = [
  { id: 'merchant_admin', value: 'UserRolesMerchantAdmin' },
  { id: 'merchant_finance', value: 'UserRolesMerchantFinance' },
  { id: 'merchant_employee', value: 'UserRolesMerchantOperator' }
]

export const ADMIN_ROLES = [{ id: 'admin', value: 'Administrator' }]
export const USER_ROLES = [...ADMIN_ROLES, ...MERCHANT_ROLES]

export const UserRole = ({ role }) => {
  const { t } = useTranslation()
  const roleObject = MERCHANT_ROLES.filter(({ id }) => id == role)[0]
  return <>{t(roleObject?.value)}</>
}
