import React from 'react'

export default function InputSkeleton() {
  return (
    <div className="animate-pulse">
      <div className="block w-full rounded-md border border-gray-300 p-2 text-gray-500 shadow-sm focus:border-chill-500 focus:ring-chill-500 sm:text-sm">
        Loading...
      </div>
    </div>
  )
}
