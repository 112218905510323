import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef } from 'react'

export default function SidePanel({ open, setOpen, children, onClose }) {
  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-0 inset-y-0 right-0 flex max-h-full max-w-full overflow-y-auto pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
              afterLeave={onClose}
            >
              <Dialog.Panel className="pointer-events-auto fixed right-0 h-full w-screen max-w-sm transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all sm:max-w-2xl print:max-w-full">
                <div className="h-full">
                  {React.cloneElement(children, {
                    className: 'h-full w-full p-0 m-0 flex flex-col'
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
