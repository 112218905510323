import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import PageHeader from '../../../../components/Pages/PageHeader'
import { useTranslation } from 'react-i18next'

export default function MerchantSettingsAccount() {
  const { setActivePage } = useOutletContext()
  const { t } = useTranslation()

  useEffect(() => {
    setActivePage('account')
  }, [])

  return (
    <div>
      <PageHeader title="Account" variant="simple" />

      <form className="divide-y-neutral-200 mt-6 space-y-8 divide-y">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-lg font-medium text-neutral-800">{t('MerchantSettingsAccountProfile')}</h2>
            <p className="mt-1 text-sm text-neutral-500">{t('MerchantSettingsAccountInformantion')}</p>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="first-name" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountFirstName')}
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountLastName')}
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="username" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountUserName')}
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-neutral-300 bg-neutral-50 px-3 text-neutral-500 sm:text-sm">
                {t('MerchantSettingsAccountWorkcation')}
              </span>
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                defaultValue="lisamarie"
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-neutral-300 text-neutral-800 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="photo" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountPhoto')}
            </label>
            <div className="mt-1 flex items-center">
              <img
                className="inline-block h-12 w-12 rounded-full"
                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                alt=""
              />
              <div className="ml-4 flex">
                <div className="bg-gray-50s relative flex cursor-pointer items-center rounded-md border border-neutral-300 px-3 py-2 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-neutral-50 hover:bg-neutral-50">
                  <label
                    htmlFor="user-photo"
                    className="pointer-events-none relative text-sm font-medium text-neutral-800"
                  >
                    <span>{t('MerchantSettingsAccountChange')}</span>
                    <span className="sr-only"> {t('MerchantSettingsAccountUserPhoto')}</span>
                  </label>
                  <input
                    id="user-photo"
                    name="user-photo"
                    type="file"
                    className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                  />
                </div>
                <button
                  type="button"
                  className="ml-3 rounded-md border border-transparent bg-transparent px-3 py-2 text-sm font-medium text-neutral-800 hover:text-neutral-700 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-neutral-50"
                >
                  {t('MerchantSettingsAccountButtonRemove')}
                </button>
              </div>
            </div>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="description" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountDescription')}
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                name="description"
                rows={4}
                className="block w-full rounded-md border border-neutral-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                defaultValue={''}
              />
            </div>
            <p className="mt-3 text-sm text-neutral-500">{t('MerchantSettingsAccountBrief')}</p>
          </div>

          <div className="sm:col-span-6">
            <label htmlFor="url" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountUrl')}
            </label>
            <input
              type="text"
              name="url"
              id="url"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-lg font-medium text-neutral-800">{t('MerchantSettingsAccountPersonalInformation')}</h2>
            <p className="mt-1 text-sm text-neutral-500">{t('MerchantSettingsAccountPublicly')}</p>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="email-address" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountEmail')}
            </label>
            <input
              type="text"
              name="email-address"
              id="email-address"
              autoComplete="email"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="phone-number" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountPhone')}
            </label>
            <input
              type="text"
              name="phone-number"
              id="phone-number"
              autoComplete="tel"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountCountry')}
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country-name"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            >
              <option />
              <option>{t('MerchantSettingsAccountUnitedStates')}</option>
              <option>{t('MerchantSettingsAccountCanada')}</option>
              <option>{t('translation.MerchantSettingsAccountMexico')}</option>
            </select>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="language" className="block text-sm font-medium text-neutral-800">
              {t('MerchantSettingsAccountLanguage')}
            </label>
            <input
              type="text"
              name="language"
              id="language"
              className="mt-1 block w-full rounded-md border-neutral-300 text-neutral-800 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <p className="text-sm text-neutral-500 sm:col-span-6">
            {t('MerchantSettingsAccountCreatedOn')}{' '}
            <time dateTime="2017-01-05T20:35:40">January 5, 2017, 8:35:40 PM</time>.
          </p>
        </div>

        <div className="flex justify-end pt-8">
          <button
            type="button"
            className="bg-gray-50s rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-neutral-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {t('MerchantSettingsAccountButtonCancel')}
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {t('MerchantSettingsAccountButtonSave')}
          </button>
        </div>
      </form>
    </div>
  )
}
