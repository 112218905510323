import React from 'react'
import { classNames } from '../../helpers/dom'

export default function Skeleton({ className }) {
  return (
    <div>
      <div className={classNames('w-5/6', className)}>
        <div className="mb-1.5 block h-2 w-full rounded-full bg-gray-300"></div>
        <div className="block h-1.5 w-5/6 rounded-full bg-gray-200"></div>
      </div>
    </div>
  )
}
