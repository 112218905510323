import React, { useId, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Progress } from '@radix-ui/themes'
import formatBytes from '../../utils/formatBytes'
import useAsyncState from '../../hooks/useAsyncState'
import UploadIcon from './icons/UploadIcon'
import FileIcon from './icons/FileIcon'
import CircleCheckIcon from './icons/CircleCheckIcon'
import TrashIcon from './icons/TrashIcon'

export default function Dropzone({ accept = [], onChange, onUpload }) {
  const { t } = useTranslation()
  const [getFiles, setFiles] = useAsyncState({})
  const [getUploadProgress, setUploadProgress] = useAsyncState({})
  const [dragOver, setDragOver] = useState(false)
  const files = getFiles()
  const uploadProgress = getUploadProgress()
  const id = useId()

  const setUploadProgressById = (id, progress) => {
    setUploadProgress({
      ...getUploadProgress(),
      [id]: progress
    })
  }

  const onChangeHandle = () => {
    if (!onChange) return
    onChange(Object.values(getFiles()).map((file) => file))
  }

  const preventEvents = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onAddHandle = (_files) => {
    const filesToAdd = {}

    const sanitizedFiles = [..._files].filter((file) => {
      const ext = `.${file.name.split('.').pop()}`
      return accept.includes(ext)
    })

    sanitizedFiles.forEach((file) => {
      const id = crypto.randomUUID()

      filesToAdd[id] = {
        data: null,
        file
      }

      onUploadHandle(id, file)
    })

    const newFiles = { ...getFiles(), ...filesToAdd }

    setFiles(newFiles)
    onChangeHandle()
  }

  const onRemoveHandle = (id) => {
    const newFiles = { ...getFiles() }

    delete newFiles[id]

    setFiles(newFiles)
    onChangeHandle()
  }

  const onUploadHandle = async (id, file) => {
    if (!onUpload) return

    setUploadProgressById(id, 0)

    const onProgress = (progress) => setUploadProgressById(id, progress)

    const onUploaded = (data) => {
      const files = getFiles()

      const newFiles = {
        ...files,
        [id]: {
          ...files[id],
          data
        }
      }

      setFiles(newFiles)

      onChangeHandle()
    }

    onUpload(file, onProgress, onUploaded)
  }

  return (
    <div className="flex flex-col gap-4">
      <input
        type="file"
        multiple
        accept={accept.join(', ')}
        id={id}
        onChange={(e) => {
          onAddHandle(e.target.files)
          e.target.value = ''
        }}
        className="hidden"
      />
      <label
        className="relative overflow-hidden px-6 py-4 bg-white rounded-xl border border-gray-200 cursor-pointer"
        htmlFor={id}
        onDragOver={preventEvents}
        onDrop={(e) => {
          preventEvents(e)
          setDragOver(false)

          onAddHandle(e.dataTransfer.files)
        }}
        onDragEnter={(e) => {
          preventEvents(e)
          setDragOver(true)
        }}
        onDragLeave={(e) => {
          preventEvents(e)
          setDragOver(false)
        }}
      >
        <div className="flex flex-col items-center gap-3 pointer-events-none">
          <div className="w-10 h-10 p-2.5 rounded-lg border border-gray-200 justify-center items-center inline-flex">
            <UploadIcon />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm">
              <span className="text-sky-800 font-semibold">{t('DropzoneLinkToUpload')}</span>
              <span className="text-slate-600">{t('DropzoneDragAndDrop')}</span>
            </span>
            <span className="text-center text-slate-600 text-xs">{accept.join(', ').toUpperCase()}</span>
          </div>
          {dragOver && (
            <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center bg-slate-900 bg-opacity-50 backdrop-blur-sm">
              <span className="text-white text-lg font-semibold">Drop file</span>
            </div>
          )}
        </div>
      </label>
      {Boolean(Object.keys(files).length) && (
        <ul className="flex flex-col gap-3">
          {Object.keys(files).map((id) => {
            const file = files[id]
            const progress = uploadProgress[id] || 0
            const isUploaded = progress === 100

            return (
              <li key={id} className="group flex gap-3 p-4 rounded-xl border border-gray-200">
                <div>
                  <FileIcon />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex gap-4">
                    <div className="flex flex-col">
                      <span className="text-slate-700 text-sm font-medium leading-tight">{file.file.name}</span>
                      <span className="text-slate-600 text-sm font-normal">{formatBytes(file.file.size)}</span>
                    </div>
                    <div
                      className={clsx('flex flex-col ml-auto', {
                        'group-hover:flex-col-reverse': isUploaded
                      })}
                    >
                      {isUploaded && <CircleCheckIcon className="visible group-hover:invisible" />}
                      <button
                        onClick={() => onRemoveHandle(id)}
                        className={clsx({
                          'invisible group-hover:visible': isUploaded
                        })}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                  {Boolean(onUpload) && (
                    <div className="flex items-center gap-3">
                      <Progress variant="soft" value={progress} />
                      <span className="text-slate-700 text-sm font-medium leading-tight">{progress}%</span>
                    </div>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
