import { UserCircleIcon, UserPlusIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import UserAvatar from '../../../../components/Auth/UserAvatar'
import Badge from '../../../../components/Badge/Badge'
import Button from '../../../../components/Button/Button'
import EmptyState from '../../../../components/EmptyState/EmptyState'
import PageHeader from '../../../../components/Pages/PageHeader'
import Table from '../../../../components/Table/Table'
import YesNo from '../../../../components/YesNo/YesNo'
import { UserRole } from '../../../../data/UserRoles'
import authenticatorApi from '../../../../services/authenticatorApi'
import UsersProvider, { useUsers } from '../../../../components/UsersProvider/UsersProvider'
import DescriptionListModal from '../../../../components/Modal/DescriptionListModal'
import TextInput from '../../../../components/Form/Inputs/TextInput'
import CustomToaster from '../../../../components/CustomToaster/CustomToaster'

export default function MerchantSettingsUser() {
  const { merchantId } = useParams()
  const { setActivePage } = useOutletContext()

  useEffect(() => {
    setActivePage('users')
  }, [])

  return (
    <UsersProvider
      asyncFn={async () =>
        (
          await authenticatorApi.get('/app/admin/users', {
            params: {
              merchant_id: merchantId
            }
          })
        ).data
      }
    >
      <MerchantSettingsUserComponent />
    </UsersProvider>
  )
}

function MerchantSettingsUserComponent() {
  const { merchantId } = useParams()
  const { t } = useTranslation()
  const [apiKeyHolderModalIsOpen, setApiKeyHolderModalIsOpen] = useState(false)
  const { isLoading, error, data = { meta: {}, users: [] } } = useUsers()
  const userApiKeyHolder = data.users.find(({ type }) => type === 'api_key_holder')

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  const columns = [
    // {
    //   title: "ID",
    //   node: "id",
    // },
    {
      title: t('MerchantSettingsUserName'),
      render: (user) => (
        <div className="flex items-center">
          <div className="hidden sm:block">
            <UserAvatar user={user} size="sm" />
          </div>
          <div className="sm:ml-3">
            <span className="block font-medium">{user.name}</span>
            <span className="text-xs">{user.email}</span>
            <div className="sm:hidden">
              <Badge variant="inverted">
                <UserRole role={user.role} />
              </Badge>
              <YesNo test={user.active} ifYes="Active" ifNo="Not active" className="ml-1" />
            </div>
          </div>
        </div>
      )
    },
    {
      title: t('MerchantSettingsUserRole'),
      onlyDesktop: true,
      render: ({ role }) => (
        <Badge>
          <UserRole role={role} />
        </Badge>
      )
    },
    {
      title: t('MerchantSettingsUserActive'),
      onlyDesktop: true,
      render: ({ active }) => <YesNo test={active} />
    },
    {
      title: t('MerchantSettingsUserConfirmed'),
      onlyDesktop: true,
      render: ({ email_confirmation }) => <YesNo test={email_confirmation} />
    },
    {
      title: '',
      render: ({ id, merchant_id, type }) => (
        <div className="grid w-fit gap-2">
          <Button
            size="xs"
            variant="secondary"
            as={<Link to={`/admin/merchant/${merchant_id}/users/${id}/impersonate`} />}
          >
            {t('MerchantSettingsUserButtonImpersonate')}
          </Button>
          {type === 'api_key_holder' && (
            <Button size="xs" variant="secondary" onClick={() => setApiKeyHolderModalIsOpen(true)}>
              {t('MerchantSettingsUserButtonCredentials')}
            </Button>
          )}
        </div>
      )
    }
  ]

  const apiKeyHolderRender = (value) => (
    <div className="flex items-center gap-2">
      <TextInput value={value} containerClassName="w-full" />
      <button
        className="ml-auto"
        onClick={async () => {
          await navigator.clipboard.writeText(value)
          toast(t('successCopy'))
        }}
      >
        <DocumentDuplicateIcon height={24} />
      </button>
    </div>
  )

  return (
    <>
      <CustomToaster />

      <Outlet />

      <PageHeader
        title={t('MerchantSettingsUsers')}
        variant="simple"
        button={
          <Button size="sm" as={<Link to={`/admin/merchant/${merchantId}/settings/users/new`} />}>
            <UserPlusIcon className="mr-2 h-4" /> {t('MerchantSettingsUsersNew')}
          </Button>
        }
      />

      <DescriptionListModal
        open={apiKeyHolderModalIsOpen}
        onClose={() => setApiKeyHolderModalIsOpen(false)}
        title={t('UserCredentialsModalTitle')}
        data={[
          {
            label: t('UserCredentialsModalApiKey'),
            value: userApiKeyHolder?.production_secret_key || userApiKeyHolder?.sandbox_secret_key,
            render: apiKeyHolderRender
          }
        ]}
        FooterComponent={() => (
          <Button type="button" size="lg" className="w-full" onClick={() => setApiKeyHolderModalIsOpen(false)}>
            {t('UserCredentialsModalClose')}
          </Button>
        )}
      />

      <Table
        columns={columns}
        rows={data.users}
        isLoading={isLoading}
        pagination={data.meta}
        emptyState={
          <>
            <EmptyState
              icon={<UserCircleIcon />}
              title={t('MerchantSettingsUserTitle')}
              description={t('MerchantSettingsUserDescription')}
              action={<Button>{t('MerchantSettingsUserButtonNewUser')}</Button>}
            />
          </>
        }
      />
    </>
  )
}
