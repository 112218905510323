import React from 'react'
import clsx from 'clsx'

export default function TabButton({ active, children, className, ...rest }) {
  return (
    <button
      className={clsx('inline-flex justify-center items-center gap-2 px-3 py-2 rounded-md', {
        'bg-gray-50': active
      })}
      {...rest}
    >
      <span
        className={clsx('text-base font-semibold', {
          'text-slate-500': !active,
          'text-slate-700': active
        })}
      >
        {children}
      </span>
    </button>
  )
}
