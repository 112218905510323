import './App.css'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { inject } from '@vercel/analytics'
import { Theme } from '@radix-ui/themes'
import PreviewAlert from './components/Flag/PreviewAlert'
import Router from './routes/Router'
import { AuthProvider } from './contexts/AuthContext'

inject()

function App() {
  const queryClient = new QueryClient()

  return (
    <FlagsmithProvider options={{ environmentID: process.env.REACT_APP_FLAGSMITH }} flagsmith={flagsmith}>
      <Theme>
        <PreviewAlert />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </QueryClientProvider>
      </Theme>
    </FlagsmithProvider>
  )
}

export default App
