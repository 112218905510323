export function getMaskByPixKeyType(pixKeyType) {
  switch (pixKeyType) {
    case 'DOCUMENT':
      return '99.999.999/9999-99'
    case 'PHONE':
      return '+99 (99) 9 9999-9999'
    default:
      return ''
  }
}
