import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import { fetchAdminPayments } from '../../../services/ledgerApi'
import PaymentStatus from '../../../components/Payment/PaymentStatus'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import Filters from '../../../components/Filters/Filters'

const initialFilters = {
  status: ['WAITING_APPROVAL']
}

export default function ListPayments() {
  const { t } = useTranslation()
  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const queryClient = useQueryClient()
  const {
    data = [],
    isLoading,
    isRefetching
  } = useQuery({
    queryFn: fetchAdminPayments,
    meta: { filters: selectedFilters },
    queryKey: ['fetchAdminPayments', { merchantId: null }]
  })

  const columns = [
    {
      title: t('AdminPaymentsPayment'),
      render: (payment) => (
        <div>
          <Link to={`/admin/payments/${payment.id}`}>
            <h1>{payment.id}</h1>
          </Link>
        </div>
      )
    },
    {
      title: t('AdminPaymentsType'),
      render: ({ payment_type }) => payment_type
    },
    {
      title: t('AdminPaymentsMerchant'),
      render: ({ merchant }) => <Link to={`/admin/merchant/${merchant.id}`}>{merchant.name}</Link>
    },
    {
      title: t('AdminPaymentsAmount'),
      render: ({ amount }) => <FormattedCurrency amount={amount} currency="BRL" />
    },
    {
      title: t('AdminPaymentsStatus'),
      render: ({ payment_status }) => <PaymentStatus paymentStatus={payment_status} />
    }
  ]

  useEffect(() => {
    ;(async () => {
      await queryClient.cancelQueries()
      queryClient.fetchQuery({
        queryKey: ['fetchAdminPayments', { merchantId: null }],
        meta: { filters: selectedFilters },
        queryFn: fetchAdminPayments
      })
    })()
  }, [selectedFilters])

  const filters = [
    {
      id: 'status',
      title: t('TransactionsSidePainelStatus'),
      options: [
        { id: 'DRAFT', title: t('PaymentStatusDraft') },
        { id: 'WAITING_APPROVAL', title: t('PaymentStatusWaitingApproval') },
        { id: 'REVIEW', title: t('PaymentStatusNeedReview') },
        { id: 'WAITING_SETTLEMENT', title: t('PaymentStatusWaitingSettlement') },
        { id: 'PAID', title: t('PaymentStatusPaid') }
      ]
    }
  ]

  const filteredData = data.filter(
    ({ payment_status }) => selectedFilters.status.length === 0 || selectedFilters.status.includes(payment_status)
  )

  return (
    <div>
      <PageHeader title={t('AdmimPayments')} subtitle={t('AdminPaymentsSubtitle')} />

      <div className="mt-7">
        <Filters
          filters={filters}
          selectedFilters={selectedFilters}
          onReset={() => setSelectedFilters({ status: [] })}
          onChangeFilters={setSelectedFilters}
        />
      </div>

      <Main>
        <Table isLoading={isLoading} columns={columns} rows={filteredData} isRefetching={isRefetching} hasFilters />
      </Main>
    </div>
  )
}
