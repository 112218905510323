import React from 'react'

export default function RowSkeleton({ times, cols }) {
  return (
    <>
      {Array.from(Array(times || 1).keys()).map((x) => (
        <tr key={`list-${x}`}>
          <td colSpan={cols || 5}>
            <div className="flex animate-pulse items-center justify-between p-4">
              <div className="w-5/6">
                <div className="mb-2.5 h-2.5 w-full rounded-full bg-gray-300"></div>
                <div className="h-2 w-5/6 rounded-full bg-gray-200"></div>
              </div>
              <div className="h-2.5 w-12 rounded-full bg-gray-300"></div>
            </div>
          </td>
        </tr>
      ))}
    </>
  )
}
