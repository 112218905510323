import React from 'react'

export default function Tooltip({ text, children }) {
  return (
    <div className="group relative">
      <div
        role="tooltip"
        className="invisible absolute -left-2 top-1/2 z-20 inline-block -translate-x-full -translate-y-1/2 whitespace-nowrap rounded-lg border bg-gray-50 px-3 py-2 text-xs font-medium text-gray-600 opacity-100 shadow transition-opacity duration-300 group-hover:visible"
      >
        <div>{text}</div>
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
      {children}
    </div>
  )
}
