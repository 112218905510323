import { forceLogout, updateToken } from '../contexts/AuthContext'
import { toast } from 'react-hot-toast'

export const responseInterceptors = [
  function (response) {
    if (response.headers.authorization) {
      updateToken(response.headers.authorization)
    }

    return response
  },
  function (error) {
    if (error.response.status === 401) {
      forceLogout()
    }

    if (error.response.status >= 500) {
      toast.error('Something went wrong! Refresh the page and try again.')
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('not ok', { error })
    return Promise.reject(error)
  }
]

export const requestInterceptors = [
  function (config) {
    config.headers['Authorization'] = localStorage.getItem('@App:token')
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
]
