import React, { useEffect } from 'react'

export default function SubmitButton({ className, text, states, loadingText, isLoading, ...rest }) {
  useEffect(() => {}, [states])

  const stateLoading = isLoading || states.isSubmitting

  const loadingClasses = stateLoading ? 'bg-gray-300 hover:bg-gray-300 animate-pulse' : ''

  return (
    <>
      <button
        disabled={stateLoading}
        type="submit"
        className={`flex w-full justify-center rounded-md border border-transparent bg-chill-600 px-4 py-3 font-bold text-white shadow-sm hover:bg-chill-700 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2 ${loadingClasses} ${className}`}
      >
        {stateLoading ? loadingText || 'Loading...' : text}
      </button>
    </>
  )
}
