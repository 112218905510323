import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const bureauApi = axios.create({
  baseURL: process.env.REACT_APP_BUREAU_URL
})

bureauApi.interceptors.response.use(...responseInterceptors)
bureauApi.interceptors.request.use(...requestInterceptors)

export default bureauApi
