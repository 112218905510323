import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/Button/Button'
import Dropzone from '../../../components/@ui/Dropzone'

export default function NewPaymentUpload({ isOpen, setIsOpen }) {
  const { t } = useTranslation()
  const [files, setFiles] = useState([])
  const navigate = useNavigate()
  const disabled = files.length === 0

  const closeHandle = () => {
    setIsOpen(false)
  }

  const uploadHandle = async () => {
    const formData = new FormData()

    files.forEach(({ file }, index) => {
      formData.append(`file-${index}`, file, file.name)
    })

    // await fetch('/v1/payments/import-files', {
    //   method: 'POST',
    //   body: formData
    // })

    navigate('/customs/confirm-upload-payments')
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <Dialog.Content maxWidth="480px">
        <Dialog.Title mb="0">{t('NewPaymentUploadDialogTitle')}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          {t('NewPaymentUploadDialogDescription')}
        </Dialog.Description>
        <Dropzone accept={['.csv']} onChange={setFiles} />
        <div className="flex gap-3 mt-8">
          <Button className="w-full" size="xl" variant="white" onClick={closeHandle}>
            {t('NewPaymentUploadDialogButtonClose')}
          </Button>
          <Button className="w-full" size="xl" disabled={disabled} onClick={uploadHandle}>
            {t('NewPaymentUploadDialogButtonSubmit')}
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
