import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { classNames } from '../../../helpers/dom'
import { useTranslation } from 'react-i18next'

const OTPElement = React.forwardRef(({ size, onChange, apiResponse }, ref) => {
  const [inputValue, setInputValue] = useState('')
  const testRegex = new RegExp(`\\d{${size}}$`)
  const submitTrigger = useRef()
  const isError = apiResponse === 'otp_required'
  const { t } = useTranslation()

  useEffect(() => {
    if (apiResponse.length > 0) {
      reset()
    }
  }, [apiResponse])

  useEffect(() => {
    onChange(inputValue)

    if (inputValue.length == size) {
      submitTrigger.current.click()
    }
  }, [inputValue])

  const inputs = Array(parseInt(size))
    .fill('_')
    .map(() => React.createRef())

  const getValue = () => {
    return inputs.map((i) => i.current.value).join('')
  }

  const setValue = (value) => {
    if (!testRegex.test(value)) {
      return
    }

    value.split('').forEach((v, i) => {
      inputs[i].current.value = v
    })

    inputs[inputs.length - 1].current.focus()

    handleChange()
  }

  const reset = () => {
    inputs.forEach((i) => {
      i.current.value = ''
    })

    inputs[0].current.focus()
  }

  const handleKey = (event, index) => {
    const key = event.key
    const input = inputs[index].current

    if (key == 'Backspace') {
      input.value = ''

      if (index >= 1) {
        inputs[index - 1].current.focus()
      }
      event.preventDefault()
    }

    if (parseInt(key) >= 0) {
      input.value = key
      index < size - 1 && inputs[index + 1].current.focus()
      event.preventDefault()
    }

    if (!event.metaKey) {
      event.preventDefault()
    }

    handleChange()
  }

  const handlePaste = (event, index) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')

    if (testRegex.test(pastedData)) {
      setValue(pastedData)
    }

    event.preventDefault()
  }

  const handleChange = () => {
    const value = getValue()
    setInputValue(value)
  }

  useEffect(() => {
    inputs[0].current.focus()
  }, [])

  return (
    <div className=" my-10">
      <div className="flex space-x-2">
        {inputs.map((ref, key) => (
          <input
            key={`otp-input-${key}`}
            ref={ref}
            className={classNames(
              'w-full rounded-md border p-1 text-center text-4xl',
              isError ? ' border-red-200 text-red-600' : 'text-gray-600'
            )}
            maxLength={1}
            onKeyDown={(e) => handleKey(e, key)}
            onPaste={(e) => handlePaste(e, key)}
          />
        ))}

        <input type="submit" value="Submit OTP" className="hidden" ref={submitTrigger} />
      </div>
      <div className="mt-4 text-center text-sm text-red-600">{isError && <p>{t('OTPInputMessage')}</p>}</div>
    </div>
  )
})

export default function OTPInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={(props) => <OTPElement {...props.field} {...rest} />}
    />
  )
}
