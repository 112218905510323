import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import i18n from 'i18next'

export function FormatDate({ date, options, locale }) {
  const parsedDate = Date.parse(date)
  const defaultOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }

  const formatedDate = new Date(parsedDate).toLocaleDateString(locale, {
    ...defaultOptions,
    ...options
  })

  return <span>{formatedDate}</span>
}

export function FormatTime({ date, options, locale }) {
  const formatedTime = new Date(Date.parse(date)).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })

  return <span>{formatedTime}</span>
}

export const CustomReactTimeAgo = ({ date }) => {
  return <ReactTimeAgo date={date} locale={i18n.language} />
}
