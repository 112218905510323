import React from 'react'
import Main from '../../../components/Main/Main'
import PageHeader from '../../../components/Pages/PageHeader'
import { useTranslation } from 'react-i18next'
import ListMerchants from '../../Customs/Merchants/ListMerchants'
import { BanknotesIcon, CreditCardIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import StatsPill from '../../../components/StatsPill/StatsPill'
import { FormattedCurrency, FormattedNumber } from '../../../components/TransactionHelpers'
import { CustomReactTimeAgo } from '../../../components/DateTime/FormatDate'
import { Flex } from '@radix-ui/themes'

const statsMapping = [
  {
    key: 'valor',
    title: 'Saldo total',
    icon: <CreditCardIcon />,
    render: () => <FormattedCurrency amount="15000000" currency="BRL" />
  },
  {
    key: 'volume',
    title: 'Rendimento mensal',
    icon: <BanknotesIcon />,
    render: () => <FormattedNumber amount="120000" />
  },
  {
    key: 'comissao',
    title: 'Pagamentos últimos 30 dias',
    icon: <FaceSmileIcon />,
    render: () => <FormattedCurrency amount="8500000" currency="BRL" />
  }
]

export default function CustomsDashboard() {
  const { t } = useTranslation()
  return (
    <div>
      <div className="mb-10">
        <PageHeader
          title="Grupo Pinho"
          subtitle={
            <span>
              {t('DashboardMainLastUpdated')} <CustomReactTimeAgo date={Date.now()} />
            </span>
          }
        />
        <Main>
          <Flex className="mt-5 flex-col md:flex-row" gap="5">
            {statsMapping.map((stats) => (
              <StatsPill
                key={stats.key}
                title={stats.title}
                icon={stats.icon}
                value={stats.render(0)}
                className="w-full"
              />
            ))}
          </Flex>
        </Main>
      </div>
      <ListMerchants />
    </div>
  )
}
