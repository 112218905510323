import React from 'react'
import { DropdownMenu, Grid, Button } from '@radix-ui/themes'

export default function DropdownButton({ children, menuContent }) {
  return (
    <Grid gap="3" display="inline-grid">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="bg-orange-600 h-full">
          <Button color="gray">
            {children}
            <DropdownMenu.TriggerIcon />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content color="gray">
          {menuContent.map(({ onClick, label }, index) => (
            <DropdownMenu.Item key={index} onClick={onClick}>
              {label}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </Grid>
  )
}
