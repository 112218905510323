import React from 'react'
import { TextField } from '@radix-ui/themes'

export default function TextInput({ slot, ...inputProps }) {
  return (
    <TextField.Root placeholder="Search" className="w-full max-w-[870px] h-8" {...inputProps}>
      {Boolean(slot) && <TextField.Slot>{slot}</TextField.Slot>}
    </TextField.Root>
  )
}
