import React from 'react'

function getUserInitials(fullname) {
  return ((fullname) =>
    fullname
      .map((n, i) => (i == 0 || i == fullname.length - 1) && n[0])
      .filter((n) => n)
      .join(''))(fullname.split(' '))
}

function stringToColor(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 4) - hash)
  }
  var colour = '#'
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 12)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export default function UserAvatar({ user, size, className }) {
  const sizes = {
    xs: 'text-xs w-6 h-6',
    sm: 'text-lg w-10 h-10',
    md: 'text-xl w-14 h-14',
    lg: 'text-3xl w-16 h-16',
    xl: 'text-5xl w-20 h-20'
  }

  return (
    <span
      className={`${sizes[size]} inline-flex items-center justify-center rounded bg-secondary-200 text-secondary-800 ${
        className || ''
      }`}
    >
      <span className={`font-semibold leading-none`}>{getUserInitials(user.name)}</span>
    </span>
  )
}
