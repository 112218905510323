import React from 'react'

export default function FormSection({ name, description }) {
  return (
    <div className="bg-gray-50 px-6 py-8">
      <div className="text-xl font-medium">{name}</div>
      {description && <p className="mt-1 text-sm text-gray-400">{description}</p>}
    </div>
  )
}
