import React, { useState, useRef } from 'react'
import { Button, Dialog, Flex, Text } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const SMSConfirmation = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [values, setValues] = useState(Array(4).fill(''))

  const inputRefs = useRef([])
  inputRefs.current = Array(4)
    .fill()
    .map((_, i) => inputRefs.current[i] ?? React.createRef())

  const handleChange = (value, index) => {
    const newValues = [...values]
    newValues[index] = value
    setValues(newValues)

    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].current.focus()
    }
  }

  const handleSubmit = () => {
    if (values.every((value) => value.length === 1)) {
      navigate('/customs/pay-boleto/success')
    } else {
      alert('Por favor, preencha todos os campos.')
    }
  }

  const phoneNumber = '985948532'

  function formatPhoneNumber(phoneNumber) {
    return (
      phoneNumber.substring(0, 2) + '•'.repeat(phoneNumber.length - 4) + phoneNumber.substring(phoneNumber.length - 2)
    )
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <Dialog.Content maxWidth="408px">
        <Dialog.Title className="text-lg font-bold text-center">{t('SMSConfirmationTitle')}</Dialog.Title>
        <Dialog.Description className="text-center" color="gray">
          {t('SMSConfirmationSubtitle')}
          <b>{` ${formatPhoneNumber(phoneNumber)}`}</b>
        </Dialog.Description>
        <Flex gap="3" justify="between" mt="4" mb="2" wrap="wrap">
          {values.map((value, index) => (
            <input
              key={index}
              ref={inputRefs.current[index]}
              placeholder="0"
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              maxLength={1}
              className="w-20 h-20 text-5xl text-center border-2 border-gray-300 rounded-lg focus:outline-none text-gray-600 font-semibold caret-transparent"
            />
          ))}
        </Flex>

        <Flex gap="1" align="baseline">
          <Text color="gray" size="2">
            {t('SMSConfirmationResend')}
          </Text>
          <Button variant="ghost" className="underline" color="gray">
            {t('SMSResend')}
          </Button>
        </Flex>
        <Flex justify="between" mt="6" gap="2">
          <Button variant="outline" color="gray" className="w-[48%]" onClick={() => setIsOpen(false)}>
            {t('CancelButton')}
          </Button>
          <Button className="bg-orange-600 hover:bg-orange-800 w-[48%]" onClick={handleSubmit}>
            {t('PayButton')}
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default SMSConfirmation
