import { Switch } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { classNames } from '../../helpers/dom'

const SwitchElement = React.forwardRef(({ value, onChange, label, name, description }, ref) => {
  useEffect(() => {
    onChange(value || false)
  }, [])

  return (
    <Switch.Group
      as="div"
      className="flex items-center justify-between space-y-1 px-4 py-5 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
    >
      <span className="mr-8 flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          {label}
        </Switch.Label>
        <Switch.Description as="span" className="mt-1 text-xs text-gray-400">
          {description}
        </Switch.Description>
      </span>
      <Switch
        name={name}
        value="true"
        checked={value || false}
        onChange={onChange}
        ref={ref}
        className={classNames(
          value ? 'bg-chill-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  )
})

export default function SwitchInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        return <SwitchElement {...props.field} {...rest} />
      }}
      rules={rules}
    />
  )
}
