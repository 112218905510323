import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const BRLMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: true,
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 8, // limit length of integer numbers
  allowNegative: false,
  requireDecimal: false
}

const USDMaskOptions = {
  prefix: '$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  decimalSymbol: '.',
  allowDecimal: true,
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 8, // limit length of integer numbers
  allowNegative: false,
  requireDecimal: false
}

const CurrencyElement = React.forwardRef(({ currency, name, states, onChange }, ref) => {
  const [errorMessage, setErrorMessage] = useState('')
  const isError = !!states?.errors?.[name]

  const currencyMask = createNumberMask(currency === 'USD' ? USDMaskOptions : BRLMaskOptions)

  const handleChange = (event) => {
    const value = event.target.value
    const finalValue =
      currency === 'USD'
        ? value.replaceAll('$ ', '').replaceAll(',', '')
        : value.replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '.')

    onChange(parseFloat(finalValue))
  }

  useEffect(() => {
    setErrorMessage(isError ? states?.errors?.[name]?.message : '')
  }, [isError])

  return (
    <>
      <MaskedInput
        mask={currencyMask}
        className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-chill-500 focus:ring-chill-500 sm:text-sm"
        inputMode="decimal"
        onChange={handleChange}
        placeholder={currency === 'USD' ? '$ 0.00' : 'R$ 0,00'}
      />
      {errorMessage && <div className="mt-2 text-xs text-red-600">{errorMessage}</div>}
    </>
  )
})

export default function CurrencyInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={(props) => (
        <CurrencyElement name={name} states={props.formState} inputRef={props.ref} {...props.field} {...rest} />
      )}
    />
  )
}
