import React from 'react'

export default function MerchantFullAddress({ merchant }) {
  return (
    <div>
      {merchant.address_street}, {merchant.address_number}
      {merchant.address_complement && ` - ${merchant.address_complement}`}
      &nbsp; - {merchant.address_district} -&nbsp;
      {merchant.address_city}, &nbsp;{merchant.address_state}
    </div>
  )
}
