import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import TextInput from '../../../components/@ui/TextInput'
import { Text, Flex, Button } from '@radix-ui/themes'
import ConfirmationPayment from '../../../components/@ui/ConfirmationPayment'
import BoletoDetailsPayment from '../../../components/@ui/BoletoDetailsPayment'
import SMSConfirmation from '../../../components/@ui/SMSConfirmation'

const boletoMock = {
  value: 120056.48,
  bank_name: 'Banco Inter',
  cnpj: '00.416.968/0001-01',
  emissor: 'Banco Inter',
  interest: 100,
  fine: 10,
  discount: 10,
  total_value: 120106.48
}

export default function BoletoPayment() {
  const [boletoNumber, setBoletoNumber] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [hasBoleto, setHasBoleto] = useState(false)
  const { t } = useTranslation()

  const handleInputChange = (event) => {
    setBoletoNumber(event.target.value)
  }

  const onConfirmation = () => {
    setShowConfirmation(false)
    setIsConfirmed(true)
  }

  useEffect(() => {
    if (boletoNumber.length > 0 && !hasBoleto) {
      setTimeout(() => {
        setHasBoleto(true)
      }, 2000)
    }
  }, [boletoNumber])

  // const { data, isLoading, isRefetching } = useQuery({
  //   queryFn: fetchAdminPayments,
  //   meta: { filters: '' },
  //   queryKey: ['fetchAdminPayments']
  // })

  // const { t } = useTranslation()

  return (
    <div>
      <PageHeader title={t('BoletoPaymentTitle')} subtitle={t('BoletoPaymentSubtitle')} />
      <Main className="pt-4">
        <Flex gap="6" direction="column">
          <Flex gap="5" direction="column">
            <Text size="4" weight="bold" as="h2">
              {t('BoletoPaymentSectionCode')}
            </Text>
            <Flex direction="column" gap="1">
              <Text as="label" size="2" weight="medium">
                {t('BoletoPaymentLabelDigitableLine')}
              </Text>
              <TextInput
                value={boletoNumber}
                onChange={handleInputChange}
                placeholder={t('BoletoPaymentPlaceholderEnterCode')}
              />
            </Flex>
          </Flex>
          {hasBoleto && (
            <>
              <div className="max-w-[592px]">
                <BoletoDetailsPayment showBank boleto={boletoMock} />
              </div>
              <Flex justify="end" mt="8" pb="4">
                <Button size="3" className="bg-orange-600" onClick={() => setShowConfirmation(true)}>
                  {t('BoletoPaymentButtonConfirm')}
                </Button>
              </Flex>
            </>
          )}
          {boletoNumber.length > 0 && !hasBoleto && <p>carregando...</p>}
        </Flex>
      </Main>
      <Outlet />
      <ConfirmationPayment isOpen={showConfirmation} setIsOpen={setShowConfirmation} onProceed={onConfirmation}>
        <BoletoDetailsPayment boleto={boletoMock} />
      </ConfirmationPayment>
      <SMSConfirmation isOpen={isConfirmed} setIsOpen={setIsConfirmed} />
    </div>
  )
}
