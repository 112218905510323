import React from 'react'

export default function FileIcon(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.667 1.891v3.442c0 .467 0 .7.09.879.08.156.208.284.365.364.178.09.411.09.878.09h3.442m.225 1.658v6.01c0 1.4 0 2.1-.273 2.634a2.5 2.5 0 01-1.092 1.093c-.535.272-1.235.272-2.635.272H7.333c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 01-1.092-1.093c-.273-.535-.273-1.235-.273-2.635V5.667c0-1.4 0-2.1.273-2.635a2.5 2.5 0 011.092-1.093c.535-.272 1.235-.272 2.635-.272h2.677c.611 0 .917 0 1.205.069a2.5 2.5 0 01.722.3c.253.154.469.37.901.802l2.657 2.657c.433.432.649.649.803.901.137.224.238.468.3.723.069.287.069.593.069 1.205z"
        stroke="#98A2B3"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
