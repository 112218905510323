import React, { useEffect, useRef, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { uploadToS3 } from '../../../services/aws'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const FileInputElement = React.forwardRef(({ name, states, onChange, ...props }, ref) => {
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [file, setFile] = useState({})
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const isError = Boolean(states?.errors?.[name])
  const { t } = useTranslation()

  useEffect(() => {
    setErrorMessage(isError ? states?.errors?.[name]?.message : '')
  }, [isError])

  const fileInput = useRef()

  const onProgress = (progress) => {
    setIsUploading(true)
    setProgress(progress)
  }

  const onUpload = (data) => {
    setIsUploading(false)
    setIsUploaded(true)
    setData(data)
    onChange(data.Location)
  }

  const handleChange = (event) => {
    console.log('event', event)
    if (event.target.value !== '') {
      setFile(event.target.files[0])
      uploadToS3(event.target.files[0], onProgress, onUpload)
    }
  }

  const resetUpload = () => {
    setIsUploading(false)
    setIsUploaded(false)
    onChange(null)
    setData({})
  }

  return (
    <div>
      {isUploading && (
        <div>
          <div className="h-2 bg-gray-100">
            <div className="h-2 bg-chill-500" style={{ width: `${progress}%` }}></div>
          </div>
          <p className="text-xs">{t('FileInputUploading')}</p>
        </div>
      )}
      {isUploaded && (
        <div className="flex">
          <div className="grow">
            <a href={data.Location} target="_blank" className="cursor-pointer text-sm font-medium text-chill-500">
              {file.name}
            </a>
            <p className="text-xs text-gray-400">{t('FileInputSuccessfully')}</p>
          </div>
          <XMarkIcon className="h-4 cursor-pointer justify-self-end" onClick={resetUpload} />
        </div>
      )}

      {!isUploading && !isUploaded && (
        <div>
          <label className="inline-block w-full cursor-pointer py-2 text-sm font-medium text-chill-500 hover:text-chill-600">
            {t('PaymentsSidePanelButtonPayButtonUpload')}
            <input type="file" className="hidden" ref={fileInput} onChange={handleChange} />
          </label>
        </div>
      )}
      {errorMessage && <div className="mt-2 text-xs text-red-600">{errorMessage}</div>}
    </div>
  )
})

export default function FileInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        return <FileInputElement name={name} states={props.formState} {...props.field} {...rest} />
      }}
      rules={rules}
    />
  )
}
