import { useState, useRef } from 'react'

/**
 * This function is designed to read and manipulate the component state asynchronously,
 * making it ideal for components that handle multiple asynchronous events and require
 * access to the current component state before taking any action.
 */
export default function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue)
  const valueRef = useRef(value)

  const getter = () => valueRef.current

  const setter = (newValue) => {
    setValue(newValue)
    valueRef.current = newValue
  }

  return [getter, setter]
}
