import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchAdminPayment, setPaymentApproved, setPaymentPaid } from '../../../services/ledgerApi'
import PageHeader from '../../../components/Pages/PageHeader'
import Button from '../../../components/Button/Button'
import Main from '../../../components/Main/Main'
import MerchantFullAddress from '../../../components/Merchant/MerchantFullAddress'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import ConfirmBox from '../../../components/ConfirmBox/ConfirmBox'
import { toast } from 'react-hot-toast'
import PaymentStatus from '../../../components/Payment/PaymentStatus'
import { FormatDate } from '../../../components/DateTime/FormatDate'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import ConfirmApprove from '../../../components/Payments/ConfirmApprove'
import { ReceiptCutoff } from 'react-bootstrap-icons'
import PaymentProcessor from '../../../components/Payment/PaymentProcessor'
import PaymentTransaction from '../../../components/Payment/PaymentTransaction'
import { useTranslation } from 'react-i18next'

export default function ShowPayment() {
  const { paymentId } = useParams()
  const { t } = useTranslation()
  const [openApprove, setOpenApprove] = useState(false)
  const [openSettle, setOpenSettle] = useState(false)
  const approvePayment = useMutation({
    mutationFn: setPaymentApproved,
    onSuccess: () => {
      setOpenApprove(false)
      refetch()
      toast.success(t('ShowPaymentSuccessMensage'))
    }
  })
  const settlePayment = useMutation({
    mutationFn: setPaymentPaid,
    onSuccess: () => {
      setOpenSettle(false)
      refetch()
      toast.success(t('ShowPaymentSettledMensage'))
    }
  })

  const { data, isLoading, isError, isRefetching, refetch } = useQuery({
    queryFn: fetchAdminPayment,
    queryKey: ['fetchPayment', { paymentId }],
    refetchOnWindowFocus: false
  })

  const onApprove = (formData) => {
    approvePayment.mutate({
      paymentId: data.id,
      processorId: formData.processor_id
    })
  }

  const onSettle = (event) => {
    settlePayment.mutate({ paymentId: data.id })
  }

  return (
    <div>
      <PageHeader
        title={<>{t('ShowPaymentPaymentApproval')}</>}
        subtitle={<>{!isLoading && <>{data.id.toUpperCase()}</>}</>}
        button={
          <>
            {!isLoading && <PaymentStatus size="md" paymentStatus={data.payment_status} />}
            {!isLoading && data?.payment_status === 'Paid' && (
              <Button variant="primary">
                <ReceiptCutoff className="mr-2" /> {t('ShowPaymentReceipt')}
              </Button>
            )}
          </>
        }
      />

      <Main className="pb-6 mt-4">
        <div className="grid grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-span-3 lg:col-start-1">
            {(isLoading || isRefetching) && !isError ? (
              'Loading...'
            ) : (
              <>
                <ConfirmApprove open={openApprove} setOpen={setOpenApprove} onConfirm={onApprove} payment={data} />

                <ConfirmBox
                  open={openSettle}
                  setOpen={setOpenSettle}
                  onConfirm={onSettle}
                  title={t('ShowPaymentConfirmBoxTitle')}
                >
                  {t('ShowPaymentThisAction')} <strong className="text-red-600">{t('ShowPaymentReversible')}</strong>,
                  {t('ShowPaymentTransferred')} {data.merchant.name} {t('ShowPaymentBankAccount')}
                  <br />
                  <br />
                  {t('ShowPaymentClickOn')}&nbsp;
                  <strong>{t('ShowPaymentApprove')}</strong> {t('ShowPaymentButtonBelow')}
                </ConfirmBox>
                <div className="grid grid-cols-2">
                  <div>
                    <h1 className="mb-2 text-lg font-semibold">{t('ShowPaymentFrom')}</h1>
                    <ul className="space-y-[2px] text-sm">
                      <li className="text-base">{data.merchant.name}</li>
                      <li>{data.merchant.document}</li>
                      <li>
                        <MerchantFullAddress merchant={data.merchant} />
                      </li>
                      <li>{data.merchant.phone}</li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="mb-2 text-lg font-semibold">To:</h1>
                    <ul className="space-y-[2px] text-sm">
                      <li className="text-base">{data.vendor.name}</li>
                      <li>{data.vendor.document}</li>
                      <li>
                        <MerchantFullAddress merchant={data.vendor} />
                      </li>
                      <li>{data.vendor.phone}</li>
                    </ul>
                  </div>
                </div>

                <h2 className="pt-3 text-xl font-semibold">{t('ShowPaymentInformation')}</h2>

                <div className="p-4 text-sm rounded bg-gray-50">
                  <div className="space-y-3 divide-y divide-gray-100">
                    <div className="flex">
                      <div className="font-semibold grow">{t('ShowPaymentStatus')}</div>
                      <div>
                        <PaymentStatus paymentStatus={data.payment_status} />
                      </div>
                    </div>
                    {data.processor_id && (
                      <div className="flex pt-3">
                        <div className="font-semibold grow">{t('ShowPaymentProcessor')}</div>
                        <div>
                          <PaymentProcessor processor_id={data.processor_id} />
                        </div>
                      </div>
                    )}

                    <div className="flex pt-3">
                      <div className="font-semibold grow">{t('ShowPaymentPIXKeyType')}</div>
                      <div>{data.vendor.pix_type}</div>
                    </div>
                    <div className="flex pt-3">
                      <div className="font-semibold grow">{t('ShowPaymentPIXKey')}</div>
                      <div>{data.vendor.pix_key}</div>
                    </div>
                    <div className="flex pt-3">
                      <div className="font-semibold grow">{t('ShowPaymentPayBy')}</div>
                      <div>
                        <FormatDate date={data.due_at} />
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="pt-3 text-xl font-semibold">{t('ShowPaymentInvoice')}</h2>

                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500"></dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul role="list" className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex items-center flex-1 w-0">
                          <PaperClipIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                          <span className="flex-1 w-0 ml-2 truncate">{data.invoice_url.split('/').pop()}</span>
                        </div>
                        <div className="flex-shrink-0 ml-4">
                          <a
                            target="_blank"
                            href={data.invoice_url}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            {t('ShowPaymentDownload')}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </div>

                <h2 className="pt-3 text-xl font-semibold">{t('ShowPaymentSummary')}</h2>

                <div className="p-4 rounded bg-gray-50">
                  <div className="space-y-3 divide-y divide-gray-200">
                    <div className="flex">
                      <div className="text-sm font-semibold grow">{t('ShowPaymentTotalAmount')}</div>
                      <div>
                        <FormattedCurrency amount={data.amount} currency="BRL" />
                      </div>
                    </div>
                  </div>
                </div>

                {data.payment_status === 'PAID' && <PaymentTransaction transactionId={data.transaction_id} />}

                <div className="flex pt-6 mt-2 space-x-2 border-t">
                  {['WAITING_SETTLEMENT', 'PAID', 'REVIEW'].includes(data.payment_status) ? (
                    <>
                      {data.payment_status == 'PAID' ? (
                        <>
                          <Button variant="primary">
                            <ReceiptCutoff className="mr-2" /> {t('ShowPaymentReceipt')}
                          </Button>
                        </>
                      ) : (
                        <Button
                          disabled={['PAID', 'REVIEW'].includes(data.payment_status)}
                          variant={['PAID', 'REVIEW'].includes(data.payment_status) ? 'fail' : 'success'}
                          onClick={() => setOpenSettle(true)}
                        >
                          {t('ShowPaymentButtonSettle')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button variant="success" onClick={() => setOpenApprove(true)}>
                        {t('ShowPaymentButtonApprove')}
                      </Button>
                      <Button variant="fail" disabled>
                        {t('ShowPaymentButtonReject')}
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Main>
    </div>
  )
}
