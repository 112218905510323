import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SidePanel from '../../components/SidePanel/SidePanel'
import SidePanelBody from '../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../components/SidePanel/SidePanelHeader'
import authenticatorApi from '../../services/authenticatorApi'
import { useUsers } from '../../components/UsersProvider/UsersProvider'
import Button from '../../components/Button/Button'
import UserForm from './UserForm'
import { stripNonNumbers } from '../../helpers/utils'

export default function NewUser() {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { reload: reloadUsers } = useUsers()
  const submitRef = useRef()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onSubmit = async (data) => {
    setIsLoading(true)

    const refinedData = {
      ...data,
      mobile_phone: `+${stripNonNumbers(data.mobile_phone)}`
    }

    await authenticatorApi.post('/app/merchant/users', { user: refinedData })
    reloadUsers()
    setIsLoading(false)
    setOpen(false)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <SidePanel open={open} setOpen={setOpen} onClose={() => navigate('/merchant/users')}>
      <div>
        <SidePanelHeader
          onClose={() => setOpen(false)}
          title={t('UsersSidePainelTitle')}
          subtitle={t('UsersSidePainelSubtitle')}
        />
        <SidePanelBody>
          <UserForm data={data} setData={setData} onSubmit={onSubmit} submitRef={submitRef} />
        </SidePanelBody>
        <SidePanelFooter>
          <Button type="button" variant="simple" disabled={isLoading} onClick={() => setOpen(false)}>
            {t('VendorsSidePainelButtonCancel')}
          </Button>
          <Button type="submit" variant="primary" isLoading={isLoading} onClick={() => submitRef.current.click()}>
            {t('VendorsSidePainelButtonSave')}
          </Button>
        </SidePanelFooter>
      </div>
    </SidePanel>
  )
}
