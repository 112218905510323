import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/Pages/PageHeader'
import Main from '../../../components/Main/Main'
import Table from '../../../components/Table/Table'
import Button from '../../../components/Button/Button'
import { Link, useSearchParams } from 'react-router-dom'
import bureauApi from '../../../services/bureauApi'
import CustomerName from '../../../components/Customer/CustomerName'
import CustomerRisk from '../../../components/Customer/CustomerRisk'
import ToggleInput from '../../../components/Form/Inputs/ToggleInput'
import EmptyState from '../../../components/EmptyState/EmptyState'
import { IdentificationIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

export default function ComplianceIndex() {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('ComplianceIndexCustomer'),
      render: (customer) => (
        <Link to={`/admin/bureau/${customer.id}`}>
          <CustomerName customer={customer} />
        </Link>
      )
    },
    {
      title: t('ComplianceIndexRisk'),
      render: (customer) => <CustomerRisk customer={customer} />
    },
    {
      title: t('ComplianceIndexTransactions')
    },
    {
      title: '',
      render: (customer) => (
        <Button size="xs" as={<Link to={`/admin/bureau/${customer.id}`} />}>
          {t('ComplianceIndexButtonView')}
        </Button>
      )
    }
  ]

  const [isLoading, setIsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const showAll = searchParams.get('all') === 'true'

  const [data, setData] = useState({
    customers: {}
  })

  const toggleCustomers = (isChecked) => {
    setSearchParams({ all: isChecked })
  }

  useEffect(() => {
    const url = showAll ? '/app/admin/customers/all' : '/app/admin/customers'

    bureauApi
      .get(url)
      .then((response) => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch((e) => console.log('e', e))
  }, [showAll])

  return (
    <div>
      <PageHeader
        title={t('ComplianceIndexTitle')}
        subtitle={t('ComplianceIndexSubTitle')}
        button={
          <ToggleInput
            optionLeft={t('AdminConformityButton')}
            optionRight={t('AdminConformityButtonAll')}
            onChange={toggleCustomers}
          />
        }
      />

      <Main className="pt-4">
        <Table
          isLoading={isLoading}
          columns={columns}
          rows={data.customers}
          emptyState={
            <EmptyState
              icon={<IdentificationIcon />}
              title={showAll ? t('AdminConformityText') : t('AdminConformityTextNo')}
              description={showAll ? t('ComplianceIndexShowAllCustomers') : t('ComplianceIndexShowAllCongratulations')}
            />
          }
        />
      </Main>
    </div>
  )
}
