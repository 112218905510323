import React from 'react'
import clsx from 'clsx'
import { Table as TableBase } from '@radix-ui/themes'

const tableCellClassName = 'shadow-[inset_0_-1px] shadow-gray-200'

function Root({ children, className, ...props }) {
  return (
    <TableBase.Root
      variant="surface"
      className={clsx('overflow-hidden rounded-xl border border-gray-200', className)}
      {...props}
    >
      {children}
    </TableBase.Root>
  )
}

function Header({ children, className, ...props }) {
  return (
    <TableBase.Header className={clsx('border-b border-gray-200', className)} {...props}>
      {children}
    </TableBase.Header>
  )
}

function Body({ children, ...props }) {
  return <TableBase.Body {...props}>{children}</TableBase.Body>
}

function Row({ children, className, ...props }) {
  return (
    <TableBase.Row className={clsx('*:last:shadow-none', className)} {...props}>
      {children}
    </TableBase.Row>
  )
}

function ColumnHeaderCell({ children, className, ...props }) {
  return (
    <TableBase.ColumnHeaderCell
      px="5"
      className={clsx('leading-5 text-slate-600 text-xs font-medium bg-gray-50', tableCellClassName, className)}
      {...props}
    >
      {children}
    </TableBase.ColumnHeaderCell>
  )
}

function RowHeaderCell({ children, className, ...props }) {
  return (
    <TableBase.RowHeaderCell
      px="5"
      py="4"
      className={clsx('text-gray-900 text-sm font-medium', tableCellClassName, className)}
      {...props}
    >
      {children}
    </TableBase.RowHeaderCell>
  )
}

function Cell({ children, className, ...props }) {
  return (
    <TableBase.Cell
      px="5"
      py="4"
      className={clsx('text-slate-600 text-sm font-normal', tableCellClassName, className)}
      {...props}
    >
      {children}
    </TableBase.Cell>
  )
}

const Table = {
  Root,
  Header,
  Body,
  Row,
  ColumnHeaderCell,
  RowHeaderCell,
  Cell
}

export default Table
