import React, { useId } from 'react'
import clsx from 'clsx'

export default function RadioGroup({ label, value, onChange, name, description, options, disableHorizontalSpacing }) {
  return (
    <div
      className={clsx('grid gap-2 sm:gap-4 sm:space-y-0', {
        'space-y-1 px-4 sm:px-6': !disableHorizontalSpacing,
        'space-y-1': disableHorizontalSpacing
      })}
    >
      {Boolean(label) && <label className="text-base font-semibold text-gray-900">{label}</label>}
      {Boolean(description) && <p className="text-sm text-gray-500">{description}</p>}
      <fieldset>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-5 sm:space-y-0">
          {options.map(({ label, value: optionValue, disabled }) => {
            const id = useId()
            return (
              <div
                key={id}
                className={clsx('flex items-center', {
                  'opacity-50': disabled
                })}
              >
                <input
                  id={id}
                  name={name}
                  type="radio"
                  checked={value === optionValue}
                  onChange={() => onChange(optionValue)}
                  disabled={disabled}
                  className="h-4 w-4 border-gray-300 text-chill-600 focus:ring-chill-600"
                />
                <label htmlFor={id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                  {label}
                </label>
              </div>
            )
          })}
        </div>
      </fieldset>
    </div>
  )
}
