import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, useMemo } from 'react'
import { PersonSquare } from 'react-bootstrap-icons'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { classNames } from '../../helpers/dom'
import authenticatorApi from '../../services/authenticatorApi'
import SidebarMobile from './SidebarMobile'
import { useTranslation } from 'react-i18next'

export default function Sidebar({ sidebarOpen, setSidebarOpen, navigation, secondaryNavigation }) {
  const isMenuActive = () => false
  const auth = useAuth()
  const impersonated = auth.isImpersonated()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const leaveImpersonation = (event) => {
    event.preventDefault()

    try {
      zE('messenger', 'logoutUser')
      zE('messenger', 'hide')
    } catch (error) {}

    authenticatorApi
      .delete(`/app/merchant/users/${auth.user.id}/impersonate`)
      .then((response) => {
        auth.UpdateCurrentUser(response.data.token)
        navigate('/admin')
      })
      .catch((error) => console.log('error', error))
  }

  return (
    <>
      <SidebarMobile
        navigation={navigation}
        secondaryNavigation={secondaryNavigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        leaveImpersonation={leaveImpersonation}
        impersonated={impersonated}
      />

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-chill-800 pb-4 pt-5">
          <div className="flex flex-shrink-0 items-center px-4 py-4">
            <img className="h-12 w-auto" src="/paag-white.png" alt="Paag logo - White version" />
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col divide-y divide-chill-900 divide-opacity-40 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="space-y-1 px-2">
              {navigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'bg-black bg-opacity-30 text-white backdrop-blur-md'
                        : 'text-gray-300 hover:bg-black hover:bg-opacity-20 hover:text-white',
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6'
                    )
                  }
                  aria-current={isMenuActive(item.href) ? 'page' : undefined}
                >
                  <item.icon className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="mt-6 pt-6">
              <div className="space-y-1 px-2">
                {secondaryNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-gray-300 hover:bg-black hover:bg-opacity-20 hover:text-white"
                  >
                    <item.icon className="mr-4 h-6 w-6" aria-hidden="true" />
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
          {impersonated && (
            <div className="p-4 text-white">
              <a
                onClick={leaveImpersonation}
                className={classNames(
                  'text-gray-300 hover:bg-black hover:bg-opacity-20 hover:text-white',
                  'group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium leading-6'
                )}
              >
                <PersonSquare className="mr-4 h-6 w-6 flex-shrink-0" aria-hidden="true" />
                {t('AdminMerchantUsersSidebar')}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
