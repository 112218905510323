import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FieldSet from '../../../components/Form/FieldSet'
import Form from '../../../components/Form/Form'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import { toast } from 'react-hot-toast'
import ledgerApi from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'

export default function NewMerchant({ open, setOpen }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { merchantId } = useParams()
  const submitRef = useRef()

  const onSubmit = (data) => {
    ledgerApi
      .post('/v1/admin/merchants', data)
      .then((response) => {
        toast.success(
          <>
            {t('NewMerchant')}&nbsp;
            <span className="font-bold">{response.data.merchant.name}</span> {t('NewMerchantWas')}
            {t('NewMerchantSuccessfulCreated')}
          </>
        )
        setOpen(false)
      })
      .catch((e) => console.log('e', e))
  }
  const onError = (error) => console.log('error', error)

  return (
    <div>
      <SidePanel
        open={open}
        setOpen={setOpen}
        onClose={() =>
          navigate(`/customs/dashboard`, {
            state: { reload: true }
          })
        }
      >
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title={t('NewMerchantCreateMerchant')} onError={onError} />
          <SidePanelBody>
            <Form
              onSubmit={onSubmit}
              submitRef={submitRef}
              className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
              onError={onError}
            >
              <FieldSet name="name" label={t('NewMerchantName')} rules={{ required: t('NewMerchantErrorEmpty') }} />
              <FieldSet name="status" label={t('NewMerchantStatus')} rules={{ required: t('NewMerchantErrorEmpty') }} />
              <FieldSet
                name="document"
                label={t('NewMerchantDocument')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet name="phone" label={t('NewMerchantPhone')} rules={{ required: t('NewMerchantErrorEmpty') }} />
              <FieldSet
                name="address_zip"
                label={t('NewMerchantZip')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_street"
                label={t('NewMerchantStreet')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_district"
                label={t('NewMerchantDistrict')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_number"
                label={t('NewMerchantNumber')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_complement"
                label={t('NewMerchantComplement')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_city"
                label={t('NewMerchantCity')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
              <FieldSet
                name="address_state"
                label={t('NewMerchantState')}
                rules={{ required: t('NewMerchantErrorEmpty') }}
              />
            </Form>
          </SidePanelBody>
          <SidePanelFooter>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              {t('NewMerchantButton')}
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-chill-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-chill-700 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => {
                submitRef.current.click()
              }}
            >
              {t('NewMerchantButtonSave')}
            </button>
          </SidePanelFooter>
        </div>
      </SidePanel>
    </div>
  )
}
