import React from 'react'

export default function CircleCheckIcon(props) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 8a8 8 0 1116 0A8 8 0 010 8z" fill="#DCFAE6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.398 4.927L6.624 9.533 5.358 8.18a.692.692 0 00-.867-.047.611.611 0 00-.173.807l1.5 2.44a.81.81 0 00.686.367c.274 0 .534-.14.68-.367.24-.313 4.82-5.773 4.82-5.773.6-.614-.127-1.154-.606-.687v.007z"
        fill="#079455"
      />
    </svg>
  )
}
