import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const helpdeskApi = axios.create({
  baseURL: process.env.REACT_APP_HELPDESK_URL
})

helpdeskApi.interceptors.response.use(...responseInterceptors)
helpdeskApi.interceptors.request.use(...requestInterceptors)

export const identifyZendesk = async () => {
  return helpdeskApi.post(`/zendesk/identify`).then((response) => response.data)
}

export default helpdeskApi
