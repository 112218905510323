import React from 'react'
import { BuildingOfficeIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

export default function UserMerchant({ data, error }) {
  const { t } = useTranslation()

  if (error) {
    return t('UserMerchantError')
  }

  return (
    <div className="flex items-center">
      <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
      {data?.merchant?.name || t('loadingText')}
    </div>
  )
}
