import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { fetchTransaction } from '../../services/gatewayApi'
import { useAuth } from '../../contexts/AuthContext'
import { TransactionAmount } from '../TransactionHelpers'
import { TransactionStatus } from '../TransactionHelpers'
import BrazilBanks from '../../data/BrazilBanks.json'
import { useTranslation } from 'react-i18next'

export default function PaymentTransaction({ transactionId }) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data, isLoading } = useQuery({
    queryFn: fetchTransaction,
    queryKey: [
      'fetchTransaction',
      {
        userRole: user.role,
        transactionId
      }
    ]
  })
  const bank = BrazilBanks.filter((bank) => data?.transaction?.statement?.payer?.ispb == bank.ISPB).at(0) || {}

  return (
    <div className="space-y-6">
      <h2 className="pt-3 text-xl font-semibold">{t('PaymentTransactionInformation')}</h2>

      <div className="rounded bg-gray-50 p-4">
        <div className="space-y-3 divide-y divide-gray-200">
          <div className="flex text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionIdenitfier')}</div>
            <div>{transactionId.toUpperCase()}</div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionStatus')}</div>
            <div>
              {isLoading ? (
                t('loadingText')
              ) : (
                <TransactionStatus transaction={data.transaction} extendedFormat={true} />
              )}
            </div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionAmount')}</div>
            <div>{isLoading ? t('loadingText') : <TransactionAmount transaction={data.transaction} />}</div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">E2E ID</div>
            <div>
              {isLoading || !data?.transaction?.statement ? (
                <div className="text-gray-400">{t('PaymentTransactionLoading')}</div>
              ) : (
                data.transaction?.statement?.e2e_id
              )}
            </div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionBankName')}</div>
            <div>
              {isLoading || !data?.transaction?.statement ? (
                <div className="text-gray-400">{t('PaymentTransactionLoading')}</div>
              ) : (
                bank?.shortName
              )}
            </div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionAgencyNumber')}</div>
            <div>
              {isLoading || !data?.transaction?.statement ? (
                <div className="text-gray-400">{t('PaymentTransactionLoading')}</div>
              ) : (
                data.transaction?.statement?.payer?.bank_agency
              )}
            </div>
          </div>
          <div className="flex pt-3 text-sm">
            <div className="grow text-sm font-semibold">{t('PaymentTransactionAccountNumber')}</div>
            <div>
              {isLoading || !data?.transaction?.statement ? (
                <div className="text-gray-400">{t('PaymentTransactionLoading')}</div>
              ) : (
                data.transaction?.statement?.payer?.acc_number
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
