import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const kernApi = axios.create({
  baseURL: process.env.REACT_APP_KERN_URL
})

kernApi.interceptors.response.use(...responseInterceptors)
kernApi.interceptors.request.use(...requestInterceptors)

export default kernApi
