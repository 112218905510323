import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function DescriptionListModal({ title, subtitle, data = {}, FooterComponent, open, onClose }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="px-4 sm:px-0">
                    {title && <h3 className="text-base font-semibold leading-7 text-gray-900">{title}</h3>}
                    {subtitle && <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{subtitle}</p>}
                  </div>
                  <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-3 sm:px-0">
                        {data.map(({ label, value, render }) => (
                          <React.Fragment key={label}>
                            <dt className="grid items-center text-sm font-medium leading-6 text-gray-900">{label}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                              {typeof render === 'function' ? render(value) : value}
                            </dd>
                          </React.Fragment>
                        ))}
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">{FooterComponent && <FooterComponent />}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
