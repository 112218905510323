import { DocumentIcon, LinkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function VendorDocuments({ vendor, isLoading }) {
  const { t } = useTranslation()
  return (
    <div className="mb-5">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorDocumentsSocialContract')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? (
              <Skeleton />
            ) : (
              <a
                className="flex items-center font-medium text-chill-500"
                href={vendor.file_social_contract_url}
                target="_blank"
              >
                <DocumentIcon className="mr-2 h-4" />
                {t('VendorDocumentsViewDocument')}
              </a>
            )}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{t('VendorDocumentsCNPJDocument')}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {isLoading ? (
              <Skeleton />
            ) : (
              <a
                className="flex items-center font-medium text-chill-500"
                href={vendor.file_document_url}
                target="_blank"
              >
                <DocumentIcon className="mr-2 h-4" />
                {t('VendorDocumentsViewDocument')}
              </a>
            )}
          </dd>
        </div>
      </dl>
    </div>
  )
}
