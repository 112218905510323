import React, { useRef } from 'react'
import ConfirmBox from '../ConfirmBox/ConfirmBox'
import Form from '../Form/Form'
import FieldSet from '../Form/FieldSet'
import SelectInput from '../Form/Inputs/SelectInput'
import { useQuery } from '@tanstack/react-query'
import { fetchMerchantProcessors } from '../../services/gatewayApi'
import { useTranslation } from 'react-i18next'

export default function ConfirmApprove({ payment, open, setOpen, onConfirm }) {
  const formRef = useRef(null)
  const { t } = useTranslation()
  const { data, isLoading } = useQuery({
    queryFn: fetchMerchantProcessors,
    queryKey: ['fetchProcessors', { merchantId: payment?.merchant_id }]
  })

  const setProcessor = (data) => {
    onConfirm(data)
  }

  const interceptConfirm = (event) => {
    formRef.current.submit()
  }

  return (
    <ConfirmBox open={open} setOpen={setOpen} onConfirm={interceptConfirm} title={t('ConfirmApproveTitle')}>
      {t('ConfirmApproveReversible')}&nbsp;
      <strong>{t('ConfirmApproveWaitingSettlement')}</strong>. {t('ConfirmApproveToProceed')}
      {t('ConfirmApproveClickOn')}&nbsp;
      <strong>{t('ConfirmApprove')}</strong> {t('ConfirmApproveButtonBelow')}
      <Form ref={formRef} onSubmit={setProcessor} onError={(a) => console.log('error')}>
        <FieldSet
          name="processor_id"
          showLabel={false}
          rules={{ required: t('ConfirmApproveRules') }}
          input={
            <SelectInput
              afterChange={(value) => value}
              options={
                data
                  ? data.processors.map((p) => ({
                      id: p.id,
                      value: p.alias
                    }))
                  : []
              }
            />
          }
        />
      </Form>
    </ConfirmBox>
  )
}
