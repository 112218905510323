import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Main from '../../../components/Main/Main'
import { Text, Flex, Button } from '@radix-ui/themes'
import BoletoDetailsPayment from '../../../components/@ui/BoletoDetailsPayment'
import CheckIcon from 'lucide-react/dist/esm/icons/circle-check-big'
import DownloadIcon from 'lucide-react/dist/esm/icons/download'
import DropdownButton from '../../../components/@ui/DropdownButton'

const boletoMock = {
  value: 120056.48,
  bank_name: 'Banco Inter',
  cnpj: '00.416.968/0001-01',
  emissor: 'Banco Inter',
  interest: 100,
  fine: 10,
  discount: 10,
  total_value: 120106.48
}

export default function BoletoPaymentSuccess() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div>
      <Main className="pt-32">
        <Flex gap="6" direction="column" justify="center" align="center">
          <Text size="7" align="center">
            Operação concluída com sucesso!
          </Text>
          <CheckIcon size="132" className="text-green-500" />
          <Flex className="max-w-[480px] w-full" justify="center">
            <BoletoDetailsPayment boleto={boletoMock} />
          </Flex>
          <Flex justify="center" mt="8" pb="4" gap="3">
            <Button size="3" variant="outline" color="gray">
              <DownloadIcon size="18" />
              Comprovante
            </Button>
            <DropdownButton
              menuContent={[
                { label: 'Boleto e Título', onClick: () => navigate('/customs/pay-boleto') },
                { label: 'Pix', onClick: () => null },
                { label: 'DARF', onClick: () => null }
              ]}
            >
              Novo pagamento
            </DropdownButton>
          </Flex>
        </Flex>
      </Main>
    </div>
  )
}
