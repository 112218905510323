import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* This example requires Tailwind CSS v2.0+ */
export default function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <main className="flex min-h-screen items-center bg-chill-700 bg-cover bg-top sm:bg-top">
        <div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <div className="flex justify-center pb-12 text-center">
            <img src="/paag-white.png" className="h-16 opacity-50" />
          </div>

          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">{t('NotFoundYouLost')}</h1>
          <p className="mt-4 text-lg font-medium text-white text-opacity-50">{t('NotFoundLookingFor')}</p>
          <div className="mt-12">
            <a
              href="/"
              onClick={(e) => {
                navigate('/')
                e.preventDefault()
              }}
              className="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-50 sm:hover:bg-opacity-100"
            >
              {t('NotFoundGoBack')}
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
