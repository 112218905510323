/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState, propagateChange } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { classNames } from '../../../helpers/dom'
import { Controller } from 'react-hook-form'

const SelectElement = React.forwardRef((props, ref) => {
  const { value, onChange, options, states, name, afterChange, propagateChange } = props
  const [selected, setSelected] = useState('')

  const isError = !!states?.errors?.[name]
  const errorMessage = isError ? states?.errors?.[name]?.message : false

  const findOption = (option) => options.filter((role) => role.id === option)[0]

  useEffect(() => {
    setSelected(findOption(value))
  }, [value])

  const handleChange = (newValue) => {
    const selectedValue = findOption(newValue)
    setSelected(selectedValue)
    onChange(selectedValue.id)
    propagateChange && propagateChange(selectedValue.id)
    !!afterChange && afterChange(selectedValue.id)
  }

  return (
    <>
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Listbox.Button
                ref={ref}
                className={classNames(
                  isError
                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                    : 'border-gray-300 focus:border-chill-500 focus:ring-chill-500',
                  'relative w-full cursor-default rounded-md border bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm'
                )}
              >
                <span className="block truncate">{selected?.value}&nbsp;</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-chill-600 text-white' : 'text-gray-900',
                          'group relative cursor-default select-none py-2 pl-8 pr-4'
                        )
                      }
                      value={person.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {person.value}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-chill-600',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {isError && errorMessage && (
        <p className="mt-2 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default function SelectInput({ control, name, rules, ...rest }) {
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => {
        return <SelectElement name={name} states={props.formState} {...props.field} {...rest} />
      }}
      rules={rules}
    />
  )
}
