import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const ledgerApi = axios.create({
  baseURL: process.env.REACT_APP_LEDGER_URL
})

ledgerApi.interceptors.response.use(...responseInterceptors)
ledgerApi.interceptors.request.use(...requestInterceptors)

export const fetchAdminPayments = async ({ queryKey, signal, meta }) => {
  const [_key, { merchantId }] = queryKey
  const { filters } = meta
  const data = {
    filters
  }

  return ledgerApi
    .get(`/v1/admin/payments`, { signal, params: { merchant_id: merchantId } })
    .then((response) => response.data)
}

export const fetchCustomsPayments = async () => {
  return ledgerApi.get(`/v1/customs/payments`).then((response) => response.data)
}

export const fetchAdminPayment = async ({ queryKey }) => {
  const [_key, { paymentId }] = queryKey

  return ledgerApi.get(`/v1/admin/payments/${paymentId}`).then((response) => response.data)
}

export const fetchAllVendors = async () => {
  return ledgerApi.get(`/v1/vendors`).then((response) => response.data)
}

export const fetchAdminMerchantVendors = async ({ queryKey }) => {
  const [_key, { merchantId }] = queryKey
  return ledgerApi.get(`/v1/admin/vendors`, { params: { merchant_id: merchantId } }).then((response) => response.data)
}

export const setPaymentApproved = async ({ paymentId, processorId }) => {
  return ledgerApi
    .post(`/v1/admin/payments/${paymentId}/approve`, {
      processor_id: processorId
    })
    .then((response) => response.data)
}

export const setPaymentPaid = async ({ paymentId }) => {
  return ledgerApi.post(`/v1/admin/payments/${paymentId}/pay`).then((response) => response.data)
}

export const fetchUserMerchant = async () => {
  return ledgerApi.get(`/v1/user/me`).then((response) => response.data)
}

export default ledgerApi
