import React, { useEffect, useState } from 'react'
import Badge from '../../../components/Badge/Badge'
import Table from '../../../components/Table/Table'
import { processorByCode } from '../../../data/ProcessorCredentials'
import kernApi from '../../../services/kernApi'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import { useTranslation } from 'react-i18next'
import { CustomReactTimeAgo } from '../../../components/DateTime/FormatDate'

export default function DashboardBanks() {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('DashBoardBanks'),
      render: ({ alias, processor_type }) => (
        <>
          <Badge variant="yellow">{processorByCode(processor_type)?.name}</Badge>
        </>
      )
    },
    {
      title: t('DashBoardBanksCount'),
      rowClassName: 'text-right',
      headerClassName: 'text-right',
      render: ({ count }) => new Intl.NumberFormat().format(count)
    },
    {
      title: t('DashBoardBanksAmount'),
      rowClassName: 'text-right',
      headerClassName: 'text-right',
      render: ({ sum, currency }) => (
        <span className="font-semibold">
          <FormattedCurrency amount={sum} currency={currency} />
        </span>
      )
    }
  ]
  const [data, setData] = useState({ stats: [], retrieved_at: '' })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    kernApi
      .get('/app/admin/stats/banks')
      .then((response) => {
        setData({
          stats: response.data.result.rows,
          retrieved_at: response.data.retrieved_at
        })
        setIsLoading(false)
      })

      .catch((e) => console.log('e', e))
  }, [])

  return (
    <div>
      <Table columns={columns} rows={data.stats} isLoading={isLoading} />
      <p className="py-2 text-right text-xs text-gray-500">
        {data.retrieved_at ? (
          <>
            {t('DashBoardBanksUpdated')} <CustomReactTimeAgo date={Date.parse(data.retrieved_at)} />
          </>
        ) : (
          t('loadingText')
        )}
      </p>
    </div>
  )
}
