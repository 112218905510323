import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../Pages/PageHeader'
import Main from '../Main/Main'
import Button from '../Button/Button'
import Table from '../@ui/Table'
import { FormatDate } from '../DateTime/FormatDate'
import { FormattedCurrency } from '../TransactionHelpers'
import { useNavigate } from 'react-router-dom'

// Mock: Isso vai ser inserido na etapa anterior (upload de arquivos) quando a API entregar a reposta.
sessionStorage.setItem(
  'confirm-payments',
  JSON.stringify({
    results: [
      {
        merchant: {
          value: 'Volks',
          error: 'ENUM'
        },
        vendor: {
          value: 'Receita Federal',
          error: 'ENUM'
        },
        to_date: {
          value: '2024-04-10T18:07:51.210Z',
          error: 'ENUM'
        },
        type: {
          value: 'Pix',
          error: 'ENUM'
        },
        amount: {
          value: 100.0,
          error: 'ENUM'
        }
      },
      {
        merchant: {
          value: 'Hyundai',
          error: 'INVALID'
        },
        vendor: {
          value: 'Starbucks',
          error: 'INVALID'
        },
        to_date: {
          value: '2024-04-11T09:30:00.000Z',
          error: 'INVALID'
        },
        type: {
          value: 'DARF',
          error: 'INVALID'
        },
        amount: {
          value: 5.75,
          error: 'INVALID'
        }
      },
      {
        merchant: {
          value: 'Volvo',
          error: 'NONE'
        },
        vendor: {
          value: 'INSS',
          error: 'NONE'
        },
        to_date: {
          value: '2024-04-12T14:20:00.000Z',
          error: 'NONE'
        },
        type: {
          value: 'Boleto',
          error: 'NONE'
        },
        amount: {
          value: 45.99,
          error: 'NONE'
        }
      }
    ]
  })
)

export default function ConfirmUploadedPayments() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const data = useMemo(() => {
    const confirmPayments = sessionStorage.getItem('confirm-payments')

    if (confirmPayments) {
      return JSON.parse(confirmPayments)
    }

    return {}
  }, [])

  const submitHandle = () => {
    navigate('/customs/payments')
  }

  return (
    <>
      <PageHeader
        title="Conferir pagamentos"
        subtitle="Valide os pagamentos antes de submeter"
        button={
          <>
            <Button variant="white">Importar lista</Button>
            <Button variant="tertiary" onClick={submitHandle}>
              Executar pagamento
            </Button>
          </>
        }
      />

      <Main>
        <Table.Root className="mt-7">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>{t('ConfirmUploadedPaymentsTableMerchant')}</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>{t('ConfirmUploadedPaymentsTableVendor')}</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>{t('ConfirmUploadedPaymentsTablePaymentDate')}</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>{t('ConfirmUploadedPaymentsTableType')}</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell justify="end">{t('ConfirmUploadedPaymentsTableAmount')}</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.results?.map(({ merchant, vendor, to_date, type, amount }) => (
              <Table.Row>
                <Table.RowHeaderCell>{merchant.value}</Table.RowHeaderCell>
                <Table.Cell>{vendor.value}</Table.Cell>
                <Table.Cell>
                  <FormatDate
                    date={to_date.value}
                    options={{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{type.value}</Table.Cell>
                <Table.Cell justify="end">
                  <FormattedCurrency amount={amount.value} currency="USD" />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        <div className="flex justify-end mt-6">
          <Button variant="tertiary" onClick={submitHandle}>
            Executar pagamento
          </Button>
        </div>
      </Main>
    </>
  )
}
