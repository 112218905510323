import React from 'react'
import Badge from '../Badge/Badge'
import { CheckCircleFill, ClockFill, XSquareFill, BanFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

const getFailureReason = (transaction) => {
  return (
    (typeof transaction.meta == 'string' && JSON.parse(transaction.meta).risk.reason) ||
    (transaction.error != null && typeof transaction.error.message == 'string' && transaction.error.message)
  )
}
export default function TransactionStatus({ transaction, size = 'normal' }) {
  const { t } = useTranslation()

  const statuses = {
    pending: {
      variant: 'muted',
      small: <ClockFill className="text-xl text-gray-200 group-hover:text-gray-400" />,
      text: t('TransactionsStatusPending')
    },
    success: {
      variant: 'green',
      small: <CheckCircleFill className="text-xl text-green-500" />,
      text: t('TransactionsStatusSuccess')
    },
    failed: {
      variant: 'red',
      small: <XSquareFill className="text-xl text-red-100 group-hover:text-red-400" />,
      text: t('TransactionsStatusFailed')
    },
    canceled: {
      variant: 'red',
      small: <BanFill className="text-xl text-red-100 group-hover:text-red-400" />,
      text: t('TransactionsStatusCanceled')
    }
  }

  const failureReason = getFailureReason(transaction)

  return (
    <>
      {size == 'normal' ? (
        <div className="flex cursor-default space-x-2">
          <Badge size="xs" variant={statuses[transaction.status]?.variant} className="group capitalize">
            {statuses[transaction.status]?.text}
          </Badge>
          {transaction.status === 'failed' && (
            <div className="group relative">
              <Badge variant="inverted" className="font-bold">
                ?
              </Badge>
              <span className="font-regular absolute -top-2 right-0 z-20 ml-2 hidden w-max -translate-y-full rounded-lg bg-gray-100 px-4 py-1 text-gray-900 shadow group-hover:block">
                {failureReason}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="group relative flex justify-center">
          {statuses[transaction.status]?.small}
          <span className="font-regular absolute -top-2 left-0 z-20 ml-2 hidden w-max -translate-y-full rounded-lg bg-gray-100 px-4 py-1 text-gray-900 shadow group-hover:block">
            <span className="capitalize">{statuses[transaction.status]?.text}</span>
            {transaction.status === 'failed' && <span> &mdash; {failureReason}</span>}
          </span>
        </div>
      )}
    </>
  )
}
