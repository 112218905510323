import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '../../../components/Tabs/Tabs'
import VendorDocuments from '../../../components/Vendors/Tabs/VendorDocuments'
import VendorOverview from '../../../components/Vendors/Tabs/VendorOverview'
import ledgerApi from '../../../services/ledgerApi'
import { useTranslation } from 'react-i18next'

export default function VendorShow() {
  const [vendor, setVendor] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { vendorId } = useParams()
  const { t } = useTranslation()
  const tabs = [
    {
      name: t('VendorShowOverview'),
      element: <VendorOverview vendor={vendor} isLoading={isLoading} />,
      current: true
    },
    // {
    //   name: "Beneficiaries",
    //   element: <div>Beneficiaries</div>,
    //   current: false,
    // },

    {
      name: t('VendorShowDocuments'),
      element: <VendorDocuments vendor={vendor} />,
      current: false
    },
    {
      name: t('VendorShowPayments'),
      element: <></>,
      current: false
    }
  ]

  useEffect(() => {
    if (!vendorId) return
    ledgerApi.get(`/v1/vendors/${vendorId}`).then((response) => {
      setVendor(response.data)
      setIsLoading(false)
    })
  }, [vendorId])

  return (
    <>
      <Tabs
        title={t('VendorShowTitle')}
        subtitle={
          <div>
            {t('VendorShowSubtitle')} #<span className="font-medium uppercase">{vendor?.id}</span>
          </div>
        }
        tabs={tabs}
      />
    </>
  )
}
