import React from 'react'
import Badge from '../Badge/Badge'
import { useTranslation } from 'react-i18next'

export default function YesNo({ test, ifYes, ifNo, ...rest }) {
  const { t } = useTranslation()
  const textYes = ifYes || t('AdminMerchantUsersActive')
  const textNo = ifNo || t('AdminMerchantUsersActiveNo')
  return (
    <Badge variant={test ? 'green' : 'red'} {...rest}>
      {test ? textYes : textNo}
    </Badge>
  )
}
