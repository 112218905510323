import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomsListPayments from '../Payments/CustomsListPayments'
import StatsPill from '../../../components/StatsPill/StatsPill'
import { CreditCardIcon } from '@heroicons/react/24/outline'
import { FormattedCurrency } from '../../../components/TransactionHelpers'
import { Flex } from '@radix-ui/themes'

const statsMapping = [
  {
    key: 'valor',
    title: 'Saldo total',
    icon: <CreditCardIcon />,
    render: () => <FormattedCurrency amount="3000000" currency="BRL" />
  }
]

export default function CustomsMerchantHome() {
  const { t } = useTranslation()
  const { isLoading, setActivePage, merchant } = useOutletContext()

  useEffect(() => {
    setActivePage('payments')
  }, [])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div>
      <dl className="mt-4 mb-8 w-96">
        {statsMapping.map((stats) => (
          <StatsPill key={stats.key} title={stats.title} icon={stats.icon} value={stats.render(0)} />
        ))}
      </dl>
      <CustomsListPayments merchantId={merchant.id} />
    </div>
  )
}
