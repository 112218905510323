import React from 'react'
import { classNames } from '../../helpers/dom'
import { FormattedCurrency, FormattedDateTime } from '../TransactionHelpers'
import { useTranslation } from 'react-i18next'

const currentEventData = (transactionEvent) => {}

const eventTimeline = (transactionEvent, transactionEvents, t) => {
  const result = transactionEvent?.success ? 'success' : 'error'
  const eventType = transactionEvent?.event_type

  const hasRefund = transactionEvents.some(({ event_type }) => event_type === 'void')

  const data = {
    capture: {
      success: {
        bgColor: 'bg-green-500',
        content: (
          <>
            {t('TransactionsEventsPixOF')}&nbsp;
            <span className="font-bold">
              <FormattedCurrency amount={transactionEvent.amount} />
            </span>
            &nbsp; {t('TransactionsEventsPaid')}
          </>
        )
      },
      error: {
        bgColor: 'bg-red-500',
        content: t('TransactionsEventsExpired')
      }
    },
    auth: {
      success: {
        bgColor: 'bg-gray-300',
        content: t('TransactionsEventsGenerated')
      },
      error: {
        get bgColor() {
          if (hasRefund) {
            return 'bg-gray-300'
          }

          return 'bg-red-500'
        },
        get content() {
          if (hasRefund) {
            return t('TransactionsEventsFailureWithRefund')
          }

          return t('TransactionsEventsFailure')
        }
      }
    },
    transfer: {
      success: {
        bgColor: 'bg-green-500',
        content: (
          <>
            <span className="font-bold">
              <FormattedCurrency amount={transactionEvent.amount} />
            </span>
            &nbsp; {t('TransactionsEventsUsingPix')}
          </>
        )
      },
      error: {
        bgColor: 'bg-red-500',
        content: t('TransactionsEventsFailurePix')
      }
    },
    payment: {
      success: {
        bgColor: 'bg-gray-300',
        content: t('TransactionsEventsPayment')
      },
      error: {
        bgColor: 'bg-red-500',
        content: t('TransactionsEventsFailurePix')
      }
    },
    void: {
      success: {
        bgColor: 'bg-gray-300',
        content: t('TransactionsEventsRefund')
      },
      error: {
        bgColor: 'bg-red-500',
        content: t('TransactionsEventsFailureRefund')
      }
    }
  }

  return data?.[eventType]?.[result]
}

export default function TransactionEventsTimeline({ transactionEvents }) {
  const { t } = useTranslation()

  return (
    <>
      <ul role="list" className="-mb-8 p-4 sm:p-0">
        {transactionEvents.reverse().map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== transactionEvents.length - 1 ? (
                <span className="absolute left-1.5 top-0 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      eventTimeline(event, transactionEvents, t)?.bgColor,
                      'mt-2 flex h-3 w-3 items-center justify-center rounded-full ring-8 ring-white'
                    )}
                  ></span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">{eventTimeline(event, transactionEvents, t)?.content}</p>
                  </div>
                  <div className="whitespace-nowrap text-right text-xs text-gray-500">
                    <FormattedDateTime dateTime={event.created_at} />
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}
