import React, { useState, useEffect, useCallback, useContext, createContext } from 'react'

const UsersContext = createContext({
  isLoading: false,
  data: {},
  reload() {}
})

export default function UsersProvider({ asyncFn, children }) {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    if (typeof asyncFn === 'function') {
      try {
        setData(await asyncFn())
      } catch (error) {
        setError(error)
      }
    }

    setIsLoading(false)
  }, [asyncFn])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <UsersContext.Provider value={{ isLoading, data, error, reload: fetchData }}>{children}</UsersContext.Provider>
}

export function useUsers() {
  return useContext(UsersContext)
}
